import moment from "moment";
import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import aws from "aws-sdk";
import Header from "./Header";
import Loader from "./loader";
import icontLogisticUnit  from '../assets/images/ic_logistics_icon.png';
import { ApiUrl, ApiUrl1 } from "../constant";

function AddDemand(){

    const location = useLocation();
    const navigate = useNavigate();
    // const userInfo = localStorage.getItem('userInfo')

    
    const [userInfo, setUserInfo] = React.useState({})
    const [subId, setSubId] = React.useState(0)    
    const [subType, setSubType] = React.useState('')    
    const [loader, setLoader] = React.useState(false);
    const [product, setProduct] = React.useState([]);
    const [showLogistic, setShowLogistic] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [units, setUnits] = React.useState([]);
    const [productsShow, setProductsShow] = React.useState(true)
    const [addData, setAddData] = React.useState({
        "delivery_address": "",
        "delivery_date": "",
        "demand_product_request": [],
        "destination": "",
        "destination_pincode": "",
        "email_recipients": [],
        "is_supply_chain_finance_require": false,
        "latitude": "",
        "longitude": "",
        "destination_pincode": "",
        "shipping_address": "",
        "tentative_shipment_date": "",
        "user_id": null,
        "vehicle_type": ""
    }
    );
    const [productData, addProductData] = React.useState({
        "buying_price": 0.0,
        "demand_master_list": "",
        "global_delivery": false,
        "gst": 0.0,
        "is_demand_product": true,
        "is_approved": "pending",
        "measurement_unit": "kilogram",
        "origin_from": "N/A",
        "pack_size": 1,
        "pack_sizes": "{\"multiPacks\":[{\"buyPrice\":\"0.0\",\"discPerc\":\"null\",\"packSize\":\"1\",\"presence\":true,\"sellPrice\":\"0.0\",\"splDisc\":\"null\",\"stock\":\"250\",\"unit\":\"kilogram\"}]}",
        "presence": false,
        "product_amount": 0.0,
        "product_category_id": 1,
        "product_desc": "",
        "product_images": [],
        "product_name": "",
        "product_sub_category_id": 1,
        "quality": "",
        "selling_price": 0.0,
        "stock": 250,
        "user_id": null,
        "min_order_qty":'',
      })

    const [formErrors, setFormErrors] = React.useState({
        "delivery_address": "",
        "delivery_date": "",
        "destination_pincode": "",
    })

    const [formValid, setFormValid] = React.useState({
        'delivery_address_valid' : true,
        'delivery_date_valid' : false,
        'destination_pincode_valid' : true,
    })

    const [formValidated, setFormValidate] = React.useState(false)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userInfo'))
        let addField = addData;
        addField.delivery_address = user.Shop.delivery_address;
        addField.destination = user.Shop.shipping_address;
        addField.destination_pincode = user.Shop.pin_code;
        addField.email_recipients.push(user.email_address);
        addField.user_id = user.ID;
        productData.user_id = user.ID
        console.log(user)
        setUserInfo(user)  
        if(location.pathname.split('/')[2]){
            setSubType(location.pathname.split('/')[3])
            setSubId(location.pathname.split('/')[2])
        }else{
            navigate('/demand')
        }  

    },[])

    useEffect(() => {
        getProduct()
        getUnits();
    },[subId])

    console.log(userInfo)
    // const shipping_address = "503 AkashDeep Apartment,  Pune, @@Muktai, @@Pune, @@Maharashtra@@ -122001"
    const shipping_address = userInfo?.Shop?.shipping_address.replaceAll("@@", "")
    
    const getProduct = (pid) => {
        setLoader(true)
        if(subId && userInfo){
            try{
                const requestOptions = {
                    crossDomain:true,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 
                    'Access-Control-Allow-Origin':'*', }
                };
                let url = ''
                if(subType === 'sub'){
                    url = `/v1/merchant/commodity/${subId}/product`;
                }else{
                    url = `/v1/merchant/9/product-list?category=${subId}`;
                }
                fetch(`${url}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setLoader(false)
                    if(data.code === 200){
                        const items = data.data;
                        
                        for(let i = 0; i < items.length;i++){
                            
                            items[i].min_order_qty = '';
                            items[i].quality = '';
                            items[i].disable = true;
                            items[i].show = true;
                            if(pid && pid === items[i].ID){
                                let selP = selectedProduct;
                                items[i].disable = false
                                selP.push(items[i])
                                setSelectedProduct([...selP])
                            }
                            if((i+1) === items.length){
                                setProduct(items)
                            }
                        }
                    }
                });
            }catch{
            setLoader(false)
        }
    }
}

const getUnits = () => {
    const requestOptions = {
        crossDomain:true,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin':'*', }
    };
    let url = `/units`;
    fetch(`${url}`, requestOptions)
    .then(response => response.json())
    .then(data => {
            setUnits(data ? data : [])
    });
}

const getSignUrl = (name,file) => {
    setLoader(true)
    const s3 = new aws.S3({
      endpoint: "https://ayekart-mobile.sfo2.digitaloceanspaces.com",
      accessKeyId: "JZXLSMYTTPFGOUKX46ZW",
      secretAccessKey: "iOU3OJckYIMvOiQSsjImYSP8KjJ1b1GnBh3TNKIQkTo",
    });

    var config = {
      Body: file,
      Bucket:
        "a4920e07cf09ce0e60dff28729322c22fbbf4bbb4d9663ca8428d0a8b73fe03a",
      // Key: "Test/sampleisssmage.jpg", 
      Key: file.name,
    };

    s3.putObject(config)
      .on("build", (request) => {
        request.httpRequest.headers.Host =
          "https://ayekart-mobile.sfo2.digitaloceanspaces.com";
        request.httpRequest.headers.Host =
          "http://ayekart-mobile.sfo2.digitaloceanspaces.com";
        request.httpRequest.headers["Content-Length"] = file.size;
        request.httpRequest.headers["Content-Type"] = "image/jpg";
        request.httpRequest.headers["x-amz-acl"] = "public-read";
        request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
      })
      .send((err, data) => {
        setLoader(false)
        if (err) {
          console.log("Failed to upload file", `${err}`);
        } else {
   
            addProductData({...productData, product_images: ['https://ayekart-mobile.sfo2.digitaloceanspaces.com/a4920e07cf09ce0e60dff28729322c22fbbf4bbb4d9663ca8428d0a8b73fe03a/'+file.name]})
        }
      });
  };


const checkProduct = (e) => {
    let prod = product
    if(e.target.checked){
        let index = product.findIndex(item => item.ID == e.target.value);
        prod[index].disable = false;
        setProduct(prod)
        setSelectedProduct([...selectedProduct, product[index]])
    }
    if(!e.target.checked){
        let sp = selectedProduct;
        let index = sp.findIndex(item => item.ID === e.target.value); 
        let mindex = product.findIndex(item => item.ID == e.target.value);       
        prod[mindex].disable = true;
        prod[mindex].min_order_qty = '';
        prod[mindex].quality = '';
        setProduct(prod)
        sp.splice(index,1);
        setSelectedProduct([...sp]);
    }
}

const onChangeInput = (e) => {
    setAddData({...addData,[e.target.name]:e.target.value})
    validateField(e.target.name, e.target.value)
}

const onCheckInput = (e) => {
    console.log(e.target.value)
    setAddData({...addData,[e.target.name]:e.target.value === 'true' ? true : false})
}

const onChangeFile = (e) => {
    getSignUrl(e.target.name,e.target.files[0])
}

const onChangeProductInput = (e) => {
    addProductData({...productData,[e.target.name]:e.target.value })
}

const checkValidation = () => {
    for(let key in addData){
        validateField(key,addData[key])
    }
 }

const validateField = (fieldName, value)=> {
    let fieldValidationErrors = formErrors;
    let formValidation = formValid;
    switch(fieldName) {
      case 'delivery_address':
        formValidation.delivery_address_valid = value !== '';
        fieldValidationErrors.delivery_address = formValid.delivery_address_valid ? '' : ' is invalid';
        break;
    case 'delivery_date':
        formValidation.delivery_date_valid = value !== '';
        fieldValidationErrors.delivery_date = formValid.delivery_date_valid ? '' : ' is invalid';
        break;
    case 'destination_pincode':
        formValidation.destination_pincode_valid = value !== '';
        fieldValidationErrors.destination_pincode = formValid.destination_pincode_valid ? '' : ' is invalid';
        break;
      default:
        break;
    }
    
    setFormErrors({...fieldValidationErrors})
    setFormValid({...formValidation})
    
  }

  useEffect(() => {
    validateForm()
  },[formErrors,formValid])
  
  const validateForm = () => {
    setFormValidate(formValid.delivery_address_valid && 
        formValid.delivery_date_valid && 
        formValid.destination_pincode_valid)
  }

  const errorClass = (error) => {
    return(error.length === 0 ? '' : 'has-error');
 }


const addNewProduct = () => {
    if(userInfo.ID){
    setLoader(true)
    const dataToAdd = productData;
    dataToAdd.min_order_qty = parseInt(dataToAdd.min_order_qty);

    const requestOptions = {
        crossDomain:true,
        method: 'POST',            
        body: JSON.stringify(dataToAdd),
        headers: { 'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin':'*', }
    };
    let url = `/v2/merchant/${userInfo.ID}/product`;
    fetch(`${url}`, requestOptions)
    .then(response => response.json())
    .then(data => {
        setLoader(false)
        if(data.code === 201){
            
            getProduct(data.data.ProductID)
            addProductData({"buying_price": 0.0,
            "demand_master_list": "",
            "global_delivery": false,
            "gst": 0.0,
            "is_demand_product": true,
            "is_approved": "pending",
            "measurement_unit": "kilogram",
            "origin_from": "N/A",
            "pack_size": 1,
            "pack_sizes": "{\"multiPacks\":[{\"buyPrice\":\"0.0\",\"discPerc\":\"null\",\"packSize\":\"1\",\"presence\":true,\"sellPrice\":\"0.0\",\"splDisc\":\"null\",\"stock\":\"250\",\"unit\":\"kilogram\"}]}",
            "presence": false,
            "product_amount": 0.0,
            "product_category_id": 1,
            "product_desc": "",
            "product_images": [],
            "product_name": "",
            "product_sub_category_id": 1,
            "quality": "",
            "selling_price": 0.0,
            "stock": 250,
            "user_id": userInfo.ID,
            "min_order_qty":''})
            toast.success('products added succesfully')
        }else{
            toast.error('something went wrong')
        }
    });
    }
}

const chekQty = () => {
    console.log(selectedProduct)
    let result = 
    console.log(result)
    if(result){
        return true
    }
    return false
}

const checkQly = () => {
    for(let item of selectedProduct){
        if(item.quality === ''){
            return true
        }
    }
    return false
}

const addNewDemand = () => {
    if(formValidated){
        console.log(selectedProduct.filter(item => console.log(item)))
        if(selectedProduct.length === 0){
            console.log('no')
            toast.error("Please select at least 1 product")
        }
        else if((selectedProduct.filter(item => item.min_order_qty === '')).length > 0){
            console.log(selectedProduct.filter(item => item.min_order_qty === ''))
            toast.error("please fill all quantity field")
        }
        else if((selectedProduct.filter(item => item.quality === '')).length > 0){
            toast.error('please fill all quality field')
        }
        else{
            setLoader(true)
            const dataToAdd = addData;
            dataToAdd.delivery_date = (moment(dataToAdd.delivery_date).format('YYYY-MM-DD'))+'T00:00:00.000Z';
            dataToAdd["tentative_shipment_date"] = dataToAdd.tentative_shipment_date !== '' ? (moment(dataToAdd.tentative_shipment_date).format('YYYY-MM-DD'))+'T00:00:00.000Z' : (moment(dataToAdd.delivery_date).format('YYYY-MM-DD'))+'T00:00:00.000Z';
            for(let item of selectedProduct){
                let obj = {
                    "measurement_unit": item.measurement_unit,
                    "pack_size": `${item.pack_size}`,
                    "product_id": item.ID,
                    "product_name": item.product_name,
                    "product_price": item.selling_price,
                    "product_quality": item.quality,
                    "product_quantity": parseFloat(item.min_order_qty),
                    "remarks": "",
                    "shop_id": userInfo.Shop.ID
                }

            dataToAdd.demand_product_request.push(obj);
            }
            console.log('Send data to adddemand=>',dataToAdd)
            const requestOptions = {
                crossDomain:true,
                method: 'POST',            
                body: JSON.stringify(dataToAdd),
                headers: { 'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin':'*', }
            };
            let url = `/v1/merchant/${userInfo.ID}/draft/demand`;
            fetch(`${url}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLoader(false)
                if(data.code === 201){
                    setAddData({
                        "delivery_address": "",
                        "delivery_date": "",
                        "demand_product_request": [],
                        "destination": "",
                        "destination_pincode": "",
                        "email_recipients": [],
                        "is_supply_chain_finance_require": false,
                        "latitude": "",
                        "longitude": "",
                        "pincode": "",
                        "shipping_address": "",
                        "tentative_shipment_date": "",
                        "user_id": userInfo.ID,
                        "vehicle_type": ""
                    })
                    toast.success('Demand created successfully')
                    navigate('/demand')
                }else{
                    toast.error('something went wrong')
                }
            });
            
                
        }
    
    }else{
        checkValidation()
    }
}

const changeprouductValue = (e,id) => {
    let selId = selectedProduct.findIndex(item => item.ID === id);
    if(selId >= 0){
        let sel = selectedProduct;
        sel[selId].min_order_qty = e.target.value;
        setSelectedProduct([...sel])
    }
    let pId = product.findIndex(item => item.ID === id)
    if(pId >= 0){
        let pro = product;
        pro[pId].min_order_qty = e.target.value;
        setProduct([...pro])
    }
}

const changeprouductQuality = (e,id) => {
    let selId = selectedProduct.findIndex(item => item.ID === id);
    if(selId >= 0){
        let sel = selectedProduct;
        sel[selId].quality = e.target.value;
        setSelectedProduct([...sel])
    }
    let pId = product.findIndex(item => item.ID === id)
    if(pId >= 0){
        let pro = product;
        pro[pId].quality = e.target.value;
        setProduct([...pro])
    }
}

const changeMeusermentUnit = (e,id) => {
    let selId = selectedProduct.findIndex(item => item.ID === id);
    if(selId >= 0){
        let sel = selectedProduct;
        sel[selId].measurement_unit = e.target.value;
        setSelectedProduct([...sel])
    }
    let pId = product.findIndex(item => item.ID === id)
    if(pId >= 0){
        let pro = product;
        pro[pId].measurement_unit = e.target.value;
        setProduct([...pro])
    }
}

const searchData = (keyword) => {
    setSearch(keyword)
    if (keyword !== '') {
        for(let item of product){
            if(item.product_name.toLowerCase().includes(keyword.toLowerCase())){
                item.show = true
            }else{
                item.show = false;
            }
        }
      } else {
        for(let item of product){
            item.show = true
        }
      }
}




    return (
        <div>
    
            <Loader loading={loader} />
            <Header />
            <div className="container-fluid mt-3 mb-3">
                <div className="custom-breadcrum">
                    <span className="disable-color" style={{cursor:'pointer'}} onClick={() => navigate('/home')}>Home </span> 
                    <span className="disable-color"> Add Demand</span>
                </div>
            </div>
            <div className="container-fluid mt-3">
                {
                    productsShow ? 
                    <>
                    <div className="row">
                    <div className="col-md-8 offset-md-2" style={{    height: '100vh', overflow: 'auto'}}>
                        <h3>Step 1</h3>
                        <div className="input-container-pro table table-responsive">
                            <div className="search-input-div" style={{    padding: '20px 0', width: '100%'}}>
                                <input className="search-input"  onChange={(e) => searchData(e.target.value)} value={search} type="text" placeholder="Search Items" />
                            </div>
                        <table>
                            <thead>
                                <tr className="header-color">
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Qty</th>
                                    <th>Quality</th>
                                    <th>UOM</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    product.map((item,index) => {
                                        return (
                                            <tr style={item.show ? {display:'table-row'} : {display:'none'}}>
                                                <td><input type={"checkbox"} name="product_id" value={item.ID} onChange={checkProduct} /></td>
                                                <td>
                                                {
                                                    item.product_images &&  item.product_images.length > 0 &&
                                                    
                                                    <img src={item.product_images[0]} alt={item.ID} width="50px" height={'50px'} />
                                                    
                                                }
                                                <span style={{fontSize:'12px'}}>{item.product_name}</span>
                                                </td>
                                                <td >
                                                    <input disabled={item.disable} type={'number'} name={'min_order_qty'} className="form-control" value={item.min_order_qty} onChange={(e) => changeprouductValue(e, item.ID)} />
                                                </td>
                                                <td >
                                                    <input disabled={item.disable} type={'text'} name={'quality'} className="form-control" value={item.quality} onChange={(e) => changeprouductQuality(e, item.ID)} />
                                                </td>
                                                <td>
                                                    <select className="input-box form-control" name="measurement_unit" onChange={(e) => changeMeusermentUnit(e, item.ID)} value={item.measurement_unit.toLowerCase()} >
                                                        {
                                                            units.map((item,index) => <option key={index} value={item.measurement.toLowerCase()}>{item.measurement}</option>)
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="input-container-pro">
                                <button className="submit-button-pro" onClick={() => setProductsShow(false)}>Done</button>
                            </div>
                        </div>
                    </div>
                    </>
                    :
                    <>
                    <div className="row">
                    <div className="col-md-12 text-center">                        
                        <h3>Step 2</h3>
                    </div>
                    <div className="col-md-6">
                    <div className="input-container-pro table table-responsive">
                            <button className="btn btn-link btn-block" onClick={() => setProductsShow(true)}>+ Add Product from list</button>
                        <table>
                            <thead>
                                <tr className="header-color">
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Quality</th>
                                    <th>UOM</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedProduct.map((item,index) => {
                                        return (
                                            <tr >
                                                <td><input type={"checkbox"} name="product_id" value={item.ID} checked onChange={checkProduct} /></td>
                                                <td>
                                                {
                                                    item.product_images &&  item.product_images.length > 0 &&
                                                    
                                                    <img src={item.product_images[0]} alt={item.ID} width="50px" height={'50px'} />
                                                    
                                                }
                                                <span style={{fontSize:'12px'}}>{item.product_name}</span>
                                                </td>
                                                <td >
                                                    <input disabled={item.disable} type={'number'} name={'min_order_qty'} className="form-control" value={item.min_order_qty} onChange={(e) => changeprouductValue(e, item.ID)} />
                                                </td>
                                                <td >
                                                    <input disabled={item.disable} type={'text'} name={'quality'} className="form-control" value={item.quality} onChange={(e) => changeprouductQuality(e, item.ID)} />
                                                </td>
                                                <td>
                                                    <select className="input-box form-control" name="measurement_unit" onChange={(e) => changeMeusermentUnit(e, item.ID)} value={item.measurement_unit.toLowerCase()} >
                                                        {
                                                            units.map((item,index) => <option key={index} value={item.measurement.toLowerCase()}>{item.measurement}</option>)
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className="col-md-6">   
                        <div className="input-container-pro">
                            <button className="btn btn-link btn-block" data-toggle="modal" data-target="#exampleModal">+ Add New Product</button>
                        </div>                      
                        <div className={`input-container-pro ${errorClass(formErrors.delivery_date)}`}>
                            <label>Expected date of delivery *</label>
                            <input type={'date'} min={moment().format('YYYY-MM-DD')}   name="delivery_date" onChange={(e) => onChangeInput(e)} value={addData['delivery_date']} className="input-box form-control"  />
                            {
                                formErrors.delivery_date !== '' &&
                                <span className="error">*Delivery date is required</span>
                            }
                        </div>                       
                        <div className={`input-container-pro ${errorClass(formErrors.delivery_address)}`}>
                            <label>Shipping Address *</label>
                            <textarea  name="delivery_address" onChange={(e) => onChangeInput(e)}
                            defaultValue={shipping_address}
                            value={addData['delivery_address']} className="input-box form-control"  />
                            {
                                formErrors.delivery_address !== '' &&
                                <span className="error">*Delivery address is required</span>
                            }
                        </div>                                              
                        <div className={`input-container-pro ${errorClass(formErrors.destination_pincode)}`}>
                            <label>Destination PinCode *</label>
                            <input type={'number'}  name="pincode" onChange={(e) => onChangeInput(e)} defaultValue={userInfo?.Shop?.pin_code} value={addData['pincode']} className="input-box form-control"  />
                            {
                                formErrors.destination_pincode !== '' &&
                                <span className="error">*Destination pincode is required</span>
                            }
                        </div>                       
                        <div className="input-container-pro">
                            <label>Remark(Optional)</label>
                            <textarea placeholder="Add your Remark" name="remark" onChange={(e) => onChangeInput(e)} value={addData['remark']} className="input-box form-control"  />
                        </div> 
                          
                        <div className="input-container-pro">

                        {
                            showLogistic ?
                            <button className={'btn btn-link'} onClick={() => setShowLogistic(false)}><img src={icontLogisticUnit} alt="logisctic" width={'50px'} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Request Logistic Partner</button>
                            :                            
                            <button className={'btn btn-link'} onClick={() => setShowLogistic(true)}><img src={icontLogisticUnit} alt="logisctic" width={'50px'} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Request Logistic Partner</button>
                        }
                           </div> 

                           <div className="input-container-pro" style={{flexDirection:'row',justifyContent:'space-between'}}>
                            <label>Is Supply Chain Finance Require? *</label>
                            <label>
                                <input type="radio"  name="is_supply_chain_finance_require" onChange={(e) => onCheckInput(e)} value={true} checked={addData['is_supply_chain_finance_require']}/>
                                <span style={{
    float: 'right',
    marginTop: '-2px',
    marginLeft: '4px',
}} >Yes</span>
                            </label>
                            <label>
                                <input type="radio"  name="is_supply_chain_finance_require" onChange={(e) => onCheckInput(e)} value={false} checked={!addData['is_supply_chain_finance_require']}/>
                                <span style={{
    float: 'right',
    marginTop: '-2px',
    marginLeft: '4px',
}} >No</span>
                            </label>
                        </div>
                        {
                            showLogistic &&  
                            <>                                          
                            <div className="input-container-pro">
                                <label>Vehicle Type</label>
                                <input type={'text'}  name="vehicle_type" onChange={(e) => onChangeInput(e)} value={addData['vehicle_type']} className="input-box form-control" placeholder="ex. 'Truck','Ship' etc." />
                            </div> 
                            <div className={`input-container-pro`}>
                                <label>Tentative date of delivery *</label>
                                <input type={'date'}  min={moment().format('YYYY-MM-DD')}  name="tentative_shipment_date" onChange={(e) => onChangeInput(e)} value={addData['tentative_shipment_date']} className="input-box form-control"  />
                                {
                                formErrors.delivery_date !== '' &&
                                <span className="error">*Tentative Date of delivery is required</span>
                            }
                            </div>                       
                            <div className={`input-container-pro`}>
                                <label>Shipping Address *</label>
                                <textarea  name="shipping_address" onChange={(e) => onChangeInput(e)} value={addData['shipping_address']} className="input-box form-control"  />
                           </div>                                              
                            {/* <div className={`input-container-pro`}>
                                <label>PinCode *</label>
                                <input type={'number'}  name="pincode" onChange={(e) => onChangeInput(e)} value={addData['pincode']} className="input-box form-control"  />
                            </div> */}
                            </>
                        }
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="input-container-pro">
                                <button className="submit-button-pro" onClick={() => addNewDemand()}>Submit</button>
                            </div>
                        </div>
                    </div>
                    </>
                }
                    
                    
                
            </div>
            <Toaster />
            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add New Product</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{textAlign: 'left'}}>
                        <div className="input-container-pro">
                            <label>Product Name</label>
                            <input type={'text'} name="product_name" onChange={(e) => onChangeProductInput(e)} value={productData['product_name']}  className="input-box form-control" placeholder="Enter Proudct name" />
                        </div> 
                        <div className="row ">
                            <div className="col">
                                <div className="input-container-pro">
                                    <label>Product Image</label>
                                    <input type={'file'} name="product_images" onChange={(e) => onChangeFile(e)} className="input-box form-control"  />
                                </div>  
                            </div>
                            {
                                productData['product_images'].length > 0 && 
                                <>
                                    <div className="col-md-4 input-container-pro">                            
                                            <img src={productData['product_images'][0]} width={'50px'} alt={'product'} />
                                            <button onClick={() => addProductData({...productData,product_images:[]})} className="remove-img " >X</button>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={`input-container-pro`}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Min. order quantity*</label>
                                    <input type={'number'} name="min_order_qty" onChange={(e) => onChangeProductInput(e)} value={productData['min_order_qty']} className="input-box form-control" placeholder="Minimum Order quantity" />
                                </div>
                                <div className="col-md-6">
                                    
                                    <label>Select Unit*</label>
                                    <select className="input-box form-control" name="measurement_unit" onChange={(e) => onChangeProductInput(e)} value={productData['measurement_unit']} >
                                        <option value={''}>Select Unit</option>
                                        {
                                            units.map((item,index) => <option key={index} value={item.measurement}>{item.measurement}</option>)
                                        }
                                    </select>                                
                                </div>
                            </div>
                        </div>
                        <div className="input-container-pro">
                            <label>Quality</label>
                            <input type={'text'} name="quality" onChange={(e) => onChangeProductInput(e)} value={productData['quality']}  className="input-box form-control" placeholder="Enter Proudct name" />
                        </div> 
                    </div>
                    <div className="modal-footer">
                        <button className="submit-button-pro" data-dismiss="modal" onClick={() => addNewProduct()}>Submit</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddDemand;