import { BrowserRouter, Routes, Route } from "react-router-dom";


// import logo from './logo.svg';
import './App.css';
import HisabInvoice from './components/HisabInvoice/Invoice'
import Invoice from './components/Invoice/Invoice';
import Directory from "./components/Directory";
import Order from "./components/Order";
import Home from "./components/Home";
import Login from "./components/Login";
import Demand from "./components/Demand";
import RecievedOrder from "./components/recievedOrder";
import Finance from "./components/Finance";
import Inventory from "./components/Inventory";
import AddProudct from "./components/AddProudct";
import EditProduct from "./components/EditProudct";
import Shipment from "./components/Shipment";
import Tranches from "./components/Tranches";
import AddDemand from "./components/addDemand";
import DemandRequest from "./components/DemandRequests";
import SellersRequest from "./components/SellersRequests";
import InvoiceList from './components/HisabInvoice/InvoiceList'
import ViewReports from "./components/Finance/viewReports";
import MyDocument from "./components/Finance/MyDocument";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route exact path="/">
          <Route index element={<Login />} />
          <Route path="/Invoice" element={<Invoice/>} />
          <Route path="/hisabinvoice" element={<HisabInvoice/>} />
          <Route path="/invoiceList" element={<InvoiceList/>}></Route>
          <Route path="/home" element={<Home />} />
          <Route path="/directory" element={<Directory />} />
          <Route path="/order" element={<Order />} />
          <Route path="/demand" element={<Demand />} />
          <Route path="/addDemand/:id/:type" element={<AddDemand />} />
          <Route path="/demandRequest/:id/:status" element={<DemandRequest />} />
          <Route path="/sellersRequest" element={<SellersRequest />} />
          <Route path="/recievedOrder" element={<RecievedOrder />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/addProduct" element={<AddProudct />} />
          <Route path="/editProduct" element={<EditProduct />} />
          <Route path="/shipment/:id" element={<Shipment />} />
          <Route path="/my-document" element={<MyDocument />}/>
          <Route path="/tranches/:id/:buyerId/:sellerId" element={<Tranches />} />
          <Route path="/finance/view-reports" element={<ViewReports />}/>
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
