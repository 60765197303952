import Header from "../Header";
import "./invoice.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect, useRef } from "react";
// import { getData } from "../../services/Apicall";
import Axios from "axios";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { useSearchParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../loader";
import {ApiUrl, local_access} from '../../constant'
import "react-datepicker/dist/react-datepicker.css";
import EditableProduct from "../../components/HisabInvoice/EditableProduct";
import fieldsArr from "../../components/HisabInvoice/Fields";
import axios from "axios";


const rows = [];


function Invoice() {
  const [InvoiceDetails, setInvoiceDetails] = useState({});
  const [productDetails, setProductDetail] = useState([]);
  const [value, setValue] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [invoicePostResponse, setInvoicePostResponse] = useState({});
  const [invoiceDateDisplay, setInvoiceDateDisplay] = useState(null);
  const [other_advance_payment_date, setOtherAdvancePaymentDate] = useState(
    new Date()
  );
  const [dateOfShipment, setDateOfShipment] = useState(new Date());
  const [param] = useSearchParams();
  const navigate = useNavigate();
  console.log("paramsssss", param.get("id"));
  const [userInfo, setUserInfo] = React.useState({});
  const [formObject, setFormObject] = useState({
    amount_in_words: "",
    balance_due: 0.0,
    business_name: "",
    company_logo_image_url: "",
    customer_address: "",
    customer_address_optional: "",
    customer_mobile: "",
    customer_name: "",
    date_time: "2022-10-27T14:23:00.548Z",
    gst_reg_no: "",
    image_url: "",
    InvoiceItems: [],
    kata_parchi: "",
    lorry_no: "",
    pan_number: "",
    payment_type: "",
    received: 0.0,
    retailer_signature: "",
    state_code: "",
    state_of_supply: "",
    tax: 0,
    tax_type: "",
    template_id: 1,
    total_amount: 250
  });
  const componentRef = useRef();
  let netTotalAmount = 0;
  // useEffect(() => {
  //   console.log("data is ===> ", formObject);
  // }, [formObject]);

  // useEffect(() => {
  //   fetchDetails();
  // }, []);

  // useEffect(() => {
  //   getroduct();
  // }, []);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    console.log(user);
    setUserInfo(user);
    fetchDetails();
    getroduct(user.ID);
  }, []);

  const getroduct = async (id) => {
    console.log('userInfpooooo', userInfo, id)
    let url = `https://ayekartproxycorsserver.herokuapp.com/${ApiUrl}/v2/merchant/${id}/orders/received?except_status=CANCELLED,Order%20Delivered&page=1&limit=10&text=`;
    const { data } = await Axios.get(
      `${local_access}${url}`
    );
    console.log('dataaaaaa 115', data.data.merchat_orders)
    const filteredData = data.data.merchat_orders.filter((data) => data.Order.ID == param.get("id") ) || []
    console.log('filteeredDAtataaa', filteredData)
    setProductDetail(filteredData)
    
  }
  console.log("dataaa 9444", productDetails);
  const fetchDetails = async () => {
    const { data } = await Axios.get(
      `https://ayekartproxycorsserver.herokuapp.com/http://128.199.23.167:9000/v1/user/2/business/2/invoice`
    );

    console.log("Hisab Invoice Data is coming ", data.data);
    const details = data.data;
    // const converted=JSON.parse(data.replace('/', '-'));

    setInvoiceDetails(InvoiceDetails);
  };

  const createInvoice = () => {
    const {customer_address_optional, ...rest} = formObject
   axios.post('https://ayekartproxycorsserver.herokuapp.com/http://128.199.23.167:9000/v1/user/2/business/2/invoice', rest).then(res => {
    console.log('resssssssss', res)
    if (res.status === 201) {
      toast.success("Invoice created successfully");
    }
   })
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  var num =
    "zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(
      " "
    );
  var tens = "twenty thirty forty fifty sixty seventy eighty ninety".split(" ");

  function number2words(n) {
    if (n < 20) return num[n];
    let digit = n % 10;
    if (n < 100) return tens[~~(n / 10) - 2] + num[digit];
    if (n < 1000)
      return num[~~(n / 100)] + " hundered" + " " + number2words(n % 100);
    return (
      number2words(~~(n / 1000)) + " thousand" + " " + number2words(n % 1000)
    );

  }
//   const defaultData = [
//     {
      

     
//     }
//   ];
  
    const getData = row => {
      console.log(row, "rows data");
      const updatedValue = row.map((val) => {
        return {
          business_id: Number(val.business_id),
          cess_amount: Number(val.cess_amount),
          cess_percentage: Number(val.cess_percentage),
          description: val.description,
          new_unit_price: Number(val.new_unit_price),
          product_service_name: val.product_service_name,
          quantity: Number(val.quantity),
          unit: Number(val.unit)
        }
      })
     // formObject.template_id = 1,
      formObject.tax = parseFloat(formObject.tax)
      formObject.InvoiceItems.push(...updatedValue)
      console.log('formobjectttttttt', formObject)
    };
  return (
    <div id="invoiceContainer">
      <Loader loading={loader} />
      <Header />
      <div className="save_button">
        <Button
          variant="outline-secondary"
          id="button-addon1"
          onClick={() => {
            window.print();
          }}
        >
          Print
        </Button>
        <Button
        variant="outline-secondary"
        id="button-addon1"
        className="create-invoice-button"
        onClick={createInvoice}
      >
        Save
      </Button>
      <Button
        variant="outline-secondary"
        id="button-addon1"
        className="create-invoice-button"
        onClick={() => navigate('/invoiceList')}
      >
        Invoice List
      </Button>
      </div>

      <Row>
        <Col className="invoice-details-box-column-1">
          <h6 className="invoice-to-text">Invoice To</h6>
          <Row>
            <Col>
              <div>
                <Form.Label className="fieldLabel">Business Name</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Business Name"
                  value={formObject.business_name}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, business_name: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">
                  Customer Name
                </Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Customer Name"
                  value={formObject.customer_name}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, customer_name: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">Customer Billing Address</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Customer Billing Address (Optional)"
                  value={formObject.customer_address}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, customer_address: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">Customer Shipping Address</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Customer Shipping Address (Optional)"
                  value={formObject.customer_address_optional}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, customer_address_optional: val };
                    });
                  }}
                />
                   <Form.Label className="fieldLabel">State/UT Code</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="State/UT Code (Optional)"
                  value={formObject.state_code}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, state_code: val };
                    });
                  }}
                />
                
               
                
              </div>
            </Col>
            <Col>
              <div>
              <Form.Label className="fieldLabel">Customer Mobile</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Customer Mobile (Optional)"
                  value={formObject.customer_mobile}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, customer_mobile: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">Lorry Number</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Lorry Number"
                  value={formObject.lorry_no}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, lorry_no: val };
                    });
                  }}
                />
                  <Form.Label className="fieldLabel">Kata Parchi</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Kata Parchi"
                  value={formObject.kata_parchi}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, kata_parchi: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">Pan Number</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Pan Number"
                  value={formObject.pan_number}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, pan_number: val };
                    });
                  }}
                />
                 <Form.Label className="fieldLabel">GSTIN</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="GSTIN"
                  value={formObject.tax}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, tax: val };
                    });
                  }}
                />
              </div>
              <div>
             
                
              </div>
            </Col>
          </Row>
        </Col>
       
      </Row>

      {/* <Row className="invoice-number-section">
        <Col>
         
          <Form.Label className="fieldLabel">Invoice Date</Form.Label>
          <DatePicker
            className="date-picker"
            placeholderText="Invoice Date"
           selected={invoiceDateDisplay}
          // value={invoiceDateDisplay}
            onChange={(date) => {
              console.log(
                "moment",
                moment(date).format(),
                moment(date).format("yyyy/mm/dd")
              );
              setInvoiceDateDisplay(date);
              setFormObject((prevState) => {
                return { ...prevState, invoice_date: moment(date).format() };
              });
            }}
          />
        </Col>
        <Col>
          <Form.Label className="fieldLabel">Invoice Number</Form.Label>
          <Form.Control
            placeholder="Invoice Number"
            value={formObject.invoice_no}
            onChange={(e) => {
              const val = e.target.value;
              setFormObject((prevState) => {
                return { ...prevState, invoice_no: val };
              });
            }}
          />
        </Col>
        <Col xs={5}>
          <Form.Label className="fieldLabel">Other References</Form.Label>
          <Form.Control
            placeholder="Other References"
            value={formObject.other_ref}
            onChange={(e) => {
              const val = e.target.value;
              setFormObject((prevState) => {
                return { ...prevState, other_ref: val };
              });
            }}
          />
        </Col>
      </Row> */}
      
      <div className="App">
        <h1>Products</h1>
        <EditableProduct
          initWithoutHead
        //   defaultData={defaultData}
          getData={getData}
          fieldsArr={fieldsArr}
        />
      </div>
     
     
     
      {/*       
      : null
} */}

      <div className="bottom-section">
        <Box>
          <Grid container spacing={3}>
            {/* <div className="bank-details">
              <Grid item xs>
                <h6>Bank Details</h6>
                <p>Ayekart Fintech Private Limited</p>
                <p>Account Number: 641905052652</p>
                <p>Bank Name: ICICI Bank (Current Account)</p>
                <p>Branch Name: Malad East</p>
                <p>IFSC: ICIC006419</p>
              </Grid>
            </div> */}
            
            <Grid   className="taxable-table">
              <h6>Total Taxable (INR)</h6>
              <TableContainer className="table-container">
                <Table
                  sx={{ minWidth: 700 ,
                        marginLeft:10
                }}
                  size="small"
                  aria-label="a dense table"
                >
                    <TableRow>
                      <TableCell width={"20%"}>IGST</TableCell>
                      <TableCell width={"20%"}>18%</TableCell>
                      <TableCell width={"20%"}>736</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"20%"}>CGST</TableCell>
                      <TableCell width={"20%"}>18%</TableCell>
                      <TableCell width={"20%"}>736</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"20%"}>SGST</TableCell>
                      <TableCell width={"20%"}>6%</TableCell>
                      <TableCell width={"20%"}>9</TableCell>
                    </TableRow>
                 
                  
                    <TableRow>
                      <TableCell width={"20%"}>CESS</TableCell>
                      <TableCell width={"20%"}>12%</TableCell>
                      <TableCell width={"20%"}>9</TableCell>
                    </TableRow>
                 
                  
                    <TableRow>
                      <TableCell width={"20%"}>Net Payable Amount</TableCell>
                      <TableCell width={"20%"}>{}</TableCell>
                      <TableCell width={"20%"}>{netTotalAmount}</TableCell>
                     
                    </TableRow>
                 
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container item xs>
            <div className="amountpayableContainer">
              <Grid  xs={5}>
                <h6>Amount Payable in Words</h6>
                <p>{number2words(netTotalAmount)}</p>
              </Grid>
            </div>
            <div className="signatureContainer">
              <Grid item xs={2}>
                <h6>Signature</h6>
              </Grid>
            </div>
          </Grid>
        </Box>
      </div>
     
      <div>
        {/* <Col className="invoice-details-box-column-1">
          <p>
            Amount Payable In Words
            <TextField id="standard-basic" label="" variant="standard" />
         
          </p>
        </Col> */}
      </div>
      <Toaster />
    </div>
  );
}
export default Invoice;
