import Header from "./Header";
import './../assets/css/style.css'
import openDemand from './../assets/images/open-demand.png';
import cancelled from './../assets/images/cancelled.png';
import wheat from './../assets/images/wheat.jpg';
import demandRequest from './../assets/images/demand-request.png';
import React, { useEffect } from "react";
import { ApiUrl, ApiUrl2, local_access } from "../constant";
import { Toaster } from "react-hot-toast";
import Loader from "./loader";
import { useNavigate } from "react-router-dom";

function Demand() {
    
    const navigate = useNavigate();
    const [orders, setOrder] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [Commodities, setCommodites] = React.useState([]);
    const [demands, setDemands] = React.useState([]);
    const [demandRequest, setDemandRequest] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState({})
    const [loader, setLoader] = React.useState(false);
    const [demandTab,setDemandTab]=React.useState({
        openDemand:0,
        quotationPending:0,
        closed:0,
        cancelled:0
        
    })

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userInfo'))
        console.log(user)
        setUserInfo(user)    
    },[])

    useEffect(() => {
        getOrders();
        getCategory()
        getComodities();
        sellerOpenDemand();
    },[userInfo])

    const getOrders = (status) => {
        setLoader(true)
        if(userInfo.ID){
            try{
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/${userInfo.ID}/demand-status`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.code === 200){
                if(data?.data&&data.data.length>0){
                    let resData=data.data;
                    setOrder(data?.data);
                    resData.map(item=>{
                        if(item?.status=="Open") {
                            demandTab.openDemand=item.count;
                        }else if(item?.status=="Cancelled"){
                            demandTab.cancelled=item.count;
                        }else if(item?.status=="Closed"){
                           demandTab.closed=item.count;
                        }else if(item?.status=="Assigned"){
                            demandTab.quotationPending=item.count;
                        }

                    })
             
                }
                console.log("count is displayed",data.data);
            }
        });
    }catch{
        setLoader(false)
    }
        }
    }

    const getCategory = () => {
        try{
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/product-list/cat`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.code === 200){
                setCategories(data?.data)
            }
        });
    }catch{
        setLoader(false)
    }
    
    }

    const getComodities = () => {
        try{
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/commodity`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.code === 200){
                setCommodites(data?.data)
            }
        });
    }catch{
        setLoader(false)
    }
    }
    // https://ayekartproxycorsserver.herokuapp.com/
    const sellerOpenDemand = () => {
        if(userInfo.ID){
            try{
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/b2b/ayekart/api/demand/seller/?seller_id=${userInfo.ID}&seller_status=Open`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.message === 'done'){
                setDemands(data?.Data)
            }
        });
    }catch{
        setLoader(false)
    }
        }
    }

    const submitDemand = () => {

    }


  return (
    <div>
    <Loader loading={loader} />
      <Header />
      <div className="container-fluid mt-3">
        <div className="custom-breadcrum">
            <span className="disable-color"  style={{cursor:'pointer'}} onClick={() => navigate('/home')}>Home</span> 
            <span className="disable-color"> Demand</span>
        </div>
        <hr></hr>
    </div>
    <div className="container-fluid mt-3" style={{ padding: '10px 50px' }}>
        <div className="row">
            <div className="col col-12 col-md-6 col-lg-2 request-div-parent mx-auto">
                <div className="demand-request-div text-center" style={{ backgroundColor: '#f3e1e9', borderRadius: '30px', cursor:'pointer' }} onClick={() => navigate(`/demandRequest/${userInfo.ID}/Open`)}>
                    <div style={{ padding: '20px 20px' }}>
                        <p><img alt="" width="20" src={openDemand}/></p>
                        <h5>{demandTab.openDemand}</h5>
                        <p>Open Demands</p>
                    </div>
                </div>
            </div>
            <div className="col col-12 col-md-6 col-lg-2 request-div-parent mx-auto">
                <div className="demand-request-div text-center" style={{ backgroundColor: '#f3f1e1', borderRadius: '30px' , cursor:'pointer' }} onClick={() => navigate(`/demandRequest/${userInfo.ID}/Assigned`)}>
                    <div style={{ padding: '20px 20px' }}>
                        <p><img width="20" src={openDemand} alt=""/></p>
                        <h5>{demandTab.quotationPending}</h5>
                        <p>Quotation Pending</p>
                    </div>
                </div>
            </div>
            <div className="col col-12 col-md-6 col-lg-2 request-div-parent mx-auto">
                <div className="demand-request-div text-center" style={{ backgroundColor: '#e1f3e6', borderRadius: '30px' , cursor:'pointer' }} onClick={() => navigate(`/demandRequest/${userInfo.ID}/Closed`)}>
                    <div style={{ padding: '20px 20px' }}>
                        <p><img width="20" src={openDemand} alt=""/></p>
                        <h5>{demandTab.closed}</h5>
                        <p>Closed</p>
                    </div>
                </div>
            </div>
            <div className="col col-12 col-md-6 col-lg-2 request-div-parent mx-auto">
                <div className="demand-request-div text-center" style={{ backgroundColor:  '#e1f0f3', borderRadius: '30px'  , cursor:'pointer' }} onClick={() => navigate(`/demandRequest/${userInfo.ID}/Cancelled`)}>
                    <div style={{ padding: '20px 20px' }}>
                        <p><img width="20" src={cancelled} alt=""/></p>
                        <h5>{demandTab.cancelled}</h5>
                        <p>Cancelled</p>
                    </div>
                </div>
            </div>
        </div>
        <h5 className="primary-color">Categories</h5><br></br>
        <div className="row">
        {
            categories.map((item,index) => {
                if(index < 3){
                    return(                    
                    <div className="col col-12 col-md-6 col-lg-2 request-div-parent mx-auto" onClick={() => navigate('/addDemand/'+item.id+'/cat')}>
                        <img className="img-fluid cat-img" src={item.image_URL} alt=""/>
                        <p className="cat-text">{item.product_name}</p>
                    </div>
                )
                }
                
            })
        }       
        {
            categories.length > 3 &&
              
        <div className="col col-12 col-md-6 col-lg-2 request-div-parent mx-auto" data-toggle="modal" data-target="#exampleModal" style={{
    marginBottom: '45px', backgroundColor: '#b3d5ed', borderRadius: '10%',display: 'flex', justifyContent: 'center', alignItems: 'center', cursor:'pointer' }}>
            <p className="text-dark">View All</p>
        </div>
            
        }   
        </div>
        <h5 className="primary-color">Sub-Category</h5><br></br>
        <div className="row">
        {
            Commodities.map((item,index) => {
                if(index < 3){
                    return(  
                <div className="col col-12 col-md-6 col-lg-2 request-div-parent mx-auto"  onClick={() => navigate('/addDemand/'+item.ID+'/sub')}>
                    <img className="img-fluid cat-img" src={item.commodity_image} alt=""/>
                        <p className="cat-text">{item.commodity_name}</p>
                </div>
                )
                }
            })
        }   
        {
            Commodities.length > 3 &&
                   
        <div className="col col-12 col-md-6 col-lg-2 request-div-parent mx-auto" data-toggle="modal" data-target="#exampleModal1" style={{ 
    marginBottom: '45px',
backgroundColor: '#b3d5ed', borderRadius: '10%',display: 'flex', justifyContent: 'center', alignItems: 'center', cursor:'pointer' }}>
            <p className="text-dark">View All</p>
        </div>
        }  
        </div>
    </div>
    <div className="demand-bottom-div">
        <h4 className="primary-color">Did not find what you looking for ?</h4>
        <button className="create-demand-button" onClick={() => navigate('/addDemand/0/sub')}>Create Demand</button>
    </div>
    <div className="container-fluid mt-3">
        <div className="col col-12 col-md-12 col-lg-12 demand-request-stripe" style={{cursor:'pointer'}} onClick={() => navigate('/sellersRequest')}>
            <img alt="" width="30" src={demandRequest} /> &nbsp;&nbsp;&nbsp;<span className="primary-color"> You have {demands.length} Demand Requests , Click here to view them </span>
        </div>
    </div>

    <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Categories</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body table-responsive" style={{textAlign: 'left'}}>
            <table className="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        categories.map((item,index) => {
                            return (
                            <tr key={index}>
                                <td>
                                    <img width={'50px'} height={'50px'} src={item.image_URL} alt=""/>
                                </td>
                                <td>
                                    {item.product_name}
                                </td>
                            </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
        </div>
    </div>
    </div>

    <div className="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Sub Categories</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body table-responsive" style={{textAlign: 'left'}}>
            <table className="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Commodities.map((item,index) => {
                            return (
                            <tr key={index} onClick={() => navigate('/addDemand/'+item.ID)} >
                                <td style={{cursor:'pointer'}}>
                                    <img width={'50px'} height={'50px'} src={item.commodity_image} alt=""/>
                                </td>
                                <td style={{cursor:'pointer'}}>
                                    {item.commodity_name}
                                </td>
                            </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
        </div>
    </div>
    </div>
    
    <Toaster />
    </div>
  );
}

export default Demand;
