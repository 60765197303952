import moment from "moment";
import React, { useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { FileUploader } from "react-drag-drop-files";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiUrl, ApiUrl1, ApiUrl2, local_access } from "../constant";
import Header from "./Header";
import Loader from "./loader";
function DemandRequest() {
  const navigate = useNavigate();
  const location = useLocation();

  const [loader, setLoader] = useState(false);
  const [userInfo, setUserInfo] = React.useState({});
  const [demand, setDemands] = React.useState([]);
  const [demandId, setDemandId] = React.useState(0);
  const [status, setStatus] = React.useState(null);
  const [selectedDemand, setSelDemand] = React.useState({});
  const [assignedItem, setAssigneditem] = React.useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if (location.pathname.split("/")[2]) {
      setDemandId(location.pathname.split("/")[2]);
      setStatus(location.pathname.split("/")[3]);
      setUserInfo(user);
      getDemands(location.pathname.split("/")[3]);
    } else {
      navigate("/demand");
    }
  }, []);

  useEffect(() => {
    getDemands(status);
  }, [userInfo, status]);

  const getDemands = (stat) => {
    if (userInfo.ID) {
      setLoader(true);
      const requestOptions = {
        crossDomain: true,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let url = "";
      if (status === "Open" || status === "Cancelled") {
        url = `/v1/merchant/${userInfo.ID}/demand?status=${stat}`;
      } else if (status === "Assigned" || status === "Closed") {
        url = `/v1/merchant/${userInfo.ID}/respond-demand?status=${stat}`;
      }
      fetch(`${url}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setLoader(false);
          if (data.code === 200) {
            let resArr = data?.data;
            console.log("res comming  =>> ", resArr);
            if (status === "Open") {
              let newArr = resArr.sort(function (a, b) {
                return a.ID - b.ID || a.ID.localeCompare(b.ID);
              });
              setDemands(newArr.reverse());
              console.log("all arry data =>> ", newArr);
            } else {
              const newArray = [
                ...new Map(resArr.map((m) => [m.demand_id, m])).values(),
              ];
              console.log("new test", newArray);
              setDemands(newArray);
            }
          }
        });
    }
  };

  console.log(demand);
  const temp = demand?.sort((a, b) => (a.CreatedAt > b.CreatedAt ? 1 : -1));
  console.log(temp);

  const filt = temp.filter((item, index) => item.Demand.ID === 66);
  console.log(filt);

  const changeStatus = (item) => {
    setLoader(false);
    const data = {
      seller_id: selectedDemand.user_id,
      demand_id: selectedDemand.ID,
      buyer_id: userInfo.ID,
    };

    const requestOptions = {
      crossDomain: true,
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let url = `/b2b/ayekart/api/demand/buyer/accept`;

    fetch(`${url}`, requestOptions).then((response) => {
      if (response.status === 404) {
        setLoader(true);
        toast.error("something went wrong");
      } else if (response.status === 200) {
        setLoader(true);
        console.log(data);
        addToCard(item);
      } else {
        setLoader(false);
      }
    });
  };

  const addToCard = (item) => {
    setLoader(true);
    const data = {
      cart_items: [],
      user_id: userInfo.ID,
    };
    for (let val of item.DemandProducts) {
      data.cart_items.push({
        color_size_variant: "",
        measurement_unit: val.measurement_unit,
        pack_sizes: `${val.pack_size}`,
        product_cities: val.Shop.city,
        product_id: val.ID,
        quantity: parseInt(item.product_quantity),
        shipping_status: item.status,
      });
    }

    const requestOptions = {
      crossDomain: true,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let url = `/v1/merchant/${userInfo.ID}/cart`;

    fetch(`${url}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.code === 201) {
          createOrder(data.data);
        }
      });
  };

  const createOrder = (value) => {
    setLoader(true);
    const data = {
      cart_id: value.ID,
      user_id: userInfo.ID,
      shipping_address: selectedDemand.shipping_address,
      billing_address: selectedDemand.shipping_address,
      payment_method: selectedDemand.is_supply_chain_finance_require
        ? "Supply Chain Finance"
        : "",
      payment_status: selectedDemand.is_supply_chain_finance_require
        ? "Supply Chain Finance/Pending"
        : "pending",
      delivery_method: "Courier",
      discount: 0,
      order_status: "Pending",
      shipping_status: "Pending",
      total_amount: 12000.0,
      reward_amount: 0,
      seller_mobile: userInfo.mobile_number,
      is_demand_order: true,
    };

    const requestOptions = {
      crossDomain: true,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let url = `/v1/merchant/${userInfo.ID}/cart/171/order`;

    fetch(`${url}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.code === 201) {
          if (selectedDemand.is_supply_chain_finance_require) {
            initialEmail(data.data);
          }
          if (selectedDemand.vehicle_type !== "") {
            logisticRequested(data.data);
          }
          updateDemandStatus(data.data);
        }
      });
  };

  const initialEmail = (value) => {
    setLoader(true);
    const data = {
      id: value.user_id,
      order_id: value.ID,
      total_amount: value.total_amount,
    };

    const requestOptions = {
      crossDomain: true,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let url = `/ayekart_only_bnp/initial_email`;

    fetch(`${url}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (selectedDemand.is_supply_chain_finance_require) {
        }
      });
  };

  const logisticRequested = (value) => {
    setLoader(true);
    const data = {
      requested_date_of_shipment: selectedDemand.delivery_date,
      destination_address: selectedDemand.delivery_address,
      merchant_id: userInfo.ID,
      merchant_mobile: userInfo.mobile_number,
      merchant_name: `${userInfo.first_name} ${userInfo.last_name}`,
      order_id: value.ID,
      requested_vehicle_type: selectedDemand.vehicle_type,
      seller_id: selectedDemand.User?.ID,
      shop_id: selectedDemand.User?.Shop?.ID,
      shop_name:
        selectedDemand.User?.Shop?.name !== ""
          ? selectedDemand.User?.Shop?.name
          : "Test",
    };

    const requestOptions = {
      crossDomain: true,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let url = `/b2b/api/logistics/save_info`;

    fetch(`${url}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
      });
  };

  const updateDemandStatus = (value) => {
    setLoader(true);
    const data = {
      status: "Closed",
    };

    const requestOptions = {
      crossDomain: true,
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let url = `/v1/merchant/${userInfo.ID}/demand/${selectedDemand.ID}`;

    fetch(`${url}`, requestOptions).then((response) => {
      setLoader(false);
      if (response.status === 200) {
        connectOrder(value);
      } else {
        toast.error("something went wrong");
      }
      // sendMessageWP()
    });
  };

  const sendMessageWP = () => {
    setLoader(true);
    const data = {
      status: "Closed",
    };

    const requestOptions = {
      crossDomain: true,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let url = `https://api.kaleyra.io/v1/HXIN1714162191IN/messages`;

    fetch(`${url}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
      });
  };

  const connectOrder = (value) => {
    setLoader(true);
    const data = {
      seller_id: selectedDemand.user_id,
      demand_id: selectedDemand.ID,
      order_id: value.ID,
    };

    const requestOptions = {
      crossDomain: true,
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let url = `/b2b/ayekart/api/demand/order_update`;

    fetch(`${url}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.message === "done") {
          getDemands(status);
          toast.success("proposal updated");
        } else {
          toast.error("something went wrong");
        }
      });
  };

  const removeDemand = async (id) => {
    const result = await confirm(
      "Are you sure you want to delete this Request"
    );
    if (result) {
      setLoader(true);
      const data = {
        status: "Cancelled",
      };

      const requestOptions = {
        crossDomain: true,
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let url = `/v1/merchant/${userInfo.ID}/demand/${id}`;

      fetch(`${url}`, requestOptions).then((response) => {
        setLoader(false);
        if (response.status === 200) {
          toast.success("Request deleted successfully");
        } else {
          toast.error("something went wrong");
        }
      });
    }
  };

  const getDemandsOnClick = (status) => {
    setStatus(status);
    // getDemands(status);
  };

  const setSelectedDemand = (item) => {
    console.log(item);
    setSelDemand(item);
  };

  const setAssignedItem = (item) => {
    setSelDemand(item.Demand);
    setAssignedItem(item);
  };

  return (
    <div>
      <Loader loading={loader} />
      <Header />
      <div className="container-fluid mt-3">
        <div className="custom-breadcrum">
          <span
            className="disable-color"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/home")}
          >
            Home{" "}
          </span>
          <span className="disable-color"> Demand </span>
          <span>{status} Requests</span>
        </div>
        <hr></hr>
        <div className="tab-panel-ui container">
          <div className="row">
            <div className="col col-lg-7">
              <div className="panel with-nav-tabs panel-default">
                <div className="panel-heading">
                  <ul className="nav nav-tabs demand-tabs mb-3">
                    <li className={status === "Open" ? "active" : ""}>
                      <a
                        href="#tab2default"
                        data-toggle="tab"
                        onClick={() => getDemandsOnClick("Open")}
                      >
                        Open
                        {/* <span className="tab-count">(69)</span> */}
                      </a>
                    </li>
                    <li className={status === "Assigned" ? "active" : ""}>
                      <a
                        href="#tab3default"
                        data-toggle="tab"
                        onClick={() => getDemandsOnClick("Assigned")}
                      >
                        Quotation Pending{" "}
                      </a>
                    </li>
                    <li className={status === "Closed" ? "active" : ""}>
                      <a
                        href="#tab3default"
                        data-toggle="tab"
                        onClick={() => getDemandsOnClick("Closed")}
                      >
                        Closed{" "}
                      </a>
                    </li>
                    <li className={status === "Cancelled" ? "active" : ""}>
                      <a
                        href="#tab4default"
                        data-toggle="tab"
                        onClick={() => getDemandsOnClick("Cancelled")}
                      >
                        Cancelled{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="panel-body">
                  <div className="tab-content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        {(status === "Open" || status === "Cancelled") && (
          <div className="row">
            {demand.map((item, index) => {
              return (
                <div className="col-md-4">
                  <div className="input-container-pro">
                    <h6>
                      <span className="text-primary">Demand Id: </span>{" "}
                      {item.ID}
                    </h6>
                    <h6>
                      <span className="text-primary">
                        Expected Delivery Date:{" "}
                      </span>{" "}
                      {moment(item.delivery_date).format("DD MMM YYYY")}
                    </h6>
                    <h6>
                      <span className="text-primary">Shipping Address: </span>{" "}
                      {item.delivery_address}
                    </h6>
                    <h6>
                      <span className="text-primary">PinCode: </span>{" "}
                      {item.pincode}
                    </h6>
                    <h6>
                      <span className="text-primary">Remarks: </span>{" "}
                      {item.remarks}
                    </h6>
                    <h6>
                      <span className="text-primary">
                        Supply Chain Finance Require:{" "}
                      </span>{" "}
                      {item.is_supply_chain_finance_require ? "Yes" : "No"}
                    </h6>
                    <h6 style={{ textAlign: "right", color: "lightgray" }}>
                      <span>Requested on: </span>{" "}
                      {moment(item.CreatedAt).format("DD MMM YYYY HH:mm A")}
                    </h6>

                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "30px" }}
                    >
                      <div className="col">
                        <button
                          className="submit-button-pro-1"
                          onClick={() => setSelectedDemand(item)}
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          View Product
                        </button>
                      </div>
                      {status === "Open" && (
                        <div className="col">
                          <button
                            className="btn btn-secondary demand-close btn-block"
                            onClick={() => removeDemand(item.ID)}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {(status === "Assigned" || status === "Closed") && (
          <div className="row">
            {demand.map((item, index) => {
              return (
                <div className="col-md-4">
                  <div className="input-container-pro">
                    <h6>
                      <span className="text-primary">Demand Id: </span>{" "}
                      {item.demand_id}
                    </h6>
                    <h6>
                      <span className="text-primary">
                        Expected Delivery Date:{" "}
                      </span>{" "}
                      {moment(item.Demand?.delivery_date).format("DD MMM YYYY")}
                    </h6>
                    <h6>
                      <span className="text-primary">Shipping Address: </span>{" "}
                      {item.Demand?.shipping_address}
                    </h6>
                    <h6>
                      <span className="text-primary">PinCode: </span>{" "}
                      {item.Demand?.destination_pincode}
                    </h6>
                    <h6>
                      <span className="text-primary">Remarks: </span>{" "}
                      {item.Demand?.remarks}
                    </h6>
                    <h6>
                      <span className="text-primary">
                        Supply Chain Finance Require:{" "}
                      </span>{" "}
                      {item.Demand?.is_supply_chain_finance_require
                        ? "Yes"
                        : "NO"}
                    </h6>

                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "30px" }}
                    >
                      <div className="col">
                        <button
                          className="submit-button-pro-1"
                          onClick={() => setAssignedItem(item)}
                          data-toggle="modal"
                          data-target={`#exampleModal1${index}`}
                        >
                          View Proposals
                        </button>
                        <div
                          class="modal fade"
                          id={`exampleModal1${index}`}
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-md" role="document">
                            <div class="modal-content">
                              <div
                                class="modal-body"
                                style={{ textAlign: "left" }}
                              >
                                <h6 className="text-primary">Proposal #1</h6>
                                {selectedDemand?.DemandProducts?.map(
                                  (item1, index) => {
                                    return (
                                      <>
                                        <h6>
                                          <b>{item1.product_name}</b>
                                        </h6>
                                        <h6>
                                          Offer Quality :{" "}
                                          {item1.product_quality}
                                        </h6>
                                        <h6>
                                          Expected Quantity :{" "}
                                          {item1.product_quantity}{" "}
                                          {item.measurement_unit}
                                        </h6>
                                        <h6>
                                          Source Location : {item1.Shop?.city}
                                        </h6>
                                        <h6>
                                          Offer Price : {item.ayekart_price} /{" "}
                                          {item1.measurement_unit}
                                        </h6>
                                        <hr />
                                      </>
                                    );
                                  }
                                )}
                                {status === "Assigned" && (
                                  <button
                                    className="btn btn-primary btn-block"
                                    data-dismiss="modal"
                                    onClick={() =>
                                      changeStatus(selectedDemand.ID)
                                    }
                                  >
                                    Accept & Place order
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {status === "Assigned" && (
                        <div className="col">
                          <button
                            className="btn btn-secondary demand-close btn-block"
                            onClick={() => removeDemand(item.demand_id)}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Toaster />
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-body" style={{ textAlign: "left" }}>
              {selectedDemand?.DemandProducts?.map((item, index) => {
                return (
                  <>
                    <h6 className="text-primary">Product #{index + 1}</h6>
                    <h6>
                      <b>{item.product_name}</b>
                    </h6>
                    <h6>Expected Quality : {item.product_quality}</h6>
                    <h6>
                      Expected Quantity : {item.product_quantity}{" "}
                      {item.measurement_unit}
                    </h6>
                    {/* <h6>Source Location : {item.Shop?.city}</h6>
                                            <h6>Offer Price : {item.product_price} / {item.measurement_unit}</h6> */}
                    <hr />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DemandRequest;
