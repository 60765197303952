const fieldsArr = [
    {
      label: "Sr No",
      name: "business_id",
      type: "number",
      validation: e => {
        const name = e.target.value;
        if (name.length > 6) {
          return false;
        } else {
          return true;
        }
      },
      error: "Enter a good name"
    },
    {
      label: "Product Name",
      name: "product_service_name",
      validation: e => {
        const name = e.target.value;
        if (name.length > 6) {
          return false;
        } else {
          return true;
        }
      },
      error: "Haha"
    },
    {
      label: "Description",
      name: "description",
      validation: e => {
        const name = e.target.value;
        if (name.length > 10) {
          return false;
        } else {
          return true;
        }
      },
      error: "Haha"
    },
    {
        label: "Quantity",
        name: "quantity",
        validation: e => {
          const name = e.target.value;
          if (name.length > 6) {
            return false;
          } else {
            return true;
          }
        },
        error: "Haha"
      },
      {
        label: "Unit Price",
        name: "unit",
        validation: e => {
          const name = e.target.value;
          if (name.length > 6) {
            return false;
          } else {
            return true;
          }
        },
        error: "Haha"
      },
      {
        label: "New Unit Price",
        name: "new_unit_price",
        validation: e => {
          const name = e.target.value;
          if (name.length > 6) {
            return false;
          } else {
            return true;
          }
        },
        error: "Haha"
      },
      {
        label: "GST",
        name: "cess_percentage",
        validation: e => {
          const name = e.target.value;
          if (name.length > 6) {
            return false;
          } else {
            return true;
          }
        },
        error: "Haha"
      },
      {
        label: "Total CESS Amount",
        name: "cess_amount",
        validation: e => {
          const name = e.target.value;
          if (name.length > 6) {
            return false;
          } else {
            return true;
          }
        },
        error: "Haha"
      },
      
    
  ];
  
  export default fieldsArr;
  