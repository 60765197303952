import React,{ useLocation, useNavigate } from "react-router-dom";
import logo from "./../assets/images/logo.png";
import Logout from "./../assets/images/logout.png";
import zoom from "./../assets/images/zoom.png";
import { useEffect, useState } from "react";

function Header() {

  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = (page) => {
    navigate("/"+page);
  }
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    if(!localStorage.getItem('userInfo')){
      redirectTo('')
    }
    setPageTitle(location.pathname.split('/')[1].replace(/([a-z])([A-Z])/g, '$1 $2'))
  },[])
  

  const logout = () => {
    localStorage.clear()
    redirectTo('')
  }
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-white"
      style={{
        boxShadow: "0px 4px 14.7px 1.3px rgba(0, 0, 0, 0.14)",
        marginBottom: "0px",
      }}
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <a href={() => false} onClick={() => redirectTo("home")} className="navbar-brand mt-2 mt-lg-0" style={{cursor:'pointer'}}>
            <img src={logo} height="30" alt="MDB Logo" loading="lazy" />
          </a>
          <ul
            className="navbar-nav me-auto mb-2 mb-lg-0"
            style={{
              position: "absolute",
              left: "50%",
              marginLeft: "-50px !important",
              display: "block",
              color: "#286d94",
            }}
          >
            <li className="nav-item">
              <a
                className="nav-link"
                style={{ color: "#286d94", fontWeight: 500 }}
                href={() => false}
              >
                Mini ERP <br />
                <span className="page-title">{pageTitle}</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex align-items-center">
          <a
            className="text-reset me-3"
            style={{ marginRight: "50px" }}
            href={() => false}
            onClick={logout}
          >
            <img alt="" style={{ height: '20px' }} src={Logout} />
            &nbsp;Logout
          </a>
          {/* <a className="text-reset me-3 mr-10" href={() => false}>
            <img alt="" style={{ height: '20px' }} src={zoom} />
          </a> */}
        </div>
      </div>
    </nav>
  );
}

export default Header;
