import Header from "./Header";
import './../assets/css/style.css'
import React, { useCallback, useEffect } from "react";
import { ApiUrl, ApiUrl1, local_access } from "../constant";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./loader";
import { useLocation, useNavigate } from "react-router-dom";
import ImageViewer from 'react-simple-image-viewer';
import { confirm } from "react-confirm-box";
import aws from "aws-sdk";

function Tranches() {
    const [orders, setOrder] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState({})
    const [loader, setLoader] = React.useState(false);
    const [orderId, setOrderId] = React.useState('1233');
    const [sellerId, setSellerId] = React.useState(null);
    const [buyerId, setBuyerId] = React.useState(null);
    const [units, setUnits] = React.useState([])
    const [products, setProducts] = React.useState([]);
    const [addData, setAddData] = React.useState({
                "tranche_products": [],
                "buyer_id": null,
                "order_id": null,
                "payment_mode": "",
                "payment_status": "",
                "seller_id": null,
                "tranch_status": "",
                "tranch_value": null
});

    const [tProduct, setTProduct] = React.useState([
        {
            "color": "",
            "delivered_qty": 0,
            "measurement_unit": "",
            "ordered_qty": 0,
            "tProduct_size": "",
            "price": 0,
            "product_name": "",
            "remaining_qty": 0,
            "unitPrice": 0
        }
    ])

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userInfo'))
        setUserInfo(user)  
        if(location.pathname.split('/')[2]){
            let ids = location.pathname.split('/')
            // setOrderId(ids[2])
            setBuyerId(ids[3])
            setSellerId(ids[4])
        }else{
            navigate('/recievedOrder')
        }  
        getUnits()
    },[])

    useEffect(() => {
        getTranches()
    },[orderId])


    const getUnits = () => {
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/units`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
                setUnits(data ? data : [])
        });
    }

    const getTranches = () => {
        setLoader(true)
        
        console.log(orderId, userInfo)
        if(orderId && userInfo){
            try{
                const requestOptions = {
                    crossDomain:true,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 
                    'Access-Control-Allow-Origin':'*', }
                };
                let url = `/v1/merchant/${userInfo.ID}/tranche?order_id=${orderId}`;
                fetch(`${local_access}${url}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setLoader(false)
                    if(data.data){
                        setOrder(data.data)
                    }
                });
            }catch{
            setLoader(false)
        }
    }
}


const onChangeInput = (e) => {
    setAddData({...addData,[e.target.name]:e.target.value})
}

const onProductChange = (e,i) => {
    const tProd = tProduct;   
    tProd[i][e.target.name] = e.target.value;           
    setTProduct([...tProd]);
}

const addNewProduct = () => {
    setTProduct([...tProduct,{
        "color": "",
        "delivered_qty": 0,
        "measurement_unit": "",
        "ordered_qty": 0,
        "tProduct_size": "",
        "price": 0,
        "product_name": "",
        "remaining_qty": 0,
        "unitPrice": 0
    }])
}


const removeProduct = (index) => {
    const tProducts = tProduct;
    tProducts.splice(index,1);        
    setTProduct([...tProducts])
}


const addTranches = () => {
    setLoader(true)
    let body = addData;
    let tProd = tProduct;
    for(let item of tProd){
        if(item.delivered_qty){
            item.delivered_qty = parseInt(item.delivered_qty);
        }
        if(item.ordered_qty){
            item.ordered_qty  = parseInt(item.ordered_qty )
        }
        if(item.price){
            item.price = parseFloat(item.price)
        }
    } 
    body.order_id = parseInt(orderId);
    body.seller_id = parseInt(sellerId);
    body.buyer_id = parseInt(buyerId);
    body.tranch_value = parseFloat(body.tranch_value)
    body.tranche_products = tProd;
    let mainBody = {
        "bulk_tranches": [{...body}]   
    }
    try{
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify(mainBody),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url =  `/v1/merchant/${userInfo.ID}/tranche`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.code === 201){
                toast.success('Tranch added successfully');
                getTranches()
            }else{
                toast.error('something went wrong.')
            }
        });
    }catch{
    setLoader(false)
    toast.error('something went wrong.')
    }
}

const removeItem = async (order_id) => {
    const result = await confirm('Are you sure you want to delete Tranches')
        if (result) {
        // Save it!
            setLoader(true)
            try{
                const requestOptions = {
                    crossDomain:true,
                    method: 'DELETE',            
                    headers: { 'Content-Type': 'application/json', 
                    'Access-Control-Allow-Origin':'*', }
                };
                let url = `/v1/merchant/${userInfo.ID}/tranche/${order_id}`;
                fetch(`${local_access}${url}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setLoader(false)
                    if(data.code === 200){
                        toast.success('Tranches Deleted');
                        setAddData({})
                        getTranches();
                    }else{
                        toast.error('something went wrong')
                    }
                });
            }catch{
                setLoader(false)
            }
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }
}




  return (
    <div>
    
    <Loader loading={loader} />
      <Header />
    <div className="container-fluid mt-3">
        <div className="custom-breadcrum">
            <span className="disable-color" style={{cursor:'pointer'}} onClick={() => navigate('/home')}>Home</span> 
            <span className="disable-color"> Tranches</span>
        </div>
        <hr></hr>
        <div className="row">
            <div className="col-md-4 offset-8 text-right">
                <button data-toggle="modal" data-target="#exampleModal1" className="ui-button mr-5" >Add Tranches</button>
            </div>
        </div>
    </div>
    <div className="container-fluid mt-3">
        <div className="table-responsive">
            
            <table className="zui-table zui-table-rounded">
            <thead>
                    <tr>
                        <th>ID</th>
                        <th>Order Id</th>
                        <th>Tranch Status</th>
                        <th>Tranch Value</th>
                        <th>Payment Mode</th>
                        <th>Payment Status</th>
                        <th>Return Reason</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                     orders &&  orders.map((item,indx) => {
                        return (
                            <tr key={indx}>
                                <td><span className="order-id">#{item.ID}</span> <a  className="product-list"  data-toggle="modal" data-target="#exampleModal" onClick={() => setProducts(item.TranchProducts)}  href={() => false}>View Tranch Products</a></td>
                                <td>#{item.order_id}</td>
                                <td>{item.tranch_status}</td>
                                <td>{item.tranch_value}</td>
                                <td>{item.payment_mode}</td>
                                <td>{item.payment_status}</td>
                                <td>{item.return_reason}</td>
                                <td><button className="btn-danger btn-sm" onClick={() => removeItem(item.order_id)}>X</button></td>
                            </tr>
                        )
                    }) 
                        
                }                    
                </tbody>
            </table>
        </div>
    </div>
    <div className="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
        <div className="modal-body" style={{textAlign: 'left'}}>
        <div className="row">
                <div className="col-md-6">                
                    <div className={`input-container-pro`}>
                        <label>Tranch Value</label>
                        <input type={'text'} name="tranch_value" onChange={(e) => onChangeInput(e)} value={addData['tranch_value']}  className="input-box form-control" placeholder="Enter Proudct name" />
                        
                    </div> 
                    <div className={`input-container-pro`}>
                        <label>Tranch Status</label>
                        <select className="input-box form-control" name="tranch_status" onChange={(e) => onChangeInput(e)} value={addData['tranch_status']}>
                            <option value={"Pending"}>Pending</option>
                            
                        </select>
                    </div>  
                    <div className={`input-container-pro`}>
                        <label>Payment Mode</label>
                        <select className="input-box form-control" name="payment_mode" onChange={(e) => onChangeInput(e)} value={addData['payment_mode']}>
                            <option value={"COD"}>COD</option>
                            <option value={"Supply Chain Finance"}>Supply Chain Finance</option>
                            
                        </select>
                    </div>   
                     
                    <div className={`input-container-pro`}>
                        <label>Payment Status</label>
                        <select className="input-box form-control" name="payment_status" onChange={(e) => onChangeInput(e)} value={addData['payment_status']}>
                            <option value={"Paid"}>Paid</option>
                            <option value={"Unpaid"}>Unpaid</option>
                            
                        </select>
                    </div>                                                      
                    
                </div>
                <div className="col-md-6 ">
                <div className="row">
                {
                    tProduct.map((item,index) => {
                        return (
                            <div className="col-md-12 file-container" key={index}>                        
                        <h3>Proudct {index + 1}</h3>
                        <div className="row">
                            <div className="col-md-12">                                
                                <div className="input-container-pro">
                                    <label>Product Name</label>
                                    <input type={'text'} name="product_name" onChange={(e) => onProductChange(e, index)} value={tProduct[index]['product_name']} className="input-box form-control" placeholder="Offer price" />
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Color</label>
                                    <input type={'text'} name="color" onChange={(e) => onProductChange(e, index)} value={tProduct[index]['color']} className="input-box form-control" placeholder="MRP" />
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Ordered Qty</label>
                                    <input type={'number'} name="ordered_qty" onChange={(e) => onProductChange(e, index)} value={tProduct[index]['ordered_qty']} className="input-box form-control" placeholder="Offer price" />
                                </div> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Delivered Qty</label>
                                    <input type={'number'} name="delivered_qty" onChange={(e) => onProductChange(e, index)} value={tProduct[index]['delivered_qty']} className="input-box form-control" placeholder="Offer price" />
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Measurement Unit</label>
                                    <select className="input-box form-control" name="measurement_unit" onChange={(e) => onProductChange(e, index)} value={tProduct[index]['measurement_unit']} >
                                        <option value={''}>Select Unit</option>
                                        {
                                            units.map((item,index) => <option key={index} value={item.measurement}>{item.measurement}</option>)
                                        }
                                    </select> 
                                </div> 
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Pack size</label>
                                    <input type={'number'} name="pack_size" onChange={(e) => onProductChange(e, index)} value={tProduct[index]['pack_size']} className="input-box form-control" placeholder="Offer price" />
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Price</label>
                                    <input type={'number'} name="price" onChange={(e) => onProductChange(e, index)} value={tProduct[index]['price']} className="input-box form-control" placeholder="MRP" />
                                </div> 
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Unit Price</label>
                                    <input type={'number'} name="unitPrice" onChange={(e) => onProductChange(e, index)} value={tProduct[index]['unitPrice']} className="input-box form-control" placeholder="MRP" />
                                </div> 
                            </div>
                            <div className="input-container-pro">
                                    <label>Remaining Price</label>
                                    <input type={'number'} name="remaining_qty" onChange={(e) => onProductChange(e, index)} value={tProduct[index]['remaining_qty']} className="input-box form-control" placeholder="MRP" />
                            </div> 
                        </div>
                        { index > 0 &&
                            <button className="add-pack-btn" onClick={() => removeProduct(index)}> -Remove Product</button>
                        }
                    </div>
                        )
                    })
                }
                </div>
                <button className="add-pack-btn" onClick={() => addNewProduct()}>+Add Product</button>              
                  
                <button className="submit-button-pro" data-dismiss="modal" onClick={addTranches}>
                    Add Product
                </button>
                </div>
            </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
        </div>
        </div>
    </div>
    </div>

    
    <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Products Detail</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body" style={{textAlign: 'left'}}>
            <table className="table-responsive" style={{    display: 'table'}}>
                <tbody>
                    {
                        products ? products.map(item => {
                            return (
                                <>
                                <tr>
                                    <th>Product name</th>
                                    <th>{item.product_name}</th>
                                </tr>                                
                                <tr>
                                    <th>Delivery Quantity</th>
                                    <th>{item.delivered_qty} {item.measurement_unit}</th>
                                </tr>                               
                                <tr>
                                    <th>Ordered Quantity</th>
                                    <th>{item.ordered_qty} {item.measurement_unit}</th>
                                </tr>
                                <tr>
                                    <th>Pack size</th>
                                    <th>{item.pack_size}</th>
                                </tr> 
                                <tr>
                                    <th>Selling price</th>
                                    <th>&#8377; {item.price} / {item.measurement_unit}</th>
                                </tr>
                                <hr />
                                </>
                            )
                        })
                        :
                        <tr>
                            <td colSpan={2}>No Prouducts</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
        </div>
    </div>
    </div>
    
    <Toaster />
    </div>
  );
}

export default Tranches;
