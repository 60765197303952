import Header from "./Header";
import './../assets/css/style.css'
import React, { useCallback, useEffect } from "react";
import { ApiUrl1, local_access } from "../constant";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./loader";
import { useLocation, useNavigate } from "react-router-dom";
import ImageViewer from 'react-simple-image-viewer';
import aws from "aws-sdk";

function Shipment() {
    const [orders, setOrder] = React.useState({});
    const [userInfo, setUserInfo] = React.useState({})
    const [loader, setLoader] = React.useState(false);
    const [orderId, setOrderId] = React.useState(0);
    const [addData, setAddData] = React.useState({
        "order_id": "",
        "challan_image_url": "",
        "kata_parchi_image_url": "",
        "bilty_image_url": "",
        "truck_loading_image_url": "",
        "invoice_image_url": ""
    });

    const [currentImage, setCurrentImage] = React.useState([]);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);

    const openImageViewer = useCallback((img) => {
        setCurrentImage([img]);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userInfo'))
        setUserInfo(user)  
        if(location.pathname.split('/')[2]){
            setOrderId(location.pathname.split('/')[2])
        }else{
            navigate('/recievedOrder')
        }  

    },[])

    useEffect(() => {
        getShipment()
    },[orderId])

    const getShipment = () => {
        setLoader(true)
        if(orderId){
            try{
                const requestOptions = {
                    crossDomain:true,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 
                    'Access-Control-Allow-Origin':'*', }
                };
                let url = `/ayekart/api/b2b/ship_docs/getAll?order_id=${orderId}`;
                fetch(`${local_access}${url}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setLoader(false)
                    if(data.data){
                        setOrder(data.data)                        
                        setAddData({
                            "order_id": data.data.order_id,
                            "challan_image_url": data.data.challan_image_url,
                            "kata_parchi_image_url": data.data.kata_parchi_image_url,
                            "bilty_image_url": data.data.bilty_image_url,
                            "truck_loading_image_url": data.data.truck_loading_image_url,
                            "invoice_image_url": data.data.invoice_image_url
                        })
                    }
                });
            }catch{
            setLoader(false)
        }
    }
}


const updateShipmentDoc = () => {
    setLoader(true)
    let body = addData
    body.order_id = orderId;
    if(addData.challan_image_url == '' && 
    addData.bilty_image_url == '' &&
    addData.invoice_image_url == '' &&
    addData.kata_parchi_image_url == '' &&
    addData.truck_loading_image_url == ''){
        try{
            const requestOptions = {
                crossDomain:true,
                method: 'POST',
                body: JSON.stringify(body),
                headers: { 'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin':'*', }
            };
            let url =  `/ayekart/api/b2b/ship_docs/upload`;
            fetch(`${local_access}${url}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLoader(false)
                if(data.data === 1){
                    toast.success('shipment Doc updated successfully');
                    getShipment()
                }else{
                    toast.error('something went wrong.')
                }
            });
        }catch{
        setLoader(false)
}
    }else{
        toast.error('Please fill all fields')
    }
}


const getSignUrl = (name,file) => {
    setLoader(true)
    const s3 = new aws.S3({
      endpoint: "https://ayekart-mobile.sfo2.digitaloceanspaces.com",
      accessKeyId: "JZXLSMYTTPFGOUKX46ZW",
      secretAccessKey: "iOU3OJckYIMvOiQSsjImYSP8KjJ1b1GnBh3TNKIQkTo",
    });

    var config = {
      Body: file,
      Bucket:
        "a4920e07cf09ce0e60dff28729322c22fbbf4bbb4d9663ca8428d0a8b73fe03a",
      // Key: "Test/sampleisssmage.jpg", 
      Key: file.name,
    };

    s3.putObject(config)
      .on("build", (request) => {
        request.httpRequest.headers.Host =
          "https://ayekart-mobile.sfo2.digitaloceanspaces.com";
        request.httpRequest.headers.Host =
          "http://ayekart-mobile.sfo2.digitaloceanspaces.com";
        request.httpRequest.headers["Content-Length"] = file.size;
        request.httpRequest.headers["Content-Type"] = "image/jpg";
        request.httpRequest.headers["x-amz-acl"] = "public-read";
        request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
      })
      .send((err, data) => {
        setLoader(false)
        if (err) {
          console.log("Failed to upload file", `${err}`);
        } else {
            let obj = {
                [name]: 'https://ayekart-mobile.sfo2.digitaloceanspaces.com/a4920e07cf09ce0e60dff28729322c22fbbf4bbb4d9663ca8428d0a8b73fe03a/'+file.name
            }
            console.log(obj)
            setAddData({...addData, ...obj})
        }
      });
  };




    const onChangeInput = (e) => {
        getSignUrl(e.target.name,e.target.files[0])
    }

  return (
    <div>
    
    <Loader loading={loader} />
      <Header />
    <div className="container-fluid mt-3">
        <div className="custom-breadcrum">
            <span className="disable-color" style={{cursor:'pointer'}} onClick={() => navigate('/home')}>Home</span> 
            <span className="disable-color"> Shipment Docs</span>
        </div>
        <hr></hr>
        <div className="row">
            <div className="col-md-4 offset-8 text-right">
                <button data-toggle="modal" data-target="#exampleModal1" className="ui-button mr-5" >Upload Doc</button>
            </div>
        </div>
    </div>
    <div className="container-fluid mt-3">
        <div className="table-responsive">
            
            <table className="zui-table zui-table-rounded">
            <thead>
                    <tr>
                        <th>ID</th>
                        <th>Bilty</th>
                        <th>Challan</th>
                        <th>Invoice</th>
                        <th>Kata parchi</th>
                        <th>Truck loading</th>
                    </tr>
                </thead>
                <tbody>
                {
                    <tr>
                        <td>#{orderId}</td>
                        <td><img onClick={ () => openImageViewer(orders.bilty_image_url) } src={orders.bilty_image_url} width={'100px'} alt={'bilty'} /></td>
                        <td><img onClick={ () => openImageViewer(orders.challan_image_url) } src={orders.challan_image_url} width={'100px'} alt={'challan_image_url'} /></td>
                        <td><img onClick={ () => openImageViewer(orders.invoice_image_url) } src={orders.invoice_image_url} width={'100px'} alt={'invoice_image_url'} /></td>
                        <td><img onClick={ () => openImageViewer(orders.kata_parchi_image_url) } src={orders.kata_parchi_image_url} width={'100px'} alt={'kata_parchi_image_url'} /></td>
                        <td><img onClick={ () => openImageViewer(orders.truck_loading_image_url) } src={orders.truck_loading_image_url} width={'100px'} alt={'truck_loading_image_url'} /></td>
                    </tr>
                        
                }                    
                </tbody>
            </table>
            {isViewerOpen && (
                <ImageViewer
                src={ currentImage }
                currentIndex={0}
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
        </div>
    </div>
    <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
        <div class="modal-body" style={{textAlign: 'left'}}>
            <div className="col-md-12 ">
                <div className="row ">
                    <div className="col">
                        <div className="input-container-pro">
                            <label>Challan Image</label>
                            <input type={'file'} name="challan_image_url" onChange={(e) => onChangeInput(e)} className="input-box form-control"  />
                        </div>  
                    </div>
                    {
                        addData['challan_image_url'] !== '' && 
                        <>
                            <div className="col-md-5 input-container-pro">                            
                                    <img src={addData['challan_image_url']} width={'100px'} alt={'chalan'} />
                                    <button onClick={() => setAddData({...addData,challan_image_url:''})} className="remove-img " >X</button>
                            </div>
                        </>
                    }
                </div>
                <hr />
                <div className="row ">
                    <div className="col">
                        <div className="input-container-pro">
                            <label>Kata Parchi Image</label>
                            <input type={'file'} name="kata_parchi_image_url" onChange={(e) => onChangeInput(e)} className="input-box form-control"  />
                        </div>  
                    </div>
                    {
                        addData['kata_parchi_image_url'] !== '' && 
                        <>
                            <div className="col-md-5 input-container-pro">                            
                                    <img src={addData['kata_parchi_image_url']} width={'100px'} alt={'kata_parchi_image'} />
                                    <button className="remove-img " onClick={() => setAddData({...addData,kata_parchi_image_url:''})} >X</button>
                            </div>
                        </>
                    }
                </div>
                <hr />
                <div className="row ">
                    <div className="col">
                        <div className="input-container-pro">
                            <label>Bilty Image</label>
                            <input type={'file'} name="bilty_image_url" onChange={(e) => onChangeInput(e)} className="input-box form-control"  />
                        </div>  
                    </div>
                    {
                        addData['bilty_image_url'] !== '' && 
                        <>
                            <div className="col-md-5 input-container-pro">                            
                                    <img src={addData['bilty_image_url']} width={'100px'} alt={'bilty_image'} />
                                    <button className="remove-img " onClick={() => setAddData({...addData,bilty_image_url:''})} >X</button>
                            </div>
                        </>
                    }
                </div>
                <hr />
                <div className="row ">
                    <div className="col">
                        <div className="input-container-pro">
                            <label>Truck Loading Image</label>
                            <input type={'file'} name="truck_loading_image_url" onChange={(e) => onChangeInput(e)} className="input-box form-control"  />
                        </div>  
                    </div>
                    {
                        addData['truck_loading_image_url'] !== '' && 
                        <>
                            <div className="col-md-5 input-container-pro">                            
                                    <img src={addData['truck_loading_image_url']} width={'100px'} alt={'truck_loading'} />
                                    <button className="remove-img " onClick={() => setAddData({...addData,truck_loading_image_url:''})} >X</button>
                            </div>
                        </>
                    }
                </div>
                <hr />
                <div className="row ">
                    <div className="col">
                        <div className="input-container-pro">
                            <label>Invoice Image</label>
                            <input type={'file'} name="invoice_image_url" onChange={(e) => onChangeInput(e)} className="input-box form-control"  />
                        </div>  
                    </div>
                    {
                        addData['invoice_image_url'] !== '' && 
                        <>
                            <div className="col-md-5 input-container-pro">                            
                                    <img src={addData['invoice_image_url']} width={'100px'} alt={'invoice_image'} />
                                    <button className="remove-img " onClick={() => setAddData({...addData,invoice_image_url:''})} >X</button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary btn-sm" onClick={updateShipmentDoc} data-dismiss="modal" >Submit</button>
        </div>
        </div>
    </div>
    </div>
    
    <Toaster />
    </div>
  );
}

export default Shipment;
