import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

function DropBox({ handleFileChange, removeImage, images, convertURIToImageData }) {

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [imgShow, setShowImage] = useState([])

    useEffect(() => {
        setShowImage([...images])        
        const imgs = [];
        for(let item of images){
            let img = convertURIToImageData(item);
            imgs.push(img)            
            setSelectedFiles([...imgs])
        } 
    },[images])
    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const upload = (e) => {
        const files = e.target.files
        // e.preventDefault();
        // const files = e.target.file;
        if (files.length) {
            handleFiles(files);
        }
    }

    const handleFiles = (files) => {
        for(let i = 0; i < files.length; i++) {
                handleFileChange(files[i])
                const reader = new FileReader();
                reader.readAsDataURL(files[i]);
                reader.onload = function(e) {
                    setShowImage([...imgShow,e.target.result]);
                }
                // add to an array so we can display the name of file
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
             // set error message
            }
    }

    // const validateFile = (file) => {
    //     const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
    //     if (validTypes.indexOf(file.type) === -1) {
    //         return false;
    //     }
    //     return true;
    // }

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const removeFile = (name) => {
        // find the index of the item
        // remove the item from array
    
        // const validFileIndex = validFiles.findIndex(e => e.name === name);
        // validFiles.splice(validFileIndex, 1);
        // // update validFiles array
        // setValidFiles([...validFiles]);
        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        removeImage(selectedFileIndex);
        selectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array
        setSelectedFiles([...selectedFiles]);
    }

    // useEffect(() => {
    //     console.log(selectedFiles)
    //     let filteredArray = selectedFiles.reduce((file, current) => {
    //         const x = file.find(item => item.name === current.name);
    //         if (!x) {
    //             return file.concat([current]);
    //         } else {
    //             return file;
    //         }
    //     }, []);
    //     setValidFiles([...filteredArray]);
    // }, [selectedFiles]);
  return (
    <div className="container">
        <div className="drop-container" 
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}>
            <div className="drop-message">
                <label>Product image (Optional)</label>
                <input type="file" name="file" onChange={upload} />
                <div className="upload-icon"></div>
                Drag & Drop or Browse
            </div>
        </div>
        <div className="file-display-container">
        {
            selectedFiles.map((data, i) => 
            <div className="file-status-bar" key={i}>
                <div>
                    <div className="file-type-logo">
                        <img style={{width:'100%'}} src={imgShow[i]} />
                    </div>
                    <div className="file-type">{data.name ? fileType(data.name) : 'Image'}</div>
                    <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name ? data.name : 'Product image'}</span>
                    <span className="file-size">({data?.size ? fileSize(data.size) :'NA'})</span> {data.invalid && <span className='file-error-message'>{errorMessage}</span>}
                </div>
                <div className="file-remove"  onClick={() => removeFile(data.name)}>X</div>
            </div>
        )
    }
        </div>
    </div>
  );
}
export default DropBox