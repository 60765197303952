import filter from "./../assets/images/filter.png";
import Header from "./Header";
import "./../assets/css/style.css";
import React, { useEffect, useState } from "react";
import { ApiUrl, local_access } from "../constant";
import moment from "moment";
import { toast, Toaster } from "react-hot-toast";
import Loader from "./loader";
import { createSearchParams, useNavigate } from "react-router-dom";
import bos1 from "../assets/images/bos1.png";
import bos2 from "../assets/images/bos2.png";
import bos3 from "../assets/images/bos3.png";

import Button from "react-bootstrap/Button";

import {
  Autocomplete,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import "./Finance.css";
import handRupee from "../assets/images/hand-rupee.png";
import handSwipe from "../assets/images/hand-swipe.png";
import invoiceIcon from "../assets/images/invoiceIconPrint.png";
import pdfIcon from "../assets/images/pdfIcon.png";
import rupeePlus from "../assets/images/rupeePlus.png";
import rupeePlusRed from "../assets/images/rupeePlusRed.png";
import shopyIcon from "../assets/images/shopyIcon.png";
import createInvoiceIcon from "../assets/images/createInvoiceIcon.png";
import createPurchase from "../assets/images/createPurchase.png";
import faker from "faker";
import dayjs from "dayjs";
import layer from "../assets/images/layer-1.png";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

function Finance() {
  const [orders, setOrder] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const [from, setFrom] = React.useState("");
  const [to, setTo] = React.useState("");
  const [too, setToo] = React.useState("");
  const [Showall, setShowAll] = React.useState("");
  const [vendors, setVendors] = useState("");
  const [customers, setCustomers] = useState("");
  const [btnSelected, setSelected] = useState("to-collect");

  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: "Pulp Fiction", year: 1994 },
    {
      title: "The Lord of the Rings: The Return of the King",
      year: 2003,
    },
    { title: "The Good, the Bad and the Ugly", year: 1966 },
    { title: "Fight Club", year: 1999 },
    {
      title: "The Lord of the Rings: The Fellowship of the Ring",
      year: 2001,
    },
    {
      title: "Star Wars: Episode V - The Empire Strikes Back",
      year: 1980,
    },
    { title: "Forrest Gump", year: 1994 },
    { title: "Inception", year: 2010 },
    {
      title: "The Lord of the Rings: The Two Towers",
      year: 2002,
    },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: "Goodfellas", year: 1990 },
    { title: "The Matrix", year: 1999 },
    { title: "Seven Samurai", year: 1954 },
    {
      title: "Star Wars: Episode IV - A New Hope",
      year: 1977,
    },
    { title: "City of God", year: 2002 },
    { title: "Se7en", year: 1995 },
    { title: "The Silence of the Lambs", year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: "Life Is Beautiful", year: 1997 },
    { title: "The Usual Suspects", year: 1995 },
    { title: "Léon: The Professional", year: 1994 },
    { title: "Spirited Away", year: 2001 },
    { title: "Saving Private Ryan", year: 1998 },
    { title: "Once Upon a Time in the West", year: 1968 },
    { title: "American History X", year: 1998 },
    { title: "Interstellar", year: 2014 },
    { title: "Casablanca", year: 1942 },
    { title: "City Lights", year: 1931 },
    { title: "Psycho", year: 1960 },
    { title: "The Green Mile", year: 1999 },
    { title: "The Intouchables", year: 2011 },
    { title: "Modern Times", year: 1936 },
    { title: "Raiders of the Lost Ark", year: 1981 },
    { title: "Rear Window", year: 1954 },
    { title: "The Pianist", year: 2002 },
    { title: "The Departed", year: 2006 },
    { title: "Terminator 2: Judgment Day", year: 1991 },
    { title: "Back to the Future", year: 1985 },
    { title: "Whiplash", year: 2014 },
    { title: "Gladiator", year: 2000 },
    { title: "Memento", year: 2000 },
    { title: "The Prestige", year: 2006 },
    { title: "The Lion King", year: 1994 },
    { title: "Apocalypse Now", year: 1979 },
    { title: "Alien", year: 1979 },
    { title: "Sunset Boulevard", year: 1950 },
    {
      title:
        "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
      year: 1964,
    },
    { title: "The Great Dictator", year: 1940 },
    { title: "Cinema Paradiso", year: 1988 },
    { title: "The Lives of Others", year: 2006 },
    { title: "Grave of the Fireflies", year: 1988 },
    { title: "Paths of Glory", year: 1957 },
    { title: "Django Unchained", year: 2012 },
    { title: "The Shining", year: 1980 },
    { title: "WALL·E", year: 2008 },
    { title: "American Beauty", year: 1999 },
    { title: "The Dark Knight Rises", year: 2012 },
    { title: "Princess Mononoke", year: 1997 },
    { title: "Aliens", year: 1986 },
    { title: "Oldboy", year: 2003 },
    { title: "Once Upon a Time in America", year: 1984 },
    { title: "Witness for the Prosecution", year: 1957 },
    { title: "Das Boot", year: 1981 },
    { title: "Citizen Kane", year: 1941 },
    { title: "North by Northwest", year: 1959 },
    { title: "Vertigo", year: 1958 },
    {
      title: "Star Wars: Episode VI - Return of the Jedi",
      year: 1983,
    },
    { title: "Reservoir Dogs", year: 1992 },
    { title: "Braveheart", year: 1995 },
    { title: "M", year: 1931 },
    { title: "Requiem for a Dream", year: 2000 },
    { title: "Amélie", year: 2001 },
    { title: "A Clockwork Orange", year: 1971 },
    { title: "Like Stars on Earth", year: 2007 },
    { title: "Taxi Driver", year: 1976 },
    { title: "Lawrence of Arabia", year: 1962 },
    { title: "Double Indemnity", year: 1944 },
    {
      title: "Eternal Sunshine of the Spotless Mind",
      year: 2004,
    },
    { title: "Amadeus", year: 1984 },
    { title: "To Kill a Mockingbird", year: 1962 },
    { title: "Toy Story 3", year: 2010 },
    { title: "Logan", year: 2017 },
    { title: "Full Metal Jacket", year: 1987 },
    { title: "Dangal", year: 2016 },
    { title: "The Sting", year: 1973 },
    { title: "2001: A Space Odyssey", year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: "Toy Story", year: 1995 },
    { title: "Bicycle Thieves", year: 1948 },
    { title: "The Kid", year: 1921 },
    { title: "Inglourious Basterds", year: 2009 },
    { title: "Snatch", year: 2000 },
    { title: "3 Idiots", year: 2009 },
    { title: "Monty Python and the Holy Grail", year: 1975 },
  ];

  const [dateTime, setDateTime] = React.useState(dayjs("2014-08-18T21:11:54"));
  const dateTimePicker = (newValue) => {
    setDateTime(newValue);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    console.log(user);
    setUserInfo(user);
  }, []);

  useEffect(() => {
    getOrders("OPEN");
  }, [userInfo, from, to]);

  const getOrders = (status) => {
    if (userInfo.ID) {
      setLoader(true);
      setStatus(status);
      try {
        const requestOptions = {
          crossDomain: true,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };
        let url = "";
        if (status === "OPEN") {
          url = `/v2/merchant/${userInfo.ID}/orders/received?except_status=CANCELLED,Order%20Delivered&page=${page}&limit=${limit}&text=${search}`;
        } else {
          url = `/v2/merchant/${userInfo.ID}/orders/received?page=${page}&limit=${limit}&order_status=${status}&text=${search}`;
        }
        fetch(`${local_access}${url}`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setLoader(false);
            if (data.code === 200) {
              setOrder(data?.data?.merchat_orders);
              console.log(
                data?.data?.merchat_orders[0].Order.CreatedAt.split("T")[0]
              );
              if (from && to) {
                let temp = data?.data?.merchat_orders.filter(
                  (item) =>
                    item.Order.CreatedAt.split("T")[0] > from &&
                    item.Order.CreatedAt.split("T")[0] < to
                );
                setShowAll(false);
                setOrder(temp);
              }
            }
          });
      } catch {
        setLoader(false);
      }
    }
  };

  const searchData = (e) => {
    setSearch(e.target.value);
    if (search.length > 2) {
      getOrders(status);
    }
  };

  const ShowAll = (e) => {
    setShowAll(e.target.checked);
    if (e.target.checked) {
      setFrom("");
      setTo("");
    }
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      // title: {
      //   display: true,
      //   text: "Finance Graph",
      //   position: "top",
      // fontWeight: "600",
      // },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "To Pay",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 3000 })),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "To Collect",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 3000 })),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const vendorsList = () => {
    const headers = {
      crossDomain: true,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const vendorsUrl =
      "http://erpstage.ayekart.com:9000/v1/user/2/business/2/vendors";
    try {
      fetch(vendorsUrl)
        .then((response) => response.json())
        .then((result) => setVendors(result));
    } catch (e) {
      console.log(e);
    }
  };

  const customersList = () => {
    const headers = {
      crossDomain: true,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const customersUrl =
      "http://erpstage.ayekart.com:9000/v1/user/2/business/2/home";
    try {
      fetch(customersUrl)
        .then((response) => response.json())
        .then((result) => setCustomers(result.data.customers));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    vendorsList();
    customersList();
  }, []);
  console.log(customers);
  return (
    <div>
      <Loader loading={loader} />
      <Header />
      {/* <div className="container-fluid mt-3">
        <div className="custom-breadcrum">
          <span
            className="disable-color"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/home")}
          >
            Home
          </span>
          <span className="disable-color"> Finance</span>
        </div>
        <p
          style={{
            fontSize: "35px",
            color: "#565e7d",
            fontWeight: 600,
            letterSpacing: "1px",
          }}
        >
          Finance
        </p>
        
        <p className="disable-color">
          {orders.length} {status} orders found
        </p>
        <hr></hr>
      </div> */}

      {/* <Divider></Divider> */}

      <div className="container-fluid mb-5">
        <div className="row pl-3">
          <span class="Home-Finance-Records">Home {`>`} Finance Records</span>
        </div>
        <div className="row pl-3">
          <span class="Finance-Records">Finance Records</span>
        </div>
        <div className="row border-bottom mb-2 pb-3 pl-3">
          <span class="-records-found">165 records found</span>
        </div>
        <div className="row text-right">
          <div className="col-10"></div>
          <div className="col-2">
            <div
              className="btn shadow-lg border"
              onClick={() => navigate("/finance/view-reports")}
            >
              View Reports
            </div>
          </div>
        </div>
        <div className="row mx-auto mt-4">
          <div className="col-md-2">
            <div className="rectangle-vertical-rounded float-right">
              <img src={handRupee} height={30} width={30} />
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column pt-3">
            <span class="To-Pay text-left">To Pay</span>
            <span className="text-left">
              10,500.00<span class="INR ml-2">INR</span>
            </span>
            <div class="Rounded-Rectangle-2 mt-2"></div>
          </div>
          <div className="col-md-1">
            <div className="rectangle-vertical-rounded-second float-right">
              <img src={handSwipe} height={30} width={30} />
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column pt-3">
            <span class="To-Pay text-left">To Pay</span>
            <span className="text-left">
              10,500.00<span class="INR ml-2">INR</span>
            </span>
            <div class="Rounded-Rectangle-2 mt-2"></div>
          </div>{" "}
        </div>
        <div className="row text-right">
          <div className="col-10"></div>
          <div className="col-2">
            <div className="btn shadow-lg border">View Invoices</div>
          </div>
        </div>

        <div className="row mx-auto mt-4">
          <div className="col-md-1"></div>
          <div className="col-md-6 justify-content-center">
            <div className="row">
              <span class="Recent-Invoices">Finance Graph</span>
            </div>
            <div
              className="row mt-1"
              style={{ height: "300px", width: "600px" }}
            >
              <Line options={options} data={data} className="float-end" />
            </div>
          </div>
          <div className="col-md-5">
            <div className="row pl-3 mb-2">
              <span class="Recent-Invoices">Recent Invoices</span>
            </div>
            {/* <div className="col-md-5 pt-2">
                {[1, 2, 3, 4].map((item, index) => (
                  <div className="row mb-2">
                    <div className="col-md-2 p-0">
                      <img src={pdfIcon} height={50} width={50} />
                    </div>
                    <div className="col-md-10 d-flex flex-column pt-2">
                      <span class="Invoice135 text-left">Invoice#135</span>
                      <span class="pdf-date text-left">
                        <small>10 Dec 2022 10:00 AM</small>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-4 pt-4 pl-0">
                <div className="card venders-list-card shadow-lg p-2 w-75">
                  <img src={invoiceIcon} />
                  <span class="Create-Invoice">Create Invoice</span>
                </div>
              </div> */}

            <div className="card w-75 float-start card-small ml-0 shadow-lg ">
              <div className="row ml-2 mr-4">
                <div className="col-2 p-3">
                  <img src={bos1} alt="" height={75} width={75} />
                </div>
                <div className="col-6 d-flex align-items-center  justify-content-center">
                  <a>
                    <span
                      class="Create-Invoice"
                      data-toggle="modal"
                      data-target={`#my-modal${"BOS-PO-createInvoice"}`}
                    >
                      Create Invoice
                    </span>
                    {/* --------------------------------Modal For BOS/PO create-invoice----------- */}
                    <div className="container d-flex justify-content-center">
                      <div
                        id={`my-modal${"BOS-PO-createInvoice"}`}
                        className="modal fade"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-top modal-xl mt-5 "
                          role="document"
                        >
                          <div className="modal-content border-0  p-0 ">
                            <div class="modal-header">
                              <h4 style={{ color: "#0062d1" }}>Invoice</h4>
                            </div>
                            <div className="modal-body p-0">
                              <div className="card shadow-lg border-0 justify-content-center">
                                <div className="card-header border-0 ">
                                  <React.Fragment>
                                    <div className="conntainer-fluid">
                                      <div className="row mx-auto print-header-nav mb-3">
                                        <div className="col-4 text-left">
                                          <img
                                            className=""
                                            height={75}
                                            width={150}
                                            src={layer}
                                          />
                                        </div>
                                        <div className="col-5 pt-3">
                                          <p className="float-end TAX-INVOICE">
                                            TAX INVOICE
                                          </p>
                                          <p className="print-header">
                                            AYEKART FINTECH PRIVATE LIMITED
                                          </p>
                                          <p className="print-header1">
                                            702, Shah Trade Center, 7th
                                            Floor,Above SBI Bank, Rani Sati
                                            Road,Near Western Express Highway,
                                            Malad (East), Mumbai - 400 097.
                                          </p>
                                        </div>

                                        <div className="col-3"></div>
                                      </div>
                                    </div>

                                    <div className="row mx-0 mt-3 ">
                                      <div className="col-md-4 border pb-2">
                                        <div className="row d-flex justify-content-center">
                                          <span className="bio-heads">
                                            Invoice To{" "}
                                          </span>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              NAME OF FIRM
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <textarea
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows="1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              Address :
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <textarea
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows="1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              PAN No :
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <textarea
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows="1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              GSTIN :
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <textarea
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows="1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              ContactPerson:
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <input
                                              type="text"
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              Lory No:
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <input
                                              type="text"
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              placeholder="Lory Number"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              Katha parche:
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <input
                                              type="text"
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              placeholder="Katha Parche"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 border pb-2">
                                        <div className="row d-flex justify-content-center">
                                          <span className="bio-heads">
                                            Ship To
                                          </span>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              NAME OF FIRM
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <textarea
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows="1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              Address :
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <textarea
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows="1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              PAN No :
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <textarea
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows="1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              GSTIN :
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <textarea
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows="1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              ContactPerson:
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <input
                                              type="text"
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              Lory No:
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <input
                                              type="text"
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              placeholder="Lory Number"
                                            />
                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-4 p-0 text-left">
                                            <label
                                              className="bio-labels ml-1 mt-1"
                                              for="inputPassword6"
                                            >
                                              Katha parche:
                                            </label>
                                          </div>
                                          <div class="col-8">
                                            <input
                                              type="text"
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              placeholder="Katha Parche"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 border pb-2">
                                        <div className="row d-flex justify-content-center">
                                          <span className="bio-heads">
                                            Terms of Delivery and Payments
                                          </span>
                                        </div>
                                        <div className="row mx-0 mt-1 text-left">
                                          <p className="bio-labels-p">
                                            1. Payment to be made within{" "}
                                            <input className="w-25" /> days of
                                            confirmation of order by Ayekart
                                            and/ or release of advance towards
                                            the supplier against this order
                                          </p>
                                          <p className="bio-labels-p">
                                            2. Any amount due after{" "}
                                            <input className="w-25" /> days will
                                            attract a interest of 3% per month.
                                          </p>
                                          <p className="bio-labels-p">
                                            3. Interest will be calculated on
                                            daily basis including the day of
                                            payment.
                                          </p>
                                          <p className="bio-labels-p">
                                            4. Taxes as applicable will be
                                            charged additional on the interest
                                            due to delay in payments
                                          </p>
                                          <p className="bio-labels-p">
                                            5. Advances, if any, paid by the
                                            buyer will be adjusted in the last
                                            tranche of the order{" "}
                                          </p>
                                        </div>
                                        {/* Invoice To.:	 	Ship To.:	Terms of Delivery and Payments.:	 */}
                                      </div>
                                    </div>

                                    <div className="row mx-0 ">
                                      <div className="col-md-4 border text-left d-flex pl-2 pt-2 pb-1">
                                        {" "}
                                        <span className="bio-labels mr-3">
                                          Invoice Date:
                                        </span>{" "}
                                        <input
                                          className="form-control w-50 ml-4"
                                          type="date"
                                        />
                                      </div>
                                      <div className="col-md-4 border text-left d-flex pl-2 pt-2 pb-1">
                                        {" "}
                                        <span className="bio-labels mr-1">
                                          Invoice No:{" "}
                                        </span>
                                        <input className="form-control w-50 ml-5" />
                                      </div>
                                      <div className="col-md-4 border text-left d-flex pl-2 pt-2 pb-1">
                                        {" "}
                                        <span className="bio-labels">
                                          Other References:{" "}
                                        </span>
                                        <input className="form-control w-50 ml-3" />
                                      </div>
                                    </div>

                                    <div className="row mx-0 mt-2 goods-row">
                                      <div className="col-md-7 goods-headers ">
                                        <div className="row text-left">
                                          <div className="col-md-1 pl-0  text-center border">
                                            <span>Sr. No.</span>
                                          </div>
                                          <div className="col-md-2 pl-0  border">
                                            <span>Description of Goods</span>
                                          </div>
                                          <div className="col-md-2 pl-0 border text-center">
                                            <span>HSN Code </span>
                                          </div>
                                          <div className="col-md-2 pl-0 border">
                                            <span>Qty(Kgs/Ltrs/Pcs) </span>
                                          </div>
                                          <div className="col-md-2 pl-0 border">
                                            <span>
                                              Rate per(in Kgs / Ltrs / Pcs)
                                            </span>
                                          </div>
                                          <div className="col-md-1 pl-0 border d-flex">
                                            <span>Amount</span>
                                          </div>
                                          <div className="col-md-1 pl-0 border d-flex">
                                            <span>Discount</span>
                                          </div>
                                          <div className="col-md-1 pl-0 border d-flex">
                                            <span>Taxable</span>
                                          </div>
                                        </div>
                                        <div className="row text-left">
                                          <div className="col-md-1 pl-0 text-center border">
                                            <span>1.</span>
                                          </div>
                                          <div className="col-md-2 pl-0  border">
                                            <span>
                                              Description of Goods here...
                                            </span>
                                          </div>
                                          <div className="col-md-2 pl-0 text-center border">
                                            <span>123456 </span>
                                          </div>
                                          <div className="col-md-2 pl-0 border text-center">
                                            <span>5 kg </span>
                                          </div>
                                          <div className="col-md-2 pl-0 border text-center">
                                            <span>15</span>
                                          </div>
                                          <div className="col-md-1 pl-0 border text-center">
                                            <span>200</span>
                                          </div>
                                          <div className="col-md-1 pl-0 border text-center">
                                            <span>15%</span>
                                          </div>
                                          <div className="col-md-1 pl-0 border text-center">
                                            <span>33</span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-5 goods-headers">
                                        <div className="row text-center">
                                          <div className="col-md-3 pl-0 border ">
                                            <span>IGST</span>
                                          </div>
                                          <div className="col-md-3 pl-0 border">
                                            <span>CGST</span>
                                          </div>
                                          <div className="col-md-3 pl-0 border">
                                            <span>SGST</span>
                                          </div>
                                          <div className="col-md-2 pl-0 border">
                                            <span>CESS</span>
                                          </div>
                                          <div className="col-md-1 pl-0 border d-flex">
                                            <span>Total</span>
                                          </div>
                                        </div>

                                        <div className="row text-left rates">
                                          <div className="col-md-3 pl-0  border">
                                            <div className="row ml-0 ">
                                              <div className="col-md-7 text-center pl-0 border">
                                                <span>RATE</span>
                                              </div>
                                              <div className="col-md-5 pl-0 border">
                                                <span>AMOUNT</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-3 pl-0 border">
                                            <div className="row ml-0 ">
                                              <div className="col-md-7 text-center pl-0 border">
                                                <span>RATE</span>
                                              </div>
                                              <div className="col-md-5 pl-0  border">
                                                <span>AMOUNT</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-3 pl-0 border">
                                            <div className="row ml-0 ">
                                              <div className="col-md-7 text-center pl-0 border">
                                                <span>RATE</span>
                                              </div>
                                              <div className="col-md-5 pl-0  border">
                                                <span>AMOUNT</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-2 pl-0 border">
                                            <div className="row ml-0 ">
                                              <div className="col-md-5 pl-0 text-center d-flex border">
                                                RATE
                                              </div>
                                              <div className="col-md-7 pl-0 text-center d-flex border">
                                                <span>AMOUNT</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-1 pl-0 border border-top-0 border-bottom-0">
                                           </div>
                                        </div>
                                        <div className="row text-left rates">
                                          <div className="col-md-3 pl-0  border">
                                            <div className="row ml-0 " style={{height:'30px'}}>
                                              <div className="col-md-7 text-center pl-0 amount-spacing border border-top-0">
                                                <span>55</span>
                                              </div>
                                              <div className="col-md-5 pl-0 text-center amount-spacing border border-top-0">
                                                <span>70</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-3 pl-0 border">
                                            <div className="row ml-0 " style={{height:'30px'}}>
                                              <div className="col-md-7 text-center pl-0 border border-top-0">
                                                <span>22</span>
                                              </div>
                                              <div className="col-md-5 pl-0 text-center border amount-spacing border-top-0">
                                                <span>400</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-3 pl-0 border">
                                            <div className="row ml-0 " style={{height:'30px'}}>
                                              <div className="col-md-7 pl-0 text-center border border-top-0">
                                                <span>66</span>
                                              </div>
                                              <div className="col-md-5 pl-0 text-center amount-spacing border border-top-0">
                                                <span>100</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-2 pl-0 border">
                                            <div className="row ml-0 " style={{height:'30px'}}>
                                              <div className="col-md-5 pl-0 text-center border">
                                                33
                                              </div>
                                              <div className="col-md-7 pl-0 text-center amount-spacing border">
                                                <span>200</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-1 pl-0 text-center amount-spacing border border-top-0">
                                            <span>₹500</span>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                    <div className="row mx-auto d-flex justify-content-center"></div>
                                  </React.Fragment>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                class="btn btn-add-vendor btn-dark"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <div className="btn btn-add-vendor">
                                SAVE Changes
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------*/}
                  </a>
                </div>
                <div className="col-4 w-75 d-flex justify-content-center align-items-center">
                  <div className="card venders-list-card-small shadow-lg  w-75 d-flex justify-content-center ">
                    <span class="Create-Invoice">View</span>
                    <span class="text-style-number">(114)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card w-75 mt-3 float-start card-small ml-0 shadow-lg ">
              <div className="row ml-2 mr-4">
                <div className="col-2 p-3">
                  <img src={bos2} alt="" height={75} width={75} />
                </div>
                <div className="col-6 d-flex align-items-center  justify-content-center">
                  <a>
                    <span
                      class="Create-Invoice"
                      data-toggle="modal"
                      data-target={`#my-modal${"createInvoice-PO-BOS"}`}
                    >
                      Create BOS
                    </span>
                    {/* --------------------------------Modal For create-invoice/PO/BOS----------- */}
                    <div className="container d-flex justify-content-center">
                      <div
                        id={`my-modal${"createInvoice-PO-BOS"}`}
                        className="modal fade"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-top modal-xl mt-5"
                          role="document"
                        >
                          <div className="modal-content border-0  p-0 ">
                            <div className="modal-content border-0  p-0 ">
                              <div class="modal-header">
                                <h4 style={{ color: "#0062d1" }}>BOS</h4>
                              </div>
                              <div className="modal-body p-0">
                                <div className="card shadow-lg border-0 pt-2 pb-2 justify-content-center">
                                  <div className="card-header pb-0 border-0 ">
                                    <div className="row mt-0 mb-4 pr-0 mx-auto d-flex justify-content-center"></div>
                                    <React.Fragment>
                                      <div className="row mx-4 mt-3"></div>
                                      <div className="row mx-auto d-flex justify-content-center">
                                        <div className="btn btn-add-vendor">
                                          SAVE
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-body p-0 ">
                              <div className="card shadow-lg  border-0 pt-2 pb-2 justify-content-center">
                                sljdsjl
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* -------------------------------------------------------------- */}
                  </a>
                </div>
                <div className="col-4 w-75 d-flex justify-content-center align-items-center">
                  <div className="card venders-list-card-small shadow-lg  w-75 d-flex justify-content-center ">
                    <span class="Create-Invoice">View</span>
                    <span class="text-style-number">(114)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card w-75 mt-3 float-start card-small ml-0 shadow-lg ">
              <div className="row ml-2 mr-4">
                <div className="col-2 p-3">
                  <img src={bos3} alt="" height={75} width={75} />
                </div>
                <div className="col-6 d-flex align-items-center  justify-content-center">
                  <a>
                    <span
                      class="Create-Invoice"
                      data-toggle="modal"
                      data-target={`#my-modal${"createInvoice-BOS-PO"}`}
                    >
                      Create PO
                    </span>
                    {/* --------------------------------Modal For create-invoice/BOS/PO----------- */}
                    <div className="container d-flex justify-content-center">
                      <div
                        id={`my-modal${"createInvoice-BOS-PO"}`}
                        className="modal fade"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-top modal-xl mt-5"
                          role="document"
                        >
                          <div className="modal-content border-0  p-0 ">
                            <div className="modal-body p-0 ">
                              <div className="card shadow-lg add-entry-card border-0 pt-2 pb-2 justify-content-center">
                                <div className="card-header pb-0 border-0 ">
                                  <div className="row mt-0 mb-4 pr-0 mx-auto d-flex justify-content-center">
                                    <h4 style={{ color: "#0062d1" }}>
                                      Create PO
                                    </h4>
                                  </div>
                                  <React.Fragment>
                                    <div className="row mx-4 mt-3">
                                      <TextField
                                        style={{
                                          boxShadow:
                                            "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                        }}
                                        label="First Name"
                                        placeholder="Enter First Name here"
                                        id="outlined-start-adornment"
                                        fullWidth
                                      />
                                    </div>

                                    <div className="row mx-4 mt-3">
                                      <TextField
                                        style={{
                                          boxShadow:
                                            "0 0 65px 8.2px rgba(30, 64, 100, 0.19)",
                                        }}
                                        label="Last Name"
                                        placeholder="Enter Last Name here"
                                        id="outlined-start-adornment"
                                        fullWidth
                                      />
                                    </div>
                                    <div className="row mx-4 mt-3">
                                      <TextField
                                        // sx={{ boxShadow: 5 }}
                                        style={{
                                          boxShadow:
                                            "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                        }}
                                        label="Mobile"
                                        placeholder="Enter Mobile Number here"
                                        id="outlined-start-adornment"
                                        fullWidth
                                      />
                                    </div>
                                    <div className="row mx-auto d-flex justify-content-center">
                                      <div className="btn btn-add-vendor">
                                        SAVE
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* -------------------------------------------------------------- */}
                  </a>
                </div>
                <div className="col-4 w-75 d-flex justify-content-center align-items-center">
                  <div className="card venders-list-card-small shadow-lg  w-75 d-flex justify-content-center ">
                    <span class="Create-Invoice">View</span>
                    <span class="text-style-number">(114)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 ">
          <div className="col-md-6">
            <div className="card shadow-lg venders-list-card  pt-2 pb-2">
              <div className="row">
                <div className="col-md-8">
                  <span class="Vendors-list">Vendors list</span>
                </div>
                <div className="col-md-4">
                  <span
                    class="Add-Vendor"
                    data-toggle="modal"
                    data-target={`#my-modal${"AddVendor"}`}
                  >
                    +Add Vendor
                  </span>
                  {/* --------------------------------Modal For Add vendor----------- */}
                  <div className="container d-flex justify-content-center">
                    <div
                      id={`my-modal${"AddVendor"}`}
                      className="modal fade"
                      tabIndex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-top mt-5"
                        role="document"
                      >
                        <div className="modal-content border-0  p-0 ">
                          <div className="modal-body p-0 ">
                            <div className="card shadow-lg add-entry-card border-0 pt-2 pb-2 justify-content-center">
                              <div className="card-header pb-0 border-0 ">
                                <div className="row mt-0 mb-4 pr-0 mx-auto d-flex justify-content-center">
                                  <h4 style={{ color: "#0062d1" }}>
                                    + Add Vendor
                                  </h4>
                                </div>
                                <React.Fragment>
                                  <div className="row mx-4 mt-3">
                                    <TextField
                                      // sx={{ boxShadow: 5 }}
                                      style={{
                                        boxShadow:
                                          "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                      }}
                                      label="First Name"
                                      placeholder="Enter First Name here"
                                      id="outlined-start-adornment"
                                      fullWidth
                                    />
                                  </div>

                                  <div className="row mx-4 mt-3">
                                    <TextField
                                      style={{
                                        boxShadow:
                                          "0 0 65px 8.2px rgba(30, 64, 100, 0.19)",
                                      }}
                                      label="Last Name"
                                      placeholder="Enter Last Name here"
                                      id="outlined-start-adornment"
                                      fullWidth
                                    />
                                  </div>
                                  <div className="row mx-4 mt-3">
                                    <TextField
                                      // sx={{ boxShadow: 5 }}
                                      style={{
                                        boxShadow:
                                          "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                      }}
                                      label="Mobile"
                                      placeholder="Enter Mobile Number here"
                                      id="outlined-start-adornment"
                                      fullWidth
                                    />
                                  </div>
                                  <div className="row mx-auto d-flex justify-content-center">
                                    <div className="btn btn-add-vendor">
                                      SAVE
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------------------------------------------------------------- */}
                </div>
              </div>
              <div className="row mx-3 mb-2">
                <input
                  className="form-control "
                  type=""
                  name=""
                  value=""
                  placeholder="Search Vendors"
                />
              </div>
              <div style={{ overflowY: "scroll", height: "300px" }}>
                {vendors.data?.map((item, index) => (
                  <div className="card mb-2 mx-3 shadow-lg pt-2 pb-2">
                    <div className="row mx-1">
                      <div className="col-md-4 text-left d-flex">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                          class="rounded-circle"
                          alt="Avatar"
                          height={40}
                          width={40}
                        />
                        <span class="name ml-2">{`${item.first_name} ${item.last_name}`}</span>
                      </div>
                      <div className="col-md-2 d-flex flex-column text-left">
                        <span class="names">To Pay</span>
                        <span className="png-icons-text">₹ {item.to_pay}</span>
                      </div>
                      <div className="col-md-2 d-flex flex-column text-left">
                        <span class="names">Paid</span>
                        <span className="png-icons-text">₹ {item.paid}</span>
                      </div>
                      <div className="col-md-2 d-flex flex-column text-left">
                        <img
                          className="png-icons mx-3 pointer"
                          src={rupeePlus}
                          alt=""
                          data-toggle="modal"
                          data-target={`#my-modal${index}`}
                        />
                        <span
                          className="png-icons-text pointer"
                          data-toggle="modal"
                          data-target={`#my-modal${index}`}
                        >
                          Add Entry
                        </span>
                        {/* --------------------------------Modal For Add Entry vendors----------- */}
                        <div className="container d-flex justify-content-center">
                          <div
                            id={`my-modal${index}`}
                            className="modal fade"
                            tabIndex="-1"
                            role="dialog"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-top mt-5"
                              role="document"
                            >
                              <div className="modal-content border-0  p-0 ">
                                <div className="modal-body p-0 ">
                                  <div className="card shadow-lg add-entry-card border-0 pt-2 pb-2 justify-content-center">
                                    <div className="card-header pb-0 border-0 ">
                                      <div className="row mx-4 mt-0 mb-4 pr-0 btn-collected-border">
                                        <div
                                          className={`btn  ${
                                            btnSelected === "to-collect"
                                              ? "btn-collected-selected text-light"
                                              : "btn-collected"
                                          }`}
                                          onClick={() =>
                                            setSelected("to-collect")
                                          }
                                        >
                                          Collect Now
                                        </div>
                                        <div
                                          className={`btn  ${
                                            btnSelected === "collected"
                                              ? "btn-collected-selected text-light"
                                              : "btn-collected"
                                          }`}
                                          onClick={() =>
                                            setSelected("collected")
                                          }
                                        >
                                          Collected
                                        </div>
                                      </div>
                                      {/* -------------------------- */}
                                      {btnSelected === "to-collect" ? (
                                        <React.Fragment>
                                          <div className="row mx-4 mt-3">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <Stack>
                                                <DateTimePicker
                                                  label="Date&Time"
                                                  value={dateTime}
                                                  onChange={dateTimePicker}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      className="textfields"
                                                      {...params}
                                                      sx={{
                                                        width: "410px",
                                                        border: 0,
                                                        "&:hover": {},
                                                      }}
                                                      style={{
                                                        boxShadow:
                                                          "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </Stack>
                                            </LocalizationProvider>
                                          </div>
                                          <div className="row mx-4 mt-3">
                                            <TextField
                                              // sx={{ boxShadow: 5 }}
                                              style={{
                                                boxShadow:
                                                  "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                              }}
                                              label="Amount"
                                              placeholder="Enter Amount"
                                              type={"number"}
                                              id="outlined-start-adornment"
                                              fullWidth
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    ₹
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          </div>
                                          <div className="row  mx-4 mt-3">
                                            <Autocomplete
                                              // sx={{ boxShadow: 5 }}
                                              style={{
                                                boxShadow:
                                                  "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                              }}
                                              fullWidth
                                              id="free-solo-demo"
                                              freeSolo
                                              options={top100Films.map(
                                                (option) => option.title
                                              )}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Type about item details"
                                                />
                                              )}
                                            />
                                          </div>
                                          <div className="row mx-4 mt-3">
                                            <TextField
                                              // sx={{ boxShadow: 5 }}
                                              style={{
                                                boxShadow:
                                                  "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                              }}
                                              id="outlined-basic"
                                              placeholder="Enter Quantity"
                                              fullWidth
                                              label="Enter Quantity"
                                              variant="outlined"
                                              type="number"
                                            />
                                          </div>
                                          <div className="row mx-auto mt-3 mb-3  pl-4">
                                            <input
                                              multiple
                                              type="file"
                                              className="form-control border-0 shadow-lg w-75 pl-0"
                                              style={{
                                                boxShadow:
                                                  "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                              }}
                                            />
                                          </div>
                                          <div className="row mx-auto d-flex justify-content-center">
                                            <div className="btn btn-save">
                                              Save
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <div className="row mx-4 mt-3">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <Stack>
                                                <DateTimePicker
                                                  label="Date&Time"
                                                  value={dateTime}
                                                  onChange={dateTimePicker}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      className="textfields"
                                                      {...params}
                                                      sx={{
                                                        width: "410px",
                                                        // boxShadow: 5,
                                                        border: 0,
                                                        "&:hover": {},
                                                      }}
                                                      style={{
                                                        boxShadow:
                                                          "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </Stack>
                                            </LocalizationProvider>
                                          </div>
                                          <div className="row mx-4 mt-3">
                                            <TextField
                                              // sx={{ boxShadow: 5 }}
                                              style={{
                                                boxShadow:
                                                  "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                              }}
                                              label="Amount"
                                              placeholder="Enter Amount"
                                              type={"number"}
                                              id="outlined-start-adornment"
                                              fullWidth
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    ₹
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          </div>
                                          <div className="row mx-auto mt-5 mb-2 d-flex justify-content-center">
                                            <div className="btn  btn-save">
                                              Save
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      )}

                                      {/* ------------------- */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* -------------------------------------------------------------- */}
                      </div>
                      <div className="col-md-2 d-flex flex-column text-left">
                        <img
                          className="png-icons mx-auto float-center pointer"
                          src={createPurchase}
                          alt=""
                          data-toggle="modal"
                          data-target={`#my-modal${index}`}
                        />
                        <span
                          className="png-icons-text pointer"
                          data-toggle="modal"
                          data-target={`#my-modal${index}`}
                        >
                          Create Purchse
                        </span>
                        {/* --------------------------------Modal For Create Purchase----------- */}
                        <div className="container d-flex justify-content-center">
                          <div
                            id={`my-modal${index}`}
                            className="modal fade"
                            tabIndex="-1"
                            role="dialog"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content border-0">
                                <div className="modal-body p-0">
                                  <div className="card border-0 pt-2 pb-2 justify-content-center">
                                    <div className="card-header pb-0 bg-white border-0 ">
                                      <div className="row  mx-auto text-center">
                                        <h6 className="font-weight-bold mb-2 ">
                                          Are you sure, you want to Accept this
                                          Order
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="card-body px-sm-4 mb-2 pt-1 pb-0">
                                      <div className="row justify-content-center mt-3 no-gutters">
                                        <div className="col-auto">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            data-dismiss="modal"
                                            // onClick={() => acceptOrder()}
                                          >
                                            Yes
                                          </button>
                                        </div>
                                        <div className="col-auto ps-2">
                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-sm"
                                            data-dismiss="modal"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* -------------------------------------------------------------- */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card shadow-lg venders-list-card  pt-2 pb-2">
              <div className="row">
                <div className="col-md-8">
                  <span class="Customers-list">Customers list</span>
                </div>
                <div className="col-md-4">
                  <span
                    class="Add-Customer Add-Vendor"
                    data-toggle="modal"
                    data-target={`#my-modal${"AddCustomer"}`}
                  >
                    +Add Customer
                  </span>{" "}
                  {/* --------------------------------Modal For Add Customer----------- */}
                  <div className="container d-flex justify-content-center">
                    <div
                      id={`my-modal${"AddCustomer"}`}
                      className="modal fade"
                      tabIndex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-top mt-5"
                        role="document"
                      >
                        <div className="modal-content border-0  p-0 ">
                          <div className="modal-body p-0 ">
                            <div className="card shadow-lg add-entry-card border-0 pt-2 pb-2 justify-content-center">
                              <div className="card-header pb-0 border-0 ">
                                <div className="row mt-0 mb-4 pr-0 mx-auto d-flex justify-content-center">
                                  <h4 style={{ color: "#0062d1" }}>
                                    + Add Customer
                                  </h4>
                                </div>
                                <React.Fragment>
                                  <div className="row mx-4 mt-3">
                                    <TextField
                                      // sx={{ boxShadow: 5 }}
                                      style={{
                                        boxShadow:
                                          "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                      }}
                                      label="First Name"
                                      placeholder="Enter First Name here"
                                      id="outlined-start-adornment"
                                      fullWidth
                                    />
                                  </div>
                                  <div className="row mx-4 mt-3">
                                    <TextField
                                      style={{
                                        boxShadow:
                                          "0 0 65px 8.2px rgba(30, 64, 100, 0.19)",
                                      }}
                                      label="Last Name"
                                      placeholder="Enter Last Name here"
                                      id="outlined-start-adornment"
                                      fullWidth
                                    />
                                  </div>
                                  <div className="row mx-4 mt-3">
                                    <TextField
                                      style={{
                                        boxShadow:
                                          "0 0 58.8px 11.2px rgba(30, 64, 155, 0.19)",
                                      }}
                                      label="Mobile"
                                      placeholder="Enter Mobile Number here"
                                      id="outlined-start-adornment"
                                      fullWidth
                                    />
                                  </div>
                                  <div className="row mx-auto d-flex justify-content-center">
                                    <div className="btn btn-add-Customer">
                                      SAVE
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------------------------------------------------------------- */}
                </div>
              </div>
              <div className="row mx-3 mb-2">
                <input
                  className="form-control "
                  type=""
                  name=""
                  value=""
                  placeholder="Search Vendors"
                />
              </div>

              <div style={{ overflowY: "scroll", height: "300px" }}>
                {customers
                  ? customers.map((item, index) => (
                      <div className="card mb-2 mx-3 shadow-lg pt-2 pb-2">
                        <div className="row mx-1">
                          <div className="col-md-3 text-left d-flex">
                            <img
                              src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                              class="rounded-circle"
                              alt="Avatar"
                              height={40}
                              width={40}
                            />
                            <span class="name ml-2">
                              {item?.first_name} {item?.last_name}
                            </span>
                          </div>
                          <div className="col-md-2 d-flex flex-column text-left">
                            <span class="names">
                              <span class="To-Collect">To Collect</span>
                            </span>
                            <span className="png-icons-text ">
                              ₹ {item?.to_collect}
                            </span>
                          </div>
                          <div className="col-md-2 d-flex flex-column text-left ">
                            <span class="names text-left">Collected</span>
                            <small className="png-icons-text">
                              ₹ {item?.collected}
                            </small>
                          </div>
                          <div className="col-md-1 pl-0 d-flex flex-column text-left">
                            <img
                              className="png-icons mx-2 pointer"
                              src={rupeePlusRed}
                              alt=""
                              data-toggle="modal"
                              data-target={`#my-modal${index}`}
                            />
                            <div className="row text-center d-flex justify-content-center">
                              <span
                                className="png-icons-text-Add pointer"
                                data-toggle="modal"
                                data-target={`#my-modal${index}`}
                              >
                                Add Entry
                              </span>
                            </div>
                            {/* --------------------------------Modal For Add Entry----------- */}
                            <div className="container d-flex justify-content-center">
                              <div
                                id={`my-modal${index}`}
                                className="modal fade"
                                tabIndex="-1"
                                role="dialog"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content border-0">
                                    <div className="modal-body p-0">
                                      <div className="card border-0 pt-2 pb-2 justify-content-center">
                                        <div className="card-header pb-0 bg-white border-0 ">
                                          <div className="row  mx-auto text-center">
                                            <h6 className="font-weight-bold mb-2 ">
                                              Are you sure, you want to Accept
                                              this Order
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="card-body px-sm-4 mb-2 pt-1 pb-0">
                                          <div className="row justify-content-center mt-3 no-gutters">
                                            <div className="col-auto">
                                              <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                data-dismiss="modal"
                                                // onClick={() => acceptOrder()}
                                              >
                                                Yes
                                              </button>
                                            </div>
                                            <div className="col-auto ps-2">
                                              <button
                                                type="button"
                                                className="btn btn-secondary btn-sm"
                                                data-dismiss="modal"
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* -------------------------------------------------------------- */}
                          </div>
                          <div className="col-md-2 d-flex flex-column">
                            <img
                              className="png-icons mx-auto pointer"
                              src={shopyIcon}
                              alt=""
                              data-toggle="modal"
                              data-target={`#my-modal${index}`}
                            />
                            <span
                              class="png-icons-text pointer"
                              data-toggle="modal"
                              data-target={`#my-modal${index}`}
                            >
                              Create Sale
                            </span>
                            {/* --------------------------------Modal For Create Sale----------- */}
                            <div className="container d-flex justify-content-center">
                              <div
                                id={`my-modal${index}`}
                                className="modal fade"
                                tabIndex="-1"
                                role="dialog"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content border-0">
                                    <div className="modal-body p-0">
                                      <div className="card border-0 pt-2 pb-2 justify-content-center">
                                        <div className="card-header pb-0 bg-white border-0 ">
                                          <div className="row  mx-auto text-center">
                                            <h6 className="font-weight-bold mb-2 ">
                                              Are you sure, you want to Accept
                                              this Order
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="card-body px-sm-4 mb-2 pt-1 pb-0">
                                          <div className="row justify-content-center mt-3 no-gutters">
                                            <div className="col-auto">
                                              <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                data-dismiss="modal"
                                                // onClick={() => acceptOrder()}
                                              >
                                                Yes
                                              </button>
                                            </div>
                                            <div className="col-auto ps-2">
                                              <button
                                                type="button"
                                                className="btn btn-secondary btn-sm"
                                                data-dismiss="modal"
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* -------------------------------------------------------------- */}
                          </div>
                          <div className="col-md-2 d-flex flex-column">
                            <img
                              className="png-icons mx-auto pointer"
                              src={createInvoiceIcon}
                              alt=""
                            />
                            <span class="png-icons-text pointer">
                              Create Invoice
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid mt-3">
        <div className="tab-panel-ui container">
          <div className="row">
            <div className="col col-md-lg-7">
              <div className="panel with-nav-tabs panel-default">
                <div className="panel-heading">
                  <ul className="nav nav-tabs">
                    <li className={status === "OPEN" ? "active" : ""}>
                      <a
                        href="#tab2default"
                        data-toggle="tab"
                        onClick={() => getOrders("OPEN")}
                      >
                        Open
                      </a>
                    </li>
                    <li className={status === "DELIVERED" ? "active" : ""}>
                      <a
                        href="#tab3default"
                        data-toggle="tab"
                        onClick={() => getOrders("DELIVERED")}
                      >
                        Delivered{" "}
                      </a>
                    </li>
                    <li className={status === "CANCELLED" ? "active" : ""}>
                      <a
                        href="#tab4default"
                        data-toggle="tab"
                        onClick={() => getOrders("CANCELLED")}
                      >
                        Cancelled{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="panel-body">
                  <div className="tab-content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-input-div">
          <input
            className="search-input"
            type="text"
            onKeyDown={(e) => e.code === "Enter" && getOrders(status)}
            onChange={(e) => searchData(e)}
            value={search}
            placeholder="Search by order id, Business Name, Address"
          />
        </div>
        <div className="table-responsive">
          <table className="zui-table zui-table-rounded">
            <thead>
              <tr>
                <th>ID</th>
                <th>Create Invoice</th>
                <th>Business Name</th>
                <th>Address</th>
                <th>Date & Time</th>
                <th>Order Total</th>
                <th className="text-right">Status</th>
              </tr>
            </thead>
            <tbody>
              {orders &&
                orders.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className="order-id">#{item.Order?.ID} </span>{" "}
                        <a
                          className="product-list"
                          data-toggle="modal"
                          data-target="#exampleModal"
                          onClick={() => setProducts(item.Order?.OrderItems)}
                          href={() => false}
                        >
                          View Product List
                        </a>
                      </td>
                      <td>
                        <a
                          className="product-list"
                          data-toggle="modal"
                          onClick={() =>
                            navigate({
                              pathname: "/Invoice",
                              search: `${createSearchParams({
                                id: item.Order?.ID,
                              })}`,
                            })
                          }
                          href={() => false}
                        >
                          Create Invoice
                        </a>
                      </td>

                      <td>{item.UserDetails?.Shop?.name}</td>
                      <td>
                        {item.UserDetails?.address},{item.UserDetails?.state},
                        {item.UserDetails?.pin_code}
                      </td>
                      <td>
                        {moment(item.Order?.ordered_date).format("DD.MM.YYYY")}
                      </td>
                      <td>Rs.{item.Order?.total_amount} </td>
                      <td className="text-right">
                        <span className="text-primary">
                          {item.Order?.order_status}
                        </span>
                        <div className="dropdown dropdown-action action-option">
                          <a
                            href={() => false}
                            className="action-icon dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa fa-ellipsis-v"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-down">
                            <a className="dropdown-item" href={() => false}>
                              View/Upload Shipment Docs
                            </a>
                            <a className="dropdown-item" href={() => false}>
                              View/Add Tranches
                            </a>
                            <a className="dropdown-item" href={() => false}>
                              Update Order Status
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                }
                )                
            }
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Products Detail
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ textAlign: "left" }}>
              <table className="table-responsive" style={{ display: "table" }}>
                <tbody>
                  {products ? (
                    products.map((item) => {
                      return (
                        <>
                          <tr>
                            <th>Product name</th>
                            <td>{item.product?.product_name}</td>
                          </tr>
                          {item.product?.product_images && (
                            <tr>
                              <th>Image</th>
                              <td>
                                <img
                                  width="100"
                                  src={item.product?.product_images[0]}
                                  alt=""
                                />
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>Selling price</th>
                            <td>
                              &#8377; {item.product?.selling_price} /{" "}
                              {item.product?.measurement_unit}
                            </td>
                          </tr>
                          <tr>
                            <th>Stock</th>
                            <td>{item.product?.stock}</td>
                          </tr>
                          <tr>
                            <th>Quantity</th>
                            <td>
                              {item.quantity} {item.product?.measurement_unit}
                            </td>
                          </tr>
                          <hr />
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={2}>No Prouducts</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <Toaster />
    </div>
  );
}

export default Finance;
