import moment from "moment";
import React, { useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { FileUploader } from "react-drag-drop-files";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiUrl, ApiUrl1, ApiUrl2, local_access } from "../constant";
import aws from "aws-sdk";
import Header from "./Header";
import Loader from "./loader"
function SellersRequest(){
    const navigate = useNavigate();
    const location = useLocation();

    
    const [loader, setLoader] = useState(false);
    const [userInfo, setUserInfo] = React.useState({});
    const [demand, setDemands] = React.useState([]);
    const [status, setStatus] = React.useState('Open');
    const [selectedDemand, setSelDemand] = React.useState({});
    const [assignedItem, setAssigneditem] = React.useState({});
    const [productData, addProductData] = React.useState({
        "proposed_price": '',
        "quality": "",
        "source_location": '',
        "product_images": [],
        "product_name": "",
        "user_id": null,
        "min_order_qty":'',
      })


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userInfo'))  
        setUserInfo(user)                      
    },[])

    useEffect(() => {
        getDemands(status)
    },[userInfo])


    const getDemands = (stat) => {
        if(userInfo.ID){
            setLoader(true)
            const requestOptions = {
                crossDomain:true,
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin':'*', }
            };
            let url = `/b2b/ayekart/api/demand/seller/?seller_id=${userInfo.ID}&seller_status=${stat}`;
            
             fetch(`${url}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLoader(false)
                if(data.message === 'done'){
                    setDemands(data?.Data.reverse())
                }
            });
                
        }
    }

    const changeStatus = (item) => {
        setLoader(true)
        const data = {
            "seller_id": selectedDemand.user_id,
            "demand_id": selectedDemand.ID,
            "buyer_id": userInfo.ID        
        }
        const requestOptions = {
            crossDomain:true,
            method: 'PATCH',     
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/b2b/ayekart/api/demand/buyer/accept`;
        
        fetch(`${url}`, requestOptions)
        .then(response => 
            {
            if(response.status === 404){
                setLoader(false)
                toast.error('something went wrong')
            }else if(response.status === 200){
                setLoader(false)
                console.log(data)
                addToCard(item)
            
            }
           
        });
    }

    const addToCard = (item) => {
        setLoader(true)
        const data = {
            "cart_items": [],
            "user_id": userInfo.ID
          }
        for(let val of item.DemandProducts){
            data.cart_items.push({
                "color_size_variant": "",
                "measurement_unit": val.measurement_unit,
                "pack_sizes": `${val.pack_size}`,
                "product_cities": val.Shop.city,
                "product_id": val.ID,
                "quantity": parseInt(item.product_quantity),
                "shipping_status": item.status
              }
            )
        }
          
        const requestOptions = {
            crossDomain:true,
            method: 'POST',     
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/${userInfo.ID}/cart`;
        
        fetch(`${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.code === 201){
            createOrder(data.data)
            }
            });
    }

    const createOrder = (value) => {
        setLoader(true)
        const data = {
            "cart_id": value.ID,
            "user_id": userInfo.ID,
            "shipping_address": selectedDemand.shipping_address,
            "billing_address": selectedDemand.shipping_address,
            "payment_method": selectedDemand.is_supply_chain_finance_require ? "Supply Chain Finance":'',
            "payment_status": selectedDemand.is_supply_chain_finance_require ? "Supply Chain Finance/Pending" : "pending",
            "delivery_method": "Courier",
            "discount": 0,
            "order_status": "Pending",
            "shipping_status": "Pending",
            "total_amount": 12000.0,
            "reward_amount": 0,
            "seller_mobile": userInfo.mobile_number,
            "is_demand_order": true,
            
          }
          
          
        const requestOptions = {
            crossDomain:true,
            method: 'POST',     
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/${userInfo.ID}/cart/171/order`;
        
        fetch(`${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.code === 201){
                if(selectedDemand.is_supply_chain_finance_require){
                    initialEmail(data.data);
                }
                if(selectedDemand.vehicle_type !==  ""){
                    logisticRequested(data.data)
                }
                updateDemandStatus(data.data);
            }
            });
    }

    const initialEmail = (value) => {
        setLoader(true)
        const data = {
            "id": value.user_id,
            "order_id": value.ID,
            "total_amount": value.total_amount
          }        
          
          
        const requestOptions = {
            crossDomain:true,
            method: 'POST',     
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/ayekart_only_bnp/initial_email`;
        
        fetch(`${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
                if(selectedDemand.is_supply_chain_finance_require){

                }
            });
    }


    const logisticRequested = (value) => {
        setLoader(true)
        const data = {
            "requested_date_of_shipment": selectedDemand.delivery_date,
            "destination_address": selectedDemand.delivery_address,
            "merchant_id": userInfo.ID,
            "merchant_mobile": userInfo.mobile_number,
            "merchant_name": `${userInfo.first_name} ${userInfo.last_name}`,
            "order_id": value.ID,
            "requested_vehicle_type": selectedDemand.vehicle_type,
            "seller_id": selectedDemand.User?.ID,
            "shop_id": selectedDemand.User?.Shop?.ID,
            "shop_name": selectedDemand.User?.Shop?.name !== '' ? selectedDemand.User?.Shop?.name : 'Test' 
          }
          
          
        const requestOptions = {
            crossDomain:true,
            method: 'POST',     
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/b2b/api/logistics/save_info`;
        
        fetch(`${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            });
    }

    const updateDemandStatus = (value) => {
        setLoader(true)
        const data = {
            "status": "Closed"
          }        
          
          
        const requestOptions = {
            crossDomain:true,
            method: 'PUT',     
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/${userInfo.ID}/demand/${selectedDemand.ID}`;
        
        fetch(`${url}`, requestOptions)
        .then(response => {
            setLoader(false)
            if(response.status === 200){
                connectOrder(value)
            }else{
                toast.error("something went wrong")
            }
            // sendMessageWP()
            
        })
    }

    const sendMessageWP = () => {
        setLoader(true)
        const data = {
            "status": "Closed"
          }        
          
          
        const requestOptions = {
            crossDomain:true,
            method: 'POST',     
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `https://api.kaleyra.io/v1/HXIN1714162191IN/messages`;
        
        fetch(`${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            });
    }

    const connectOrder = (value) => {
        setLoader(true)
        const data = {
            "seller_id": selectedDemand.user_id,
            "demand_id": selectedDemand.ID,
            "order_id": value.ID  
        }     
          
          
        const requestOptions = {
            crossDomain:true,
            method: 'PATCH',     
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/b2b/ayekart/api/demand/order_update`;
        
        fetch(`${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
                if(data.message === "done"){
                    getDemands(status)
                    toast.success("proposal updated")
                }else{
                    toast.error("something went wrong")
                }
            });
    }

    const removeDemand = async (id) => {
        const result = await confirm('Are you sure you want to delete this Request')
        if (result) {
        setLoader(true)
        const data = {
            "status": "Cancelled"
          }           
          
          
        const requestOptions = {
            crossDomain:true,
            method: 'PUT',     
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/${userInfo.ID}/demand/${id}`;
        
        fetch(`${url}`, requestOptions)
        .then(response => {
            setLoader(false)
            if(response.status === 200){
                toast.success("Request deleted successfully")
            }else{
                toast.error("something went wrong")
            }
        })
        }
    }


    const getDemandsOnClick = (status) => {
        setStatus(status);
        getDemands(status)
    }


    const setAssignedItem = (item) => {
        console.log(item)
        setSelDemand(item)
        // setAssignedItem(item)
    }

    const getSignUrl = (name,file) => {
        setLoader(true)
        const s3 = new aws.S3({
          endpoint: "https://ayekart-mobile.sfo2.digitaloceanspaces.com",
          accessKeyId: "JZXLSMYTTPFGOUKX46ZW",
          secretAccessKey: "iOU3OJckYIMvOiQSsjImYSP8KjJ1b1GnBh3TNKIQkTo",
        });
    
        var config = {
          Body: file,
          Bucket:
            "a4920e07cf09ce0e60dff28729322c22fbbf4bbb4d9663ca8428d0a8b73fe03a",
          // Key: "Test/sampleisssmage.jpg", 
          Key: file.name,
        };
    
        s3.putObject(config)
          .on("build", (request) => {
            request.httpRequest.headers.Host =
              "https://ayekart-mobile.sfo2.digitaloceanspaces.com";
            request.httpRequest.headers.Host =
              "http://ayekart-mobile.sfo2.digitaloceanspaces.com";
            request.httpRequest.headers["Content-Length"] = file.size;
            request.httpRequest.headers["Content-Type"] = "image/jpg";
            request.httpRequest.headers["x-amz-acl"] = "public-read";
            request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
          })
          .send((err, data) => {
            setLoader(false)
            if (err) {
              console.log("Failed to upload file", `${err}`);
            } else {
       
                addProductData({...productData, product_images: ['https://ayekart-mobile.sfo2.digitaloceanspaces.com/a4920e07cf09ce0e60dff28729322c22fbbf4bbb4d9663ca8428d0a8b73fe03a/'+file.name]})
            }
          });
      };

    const onChangeFile = (e) => {
        getSignUrl(e.target.name,e.target.files[0])
    }
    return(
        <div>
        <Loader loading={loader} />
          <Header />
          <div className="container-fluid mt-3">
            <div className="custom-breadcrum">
                <span className="disable-color"   style={{cursor:'pointer'}} onClick={() => navigate('/home')}>Home </span> 
                <span className="disable-color"> Demand  </span>
                <span>{status} Requests</span>
            </div>
            <hr></hr>
            <div className="tab-panel-ui container">
                <div className="row">
                    <div className="col col-lg-12">
                        <div className="panel with-nav-tabs panel-default">
                            <div className="panel-heading">
                                <ul className="nav nav-tabs demand-tabs mb-3">
                                    <li  className={status === 'Open'?'active':''}><a href="#tab2default" data-toggle="tab"  onClick={() => getDemandsOnClick('Open')}>Open  
                                    {/* <span className="tab-count">(69)</span> */}
                                    </a></li>
                                    <li  className={status === 'Shared'?'active':''}><a href="#tab3default" data-toggle="tab" onClick={() => getDemandsOnClick('Shared')}>Responded </a></li>
                                </ul>
                            </div>
                            <div className="panel-body">
                                <div className="tab-content">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container mt-3">
            <div className="row">
                {
                    demand.map((item,index) => {
                        return (
                            <div className="col-md-4">
                                <div className="input-container-pro">
                                    <h6><span className="text-primary">Demand Id: </span> {item.id}</h6>
                                    <h6><span className="text-primary">Expected Delivery Date: </span> {moment(item.delivery_date).format('DD MMM YYYY')}</h6>
                                    <h6><span className="text-primary">Shipping Address: </span> {item.shipping_address}</h6>
                                    <h6><span className="text-primary">PinCode: </span> {item.pincode}</h6>
                                    <h6><span className="text-primary">Remarks: </span> {item.remarks}</h6>
                                    <h6><span className="text-primary">Supply Chain Finance Require: </span> {item.is_supply_chain_finance_require? 'Yes' : 'No'}</h6>
                                    <h6 style={{textAlign:'right', color:'lightgray'}}><span>Requested on: </span> {moment(item.created_at).format('DD MMM YYYY HH:mm A')}</h6>
                                    
                                        
                                        <div className="row" style={{width:'100%', marginTop:'30px'}}>
                                       
                                            {
                                                (status === 'Open') &&
                                                <div className="col">                                            
                                                    <button className="btn submit-button-pro-1 btn-block" data-toggle="modal" data-target="#exampleModal" onClick={() => setAssignedItem(item)}>View & Respond Product list</button>
                                                </div>
                                            }
                                            {
                                                (status === 'Shared') &&
                                                <div className="col">                                            
                                                    <button className="btn submit-button-pro-1 btn-block" data-toggle="modal" data-target="#exampleModal1" onClick={() => setAssignedItem(item)}>View Product list</button>
                                                </div>
                                            }
                                        </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <Toaster />
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-body" style={{textAlign: 'left'}}>
                            {
                                selectedDemand?.demand_sellers?.map((item,index) => {
                                    return (
                                        <>
                                            <h6><b>{item.demand_product.product_name}</b></h6>
                                            <h6>Expected Quality : {item.demand_product.product_quality}</h6>
                                            <h6>Expected Quantity : {item.product_quantity} {item.measurement_unit}</h6>
        
                                            <div className="form-group">
                                                {/* <input name='proposedPrice' placeholder={`Input Proposed Price per ${item.measurement_unit}`} className="form-control" type={'number'} /> */}
                                                <input name='proposedPrice' placeholder={`Input Proposed Price`} className="form-control" type={'number'} />
                                            </div>
                                            <div className="form-group">
                                                <input name='offeredQuality' placeholder={`Enter Offered quality`} className="form-control" type={'text'} />
                                            </div>
                                            <div className="form-group">
                                                <input name='sourceLocation' placeholder={`Enter Source location`} className="form-control" type={'text'} />
                                            </div>
                                            <div className="row ">
                                                <div className="col">
                                                    <div className="input-container-pro">
                                                        <label>Upload Product Image</label>
                                                        <input type={'file'} name="product_images" onChange={(e) => onChangeFile(e)} className="input-box form-control"  />
                                                    </div>  
                                                </div>
                                                {
                                                    productData['product_images'].length > 0 && 
                                                    <>
                                                        <div className="col-md-4 input-container-pro">                            
                                                                <img src={productData['product_images'][0]} width={'50px'} alt={'product'} />
                                                                <button onClick={() => addProductData({...productData,product_images:[]})} className="remove-img " >X</button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <hr />
                                        </>
                                    )
                                })
                            }
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary"   data-dismiss="modal" >Done</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-body" style={{textAlign: 'left'}}>
                    
                            <h6 className="text-primary">Responded Price</h6>
                            {
                                selectedDemand?.demand_sellers?.map((item,index) => {
                                    return (
                                        <div className="row">
                                            <div className="col">
                                                <h6><b>{item.demand_product.product_name}</b></h6>
                                                <h6>Quality : {item.demand_product.product_quality}</h6>
                                                <h6>Quantity : {item.demand_product.product_quantity} {item.demand_product.measurement_unit}</h6>
                                                <h6>Offer Price : {item.demand_product.product_price} / {item.demand_product.measurement_unit}</h6>
                                                <h6>Offered Quality : {item.quality_by_seller}</h6>
                                                <hr />
                                            </div>
                                            {
                                                item.demand_product.product.product_images && item.demand_product.product.product_images.length > 0 &&
                                                <div className="col-md-4">
                                                    <img src={item.demand_product.product.product_images[0]} alt="demand" width="50px" />
                                                </div>
                                            }
                                            
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
export default SellersRequest;