import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiUrl1, local_access } from '../constant';

import toast, { Toaster } from "react-hot-toast";
import './../assets/css/login.css';
import logo from './../assets/images/logo.png';
import mobile from './../assets/images/mobile.png';
import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from '../constants/firebaseConfig';
import Loader from './loader';

function Login() {
    !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
    const navigate = useNavigate();
    const [mobileNo,setMobileNo] = React.useState('');
    const [otp,setOtp] = React.useState(0);
    const [otpVerify, setOtpVerify] = React.useState(null);
    const [otpSend, setOtpSend] = React.useState(false);
    const [withOtp, setWithOtp] = React.useState(true);
    const [loader, setLoader] = React.useState(false);

    const redirectTo = (page) => {
        navigate("/"+page);
      }

    const handleSubmit = event => {
        // event.preventDefault();    
        // navigate('/home');
        setLoader(true)
        console.log(mobileNo)
        try{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mobile_number: mobileNo })
        };
        fetch(`ayekart/api/b2b/create_otp`, requestOptions)
        .then(response => response.json())
        .then(data => {
            
            setLoader(false)
            if(data.message === 'done'){
                toast.success('sending otp');
                setOtp(data?.data?.otp);
                sendOtp(data.data)
            }else{
                toast.error(data.message);
            }
        });
    }catch{
        setLoader(false)
    }
    };

    const sendOtp = (data) => {
        
        setLoader(true)
        try{
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(`${local_access}/https://api.paasoo.com/json?key=4fym973z&secret=384QdxT6&from=AYEKRT&to=+91${data.mobile_number}&text=${data.otp}%20is%20your%20OTP%20for%20Ayekart%20Business.%20Please%20do%20not%20share%20it%20with%20anyone.%20Thank%20you!%20Ayekart%20Fintech`, requestOptions)
        .then(response => response.json())
        .then(data => {
            
            setLoader(false)
            if(data.messageid){
                toast.success('otp sent to your mobile');
                setOtpSend(true)
            }
        });
    }catch{
        setLoader(false)
    }
    }

    const VerifyOtp = () => {
        console.log(otp,otpVerify)
        if(Number(otp) === Number(otpVerify)){
            toast.success('otp Verified');
            getDetail();
        }else {
            toast.error('OTP not matched');
        }
    }

    const [password,setPassword] = React.useState('');


  
      const handlePwdSubmit = event => {
        
            setLoader(true)
          firebase.auth().fetchSignInMethodsForEmail(`${mobileNo}@example.com`)
                  .then((userCredential) => {
                      if (userCredential[0] === 'password') {
                          firebase.auth().signInWithEmailAndPassword(`${mobileNo}@example.com`, password)
                              .then((userCredential) => {
                                
                                    setLoader(false)
                                  // Signed in
                                  toast.success('login success')
                                  console.log(userCredential,'userCredentialelse')
  
                                  getDetail()
                                  // ...
                              })
                              .catch((error) => {
                                setLoader(false)
                                  toast.error(error.message);
                              });
                      } else {
                        setLoader(false)
                          toast.error("Account with this Email doesn't exist");
                      }
                  })
                  .catch((error) => {
                    setLoader(false)
                      toast.error(error);
                      console.log("Not sent" + error);
                      // ..
                  });
      };

    const getDetail = () => {
        setLoader(true)
        try{
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(`/ayekart/api/merchant/me?mobile_number=${mobileNo}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.message === 'done'){
                localStorage.setItem('userInfo', JSON.stringify(data.data))
                redirectTo('home')
            }
        });
    }catch{
        setLoader(false)
    }
    }
    return (
        <>
        <Loader loading={loader} />
        {
            withOtp ?
            <div className="login-dark full-height" style={{ background: '#214a80' }}>
        {
            otpSend ?
            <div className='form' >
                <h2 className="sr-only">OTP</h2>
                <div className="illustration">
                    <img alt="" className="logo-img" src={logo} />
                </div>
                <div className="login-txt-color mb-4">
                    <p style={{ fontSize: '17px',fontWeight: 400,marginBottom: '1px' }}>Mini ERP</p>
                    <p style={{ fontSize: '35px',fontWeight: 520,margin: 0,padding: 0}}>OTP</p>
                    <p style={{ color: '#a2a5b1' }}>Enter your details below to continue</p>
                </div>
                <div className="input-container">
                    <input className="input-field" id="otp" type="number" value={otpVerify} onChange={(e) => setOtpVerify(e.target.value)} name="otp" placeholder="Enter otp here"></input>
                </div>
                <div className="form-group login-button">
                    <button type='submit' disabled={!otpVerify} onClick={VerifyOtp}  className="login-bottom">Verify</button>
                </div>
                <div className="login-txt-color text-center">
                    <p>OR</p>
                </div>
                <div className="form-group login-button mb-4">
                    <button className="pwd-bottom" onClick={() => setOtpSend(false)}>Back to login</button>
                </div>
            </div>
            :
            <div className='form' >
                <h2 className="sr-only">Login Form</h2>
                <div className="illustration">
                    <img alt="" className="logo-img" src={logo} />
                </div>
                <div className="login-txt-color mb-4">
                    <p style={{ fontSize: '17px',fontWeight: 400,marginBottom: '1px' }}>Mini ERP</p>
                    <p style={{ fontSize: '35px',fontWeight: 520,margin: 0,padding: 0}}>Login</p>
                    <p style={{ color: '#a2a5b1' }}>Enter your details below to continue</p>
                </div>
                <div className="input-container">
                    <img alt="" src={mobile} className='mobile-img'/>
                    <input className="input-field" id="mobile" type="number" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} name="mobile" placeholder="Enter mobile number"></input>
                </div>
                <div className="form-group login-button">
                    <button type='submit' disabled={!mobileNo} onClick={handleSubmit}  className="login-bottom">Log With OTP</button>
                </div>
                <div className="login-txt-color text-center">
                    <p>OR</p>
                </div>
                <div className="form-group login-button mb-4">
                    <button className="pwd-bottom"  onClick={() => setWithOtp(false)}>I’ll use password instead</button>
                </div>
            </div>
        }
        </div>
        :
        <div className="login-dark full-height" style={{ background: '#214a80' }}>
            <div className='form' >
                <h2 className="sr-only">Login Form</h2>
                <div className="illustration">
                    <img alt="" className="logo-img" src={logo} />
                </div>
                <div className="login-txt-color mb-4">
                    <p style={{ fontSize: '17px',fontWeight: 400,marginBottom: '1px' }}>Mini ERP</p>
                    <p style={{ fontSize: '35px',fontWeight: 520,margin: 0,padding: 0}}>Login</p>
                    <p style={{ color: '#a2a5b1' }}>Enter your details below to continue</p>
                </div>
                <div className="input-container">
                    {/* <img alt="" src={mobile} className='mobile-img'/> */}
                    <input className="input-field" id="mobile" type="number" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} name="mobile" placeholder="Enter mobile number"></input>
                </div>
                <div className="input-container">
                    {/* <img alt="" src={otp} className='mobile-img'/> */}
                    <input className="input-field" id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" placeholder="Enter your password"></input>
                </div>
                <div className="form-group login-button">
                    <button type='submit' disabled={!mobileNo && !password} onClick={handlePwdSubmit}  className="login-bottom">Login</button>
                </div>
                <div className="login-txt-color text-center">
                    <p>OR</p>
                </div>
                <div className="form-group login-button mb-4">
                    <button className="pwd-bottom" onClick={() => setWithOtp(true)}>I’ll use OTP instead</button>
                </div>
            </div>
            
        </div>
        }
        
        <Toaster />
        </>
    );
}

export default Login;