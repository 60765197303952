import filter from "./../assets/images/filter.png";
import Header from "./Header";
import './../assets/css/style.css'
import React, { useEffect } from "react";
import { ApiUrl, local_access } from "../constant";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./loader";
import { useNavigate } from "react-router-dom";

function RecievedOrder() {
    const [orders, setOrder] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(20);
    const [search, setSearch] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [products, setProducts] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState({})
    const [loader, setLoader] = React.useState(false);
    const [prodId, setProdId] = React.useState(null);
    const [orderStatus, setOrderStatus] = React.useState('')

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userInfo'))
        console.log(user)
        setUserInfo(user)    
    },[])

    useEffect(() => {
        getOrders('OPEN')
    },[userInfo])

    const getOrders = (status) => {
        setLoader(true)
        setStatus(status);
        if(userInfo.ID){
            try{
                const requestOptions = {
                    crossDomain:true,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 
                    'Access-Control-Allow-Origin':'*', }
                };
                let url = '';
                if(status === 'OPEN'){
                    url = `/v2/merchant/${userInfo.ID}/orders/received?except_status=CANCELLED,Order%20Delivered&page=${page}&limit=${limit}&text=${search}`;
                }else{
                    url = `/v2/merchant/${userInfo.ID}/orders/received?page=${page}&limit=${limit}&order_status=${status}&text=${search}`;
                }
                fetch(`${local_access}${url}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setLoader(false)
                    if(data.code === 200){
                        setOrder(data?.data?.merchat_orders)
                    }
                });
            }catch{
            setLoader(false)
        }
    }
}


const changeOrderStatus = () => {
    setLoader(true)
    let body = {
        "order_status": orderStatus,
        "payment_status": ""
    
    }
    if(userInfo.ID){
        try{
            const requestOptions = {
                crossDomain:true,
                method: 'PATCH',
                body: JSON.stringify(body),
                headers: { 'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin':'*', }
            };
            let url =  `/v1/merchant/${userInfo.ID}/orders/${prodId}/received`;
            fetch(`${local_access}${url}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLoader(false)
                if(data.code === 202){
                    toast.success('Order Status Updated Successfully');
                    getOrders(status)
                }else{
                    toast.error('Something went wrong. please try again')
                }
            });
        }catch{
        setLoader(false)
    }
}
}

    
    const navigate = useNavigate();

    const setProductId = (order) => {
        setProdId(order.ID)
        setOrderStatus(order.order_status)
        console.log(order.order_status)
    }

    const searchData = (e) => {
        setSearch(e.target.value);
        if(search.length > 2){
            getOrders(status)
        }
    }


  return (
    <div>
    
    <Loader loading={loader} />
      <Header />
    <div className="container-fluid mt-3">
        <div className="custom-breadcrum">
            <span className="disable-color" style={{cursor:'pointer'}} onClick={() => navigate('/home')}>Home</span> 
            <span className="disable-color"> Received Orders</span>
        </div>
        <p style={{ fontSize: '35px', color: '#565e7d' ,fontWeight: 600,letterSpacing: '1px' }}>Received Orders</p>
        <p className="disable-color">{orders.length} {status} orders found</p>
        <hr></hr>
    </div>
    <div className="container-fluid mt-3">
        {/* <div className="filter-ui text-right">
            <img alt="" width="20" src={filter} /> <input className="filter-checkbox" type="checkbox" /> <span className="text-dark">Show All</span>
        </div> */}
        <div className="tab-panel-ui container">
            <div className="row">
                <div className="col col-lg-7">
                    <div className="panel with-nav-tabs panel-default">
                        <div className="panel-heading">
                            <ul className="nav nav-tabs">
                                <li  className={status === 'OPEN'?'active':''}><a href="#tab2default" data-toggle="tab"  onClick={() => getOrders('OPEN')}>Open  
                                {/* <span className="tab-count">(69)</span> */}
                                </a></li>
                                <li  className={status === 'DELIVERED'?'active':''}><a href="#tab3default" data-toggle="tab" onClick={() => getOrders('DELIVERED')}>Delivered </a></li>
                                <li  className={status === 'CANCELLED'?'active':''}><a href="#tab4default" data-toggle="tab" onClick={() => getOrders('CANCELLED')}>Cancelled </a></li>
                            </ul>
                        </div>
                        <div className="panel-body">
                            <div className="tab-content">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="search-input-div">
            <input className="search-input" type="text"onKeyDown={(e) => e.code === 'Enter' && getOrders(status)}  onChange={(e) => searchData(e)} value={search}  placeholder="Search by order id, Business Name, Address" />
        </div>


        <div className="table-responsive">

            <table className="zui-table zui-table-rounded">
            <thead>
                    <tr>
                        <th>ID</th>
                        <th>Business Name</th>
                        <th>Address</th>
                        <th>Date & Time</th>
                        <th>Order Total</th>
                        <th className="text-right">Status</th>
                    </tr>
                </thead>
                <tbody>
                {
                    orders && orders.map((item,index) => {
                        return (
                            <tr key={index}>
                                <td><span className="order-id">#{item.Order?.ID} </span> <a  className="product-list"  data-toggle="modal" data-target="#exampleModal" onClick={() => setProducts(item.Order?.OrderItems)}  href={() => false}>View Product List</a></td>
                                <td>{item.UserDetails?.Shop?.name}</td>
                                <td>{item.UserDetails?.address},{item.UserDetails?.state},{item.UserDetails?.pin_code}</td>
                                <td>{moment(item.Order?.ordered_date).format('DD.MM.YYYY')}</td>
                                <td>Rs.{item.Order?.total_amount} </td>
                                <td className="text-right">
                                    <span className="text-primary">{item.Order?.order_status}</span>
                                    <div className="dropdown dropdown-action action-option">
                                        <a href={() => false} className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                        <div className="dropdown-menu dropdown-menu-down">
                                            <a className="dropdown-item" href={() => false} onClick={() => navigate(`/shipment/${item.Order.ID}`)}>View/Upload Shipment Docs</a>
                                            <a className="dropdown-item" href={() => false} onClick={() => navigate(`/tranches/${item.Order.ID}/${item.Order.user_id}/${userInfo.ID}`)}>View/Add Tranches</a>
                                            <a className="dropdown-item" href={() => false} data-toggle="modal" data-target="#exampleModal1" onClick={() => setProductId(item.Order)}>Update Order Status</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    
                    })
                }                    
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Products Detail</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style={{textAlign: 'left'}}>
            <table class="table-responsive" style={{    display: 'table'}}>
                <tbody>
                    {
                        products ? products.map(item => {
                            return (
                                <>
                                <tr>
                                    <th>Product name</th>
                                    <td>{item.product?.product_name}</td>
                                </tr>
                                {
                                    item.product?.product_images   &&                                     
                                    <tr>
                                        <th>Image</th>
                                        <td><img width="100" src={item.product?.product_images[0]} alt="" /></td>
                                    </tr>
                                }
                                <tr>
                                    <th>Selling price</th>
                                    <td>&#8377; {item.product?.selling_price} / {item.product?.measurement_unit}</td>
                                </tr>
                                <tr>
                                    <th>Stock</th>
                                    <td>{item.product?.stock}</td>
                                </tr>
                                <tr>
                                    <th>Quantity</th>
                                    <td>{item.quantity} {item.product?.measurement_unit}</td>
                                </tr>
                                <hr />
                                </>
                            )
                        })
                        :
                        <tr>
                            <td colSpan={2}>No Prouducts</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
        </div>
    </div>
    </div>

    <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
        <div class="modal-body" style={{textAlign: 'left'}}>
            <ul className="order-option-list">
                <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="orderStatus" checked={orderStatus == 'Order Processing'} value="Order Processing" onChange={(e) => setOrderStatus(e.target.value)} id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Order Processing
                        </label>
                    </div>
                </li>
                <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="orderStatus" checked={orderStatus == 'Order Packed'} value="Order Packed" onChange={(e) => setOrderStatus(e.target.value)} id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Order Packed
                        </label>
                    </div>
                </li>
                <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="orderStatus" checked={orderStatus == 'Order Dispatched'} value="Order Dispatched" onChange={(e) => setOrderStatus(e.target.value)} id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Order Dispatched
                        </label>
                    </div>
                </li>
                <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="orderStatus" checked={orderStatus == 'On The Way'} value="On The Way" onChange={(e) => setOrderStatus(e.target.value)} id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            On The Way
                        </label>
                    </div>
                </li>
                <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="orderStatus" checked={orderStatus == 'Out For Delivery'} value="Out For Delivery" onChange={(e) => setOrderStatus(e.target.value)} id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Out For Delivery
                        </label>
                    </div>
                </li>
                <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="orderStatus" checked={orderStatus == 'Order Delivered'} value="Order Delivered" onChange={(e) => setOrderStatus(e.target.value)} id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Order Delivered
                        </label>
                    </div>
                </li>
                <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="orderStatus" checked={orderStatus == 'Order Not Delivered'} value="Order Not Delivered" onChange={(e) => setOrderStatus(e.target.value)} id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Order Not Delivered
                        </label>
                    </div>
                </li>
                <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="orderStatus" checked={orderStatus == 'Order Returned'} value="Order Returned" onChange={(e) => setOrderStatus(e.target.value)} id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Order Returned
                        </label>
                    </div>
                </li>
                <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="orderStatus" checked={orderStatus == 'Customer Not Preset At The Time Of Delivery'} value="Customer Not Preset At The Time Of Delivery" onChange={(e) => setOrderStatus(e.target.value)} id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Customer Not Preset At The Time Of Delivery
                        </label>
                    </div>
                </li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary btn-sm" onClick={changeOrderStatus} data-dismiss="modal">Submit</button>
        </div>
        </div>
    </div>
    </div>
    
    <Toaster />
    </div>
  );
}

export default RecievedOrder;
