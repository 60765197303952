import React from 'react';
import { Circles } from 'react-loader-spinner';
function Loader(props){
    return (
            props.loading &&
            <div className='loading-screen'>  
                <Circles  color="#00BFFF" height={80} width={80}/>
            </div>
           
    )
}

export default Loader;