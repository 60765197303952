import React,{useState,useEffect} from 'react';
import axios from 'axios'

function InvoiceList() {
const[invoiceList, setInvoiceList] = useState([])
    useEffect(() => {
        axios.get('http://128.199.23.167:9000/v1/user/2/business/2/invoices').then((res) => {
            console.log('ressssss', res)
            setInvoiceList(res.data.data)
        })
    }, [])
    return(
        <div className="table-responsive">

        <table className="zui-table zui-table-rounded">
        <thead>
                <tr>
                    <th>ID</th>
                    <th>Download Invoice</th>
                </tr>
            </thead>
            <tbody>
                       
                            {
                                invoiceList?.map((list, index) => {
                                    return(
                                        <tr>
                                            <th>{list.ID}</th>
                                            <th> 
                                                <a className="product-list"  data-toggle="modal" href={() => false}>link</a>
                                            </th>
                                        </tr>
                                        
                                    )
                                })
                            }
                           
                            
                           
                            
                        
                
            </tbody>
        </table>
    </div>
    )
}

export default InvoiceList