import Header from "../Header";
import "./invoice.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect, useRef } from "react";
// import { getData } from "../../services/Apicall";
import Axios from "axios";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../loader";
import { ApiUrl, local_access } from "../../constant";
import "react-datepicker/dist/react-datepicker.css";
import layer from "../../assets/images/layer-1.png";
import printerIcon from '../../assets/images/printerIcon.png'
import saveIcon from '../../assets/images/SaveIcon.png'

// function createData(SrNo, desc, hsncode, qty,rate,amount,discount,taxable,igst,igstamount,cgstrate,csgtamount,cessamount,cessrate,totalamount) {
//   return { SrNo, desc, hsncode, qty,rate,amount,discount,taxable,igst,igstamount,cgstrate,csgtamount,cessamount,cessrate,totalamount};
// }

const rows = [];
// createData('1', "Wheat","abshdkba","50","0","91428.57","5","0","0","0","0","0","0","91428.57"),
// ('2', "Wheat","abshdkba","50","0","91428.57","5","0","0","0","0","0","0","91428.57"),
// ('2', "Wheat","abshdkba","50","0","91428.57","5","0","0","0","0","0","0","91428.57")];

function Invoice() {
  const [InvoiceDetails, setInvoiceDetails] = useState({});
  const [productDetails, setProductDetail] = useState([]);
  const [value, setValue] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [invoicePostResponse, setInvoicePostResponse] = useState({});
  const [invoiceDateDisplay, setInvoiceDateDisplay] = useState(null);
  const [other_advance_payment_date, setOtherAdvancePaymentDate] = useState(
    new Date()
  );
  const [dateOfShipment, setDateOfShipment] = useState(new Date());
  const [param] = useSearchParams();
  console.log("paramsssss", param.get("id"));
  const [userInfo, setUserInfo] = React.useState({});
  const [formObject, setFormObject] = useState({
    invoice_no: "",
    invoice_date: "",
    ref_no: "",
    other_ref: "",
    invoice_to: "",
    invoice_to_address: "",
    invoice_to_pan_no: "",
    invoice_gstin: "",
    shipment_date: "",
    lorry_no: "",
    katha_parche: "",
    other_date_of_shipment: "",
    other_acceptance_note: "",
    other_advance_payment_date: "",
    terms_payment_within: "",
    terms_amount_due: "",
    amount_payable_words: "",
    total: "",
    remark: "",
    products: [],
    invoice_ship_to: "",
    invoice_to_address_ship_to: "",
    invoice_to_pan_no_ship_to: "",
    invoice_gstin_ship_to: "",
    contact_person_info_ship_to: "",
    contact_person_info: "",
    delivery_date: "",
    total_igst: 0.0,
    total_cgst: 0.0,
    total_sgst: 0.0,
    katha_parche_ship_to: "",
    lorry_no_ship_to: "",
    shipment_date_ship_to: "",
    total_igst_percentage: "",
    total_cgst_percentage: "",
    total_sgst_percentage: "",
    total_cess_percentage: "",
    total_cess: 0.0,
  });
  const componentRef = useRef();
  let netTotalAmount = 0;
  // useEffect(() => {
  //   console.log("data is ===> ", formObject);
  // }, [formObject]);

  // useEffect(() => {
  //   fetchDetails();
  // }, []);

  // useEffect(() => {
  //   getroduct();
  // }, []);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    console.log(user);
    setUserInfo(user);
    fetchDetails();
    getroduct(user.ID);
  }, []);

  const getroduct = async (id) => {
    console.log("userInfpooooo", userInfo, id);
    let url = `/v2/merchant/${id}/orders/received?except_status=CANCELLED,Order%20Delivered&page=1&limit=10&text=`;
    const { data } = await Axios.get(`${local_access}${url}`);
    console.log("dataaaaaa 115", data.data.merchat_orders);
    const filteredData =
      data.data.merchat_orders.filter(
        (data) => data.Order.ID == param.get("id")
      ) || [];
    console.log("filteeredDAtataaa", filteredData);
    setProductDetail(filteredData);
  };
  console.log("dataaa 9444", productDetails);
  const fetchDetails = async () => {
    const { data } = await Axios.get(`/v1/order/finance-invoices`);

    console.log("Data is coming ", data.data);
    const details = data.data;
    // const converted=JSON.parse(data.replace('/', '-'));

    setInvoiceDetails(InvoiceDetails);
  };

  const createInvoice = async () => {
    // window.print()
    setLoader(true);
    console.log("formObjectformObject", formObject);
    console.log("data send to ser ", formObject);
    formObject.other_advance_payment_date = "2022-09-06T06:28:00.679Z";
    //formObject.invoice_date = '2022-09-06T06:28:00.679Z';
    //formObject.shipment_date = '2022-09-06T06:28:00.679Z';
    formObject.shipment_date_ship_to = "2022-09-06T06:28:00.679Z";
    formObject.delivery_date = "2022-09-06T06:28:00.679Z";
    //formObject.invoice_gstin_ship_to = '2022-09-06T06:28:00.679Z';
    formObject.other_date_of_shipment = "2022-09-06T06:28:00.679Z";
    formObject.total = 0.0;
    // formObject.products = productDetails
    Axios.post(`/v1/order/finance-invoice`, formObject).then((res) => {
      console.log("res is ", res);
      setLoader(false);
      if (res.status === 200) {
        toast.success("Invoice created successfully");
      }
      setInvoicePostResponse(res.data.data);
    });

    // const input = document.getElementById('invoiceContainer');
    //     html2canvas(input)
    //         .then((canvas) => {
    //             const imgData = canvas.toDataURL('image/png');
    //             const pdf = new jsPDF();
    //             pdf.addImage(imgData, 'JPEG', 0, 0);
    //             pdf.save(`test.pdf`);
    //         })
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  var num =
    "zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(
      " "
    );
  var tens = "twenty thirty forty fifty sixty seventy eighty ninety".split(" ");

  function number2words(n) {
    if (n < 20) return num[n];
    let digit = n % 10;
    if (n < 100) return tens[~~(n / 10) - 2] + num[digit];
    if (n < 1000)
      return num[~~(n / 100)] + " hundered" + " " + number2words(n % 100);
    return (
      number2words(~~(n / 1000)) + " thousand" + " " + number2words(n % 1000)
    );
    //   if (n < 20) return num[n];
    //   var digit = n%10;
    //   console.log('n/10', n/10, '~~n/10', ~~(n/10))
    //   if (n < 100) return tens[~~(n/10)-2] + (digit? "-" + num[digit]: "");
    //   if (n < 1000) return num[~~(n/100)] +" hundred" + (n%100 == 0? "": " " + number2words(n%100));
    //   return number2words(~~(n/1000)) + " thousand" + (n%1000 != 0? " " + number2words(n%1000): "");
  }

  return (
    <div className="">
      <Loader loading={loader} />
      <Header />
      


      <div className="conntainer">
        <div className="row mx-auto print-header-nav mb-3">
          <div className="col-4 text-left">
            <img className="" height={75} width={150} src={layer} />
          </div>
          <div className="col-5 pt-3">
            <p className="float-end TAX-INVOICE">TAX INVOICE</p>
            <p className="print-header">AYEKART FINTECH PRIVATE LIMITED</p>
            <p className="print-header1">702, Shah Trade Center, 7th Floor,Above SBI Bank, Rani Sati Road,Near Western Express Highway,
            Malad (East), Mumbai - 400 097.</p>   
          </div>

          <div className="col-3">
          <div className="save_button row pt-2 m-0">
            <div className="col-9">
              <div className="row d-flex justify-content-end">
              <img className="" height={50} width={50} src={printerIcon} onClick={() => {window.print();}}/>
              </div>
              <div className="row d-flex justify-content-end pr-2">
              <p>Print</p>
              </div>
            </div>
            <div className="col-3">
              <div className="row d-flex justify-content-end">
              <img height={50} width={50} src={saveIcon} onClick={createInvoice}/>
              </div>
              <div className="row d-flex justify-content-end pr-2">
              <p>Save</p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className="row mx-auto pr-3">
        <Col className="invoice-details-box-column-1">
          <h6 className="invoice-to-text">Invoice To</h6>
          <Row>
            <Col>
              <div>
                <Form.Label className="fieldLabel">Name of firm</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Name of firm"
                  value={formObject.invoice_to}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, invoice_to: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">Kata Parchi</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Kata Parchi"
                  value={formObject.katha_parche}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, katha_parche: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">GSTIN</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="GSTIN"
                  value={formObject.invoice_gstin}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, invoice_gstin: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">
                  Contact Person Name
                </Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Contact Person Name"
                  value={formObject.contact_person_info}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, contact_person_info: val };
                    });
                  }}
                />
              </div>
            </Col>
            <Col>
              <div>
                <Form.Label className="fieldLabel">Address</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Address"
                  value={formObject.invoice_to_address}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, invoice_to_address: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">Lorry Number</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Lorry Number"
                  value={formObject.lorry_no}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, lorry_no: val };
                    });
                  }}
                />
                <Form.Label className="fieldLabel">Pan Number</Form.Label>
                <Form.Control
                  className="invoice-details"
                  placeholder="Pan Number"
                  value={formObject.invoice_to_pan_no}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormObject((prevState) => {
                      return { ...prevState, invoice_to_pan_no: val };
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col className="invoice-details-box-column-1">
          <div className="row">
            <div className="col-6 pr-0">
            <h6 className="invoice-to-text text-right">Ship To</h6>
            </div>
            <div className="col-6 text-right pt-1">
            <span className="">
            Same as invoice to
            <input type="checkbox"
            className=" "
            onChange={(e) => {
              if (e.target.checked) {
                setFormObject((prevState) => {
                  return {
                    ...prevState,
                    invoice_ship_to: formObject.invoice_to,
                    katha_parche_ship_to: formObject.katha_parche,
                    contact_person_info_ship_to:
                      formObject.contact_person_info,
                    invoice_to_address_ship_to: formObject.invoice_to_address,
                    lorry_no_ship_to: formObject.lorry_no,
                    invoice_to_pan_no_ship_to: formObject.invoice_to_pan_no,
                    invoice_gstin_ship_to: formObject.invoice_gstin,
                  };
                });
              } else {
                setFormObject((prevState) => {
                  return {
                    ...prevState,
                    invoice_ship_to: "",
                    katha_parche_ship_to: "",
                    contact_person_info_ship_to: "",
                    invoice_to_address_ship_to: "",
                    lorry_no_ship_to: "",
                    invoice_to_pan_no_ship_to: "",
                    invoice_gstin_ship_to: "",
                  };
                });
              }
            }}
            />
            </span>
            </div>
          </div>
          <Row>
            <Col>
              <Form.Label className="fieldLabel">Name of firm</Form.Label>
              <Form.Control
                className="invoice-details"
                placeholder="Name of firm"
                value={formObject.invoice_ship_to}
                onChange={(e) => {
                  const val = e.target.value;
                  setFormObject((prevState) => {
                    return { ...prevState, invoice_ship_to: val };
                  });
                }}
              />
              <Form.Label className="fieldLabel">GSTIN</Form.Label>
              <Form.Control
                className="invoice-details"
                placeholder="GSTIN"
                value={formObject.invoice_gstin_ship_to}
                onChange={(e) => {
                  const val = e.target.value;
                  setFormObject((prevState) => {
                    return { ...prevState, invoice_gstin_ship_to: val };
                  });
                }}
              />
              <Form.Label className="fieldLabel">Kata Parachi</Form.Label>
              <Form.Control
                className="invoice-details"
                placeholder="Kata Parachi"
                value={formObject.katha_parche_ship_to}
                onChange={(e) => {
                  const val = e.target.value;
                  setFormObject((prevState) => {
                    return { ...prevState, katha_parche_ship_to: val };
                  });
                }}
              />
              <Form.Label className="fieldLabel">
                Contact Person Name
              </Form.Label>
              <Form.Control
                className="invoice-details"
                placeholder="Contact Person Name"
                value={formObject.contact_person_info_ship_to}
                onChange={(e) => {
                  const val = e.target.value;
                  setFormObject((prevState) => {
                    return { ...prevState, contact_person_info_ship_to: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="fieldLabel">Address</Form.Label>
              <Form.Control
                className="invoice-details"
                placeholder="Address"
                value={formObject.invoice_to_address_ship_to}
                onChange={(e) => {
                  const val = e.target.value;
                  setFormObject((prevState) => {
                    return { ...prevState, invoice_to_address_ship_to: val };
                  });
                }}
              />
              <Form.Label className="fieldLabel">Lorry Number</Form.Label>
              <Form.Control
                className="invoice-details"
                placeholder="Lorry Number"
                value={formObject.lorry_no_ship_to}
                onChange={(e) => {
                  const val = e.target.value;
                  setFormObject((prevState) => {
                    return { ...prevState, lorry_no_ship_to: val };
                  });
                }}
              />
              <Form.Label className="fieldLabel">Pan Number</Form.Label>
              <Form.Control
                className="invoice-details"
                placeholder="Pan Number"
                value={formObject.invoice_to_pan_no_ship_to}
                onChange={(e) => {
                  const val = e.target.value;
                  setFormObject((prevState) => {
                    return { ...prevState, invoice_to_pan_no_ship_to: val };
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className="invoice-details-box-column-1">
          <div className="row d-flex mx-auto justify-content-center invoice-to-text pb-5">Terms of Delivery and Payments</div>
          <p>
            1. Payment to be made within
            <TextField
              id="standard-basic"
              label=""
              variant="standard"
              value={formObject.terms_payment_within}
              onChange={(e) => {
                const val = e.target.value;
                setFormObject((prevState) => {
                  return { ...prevState, terms_payment_within: val };
                });
              }}
            />
            days of confirmation of order by Ayekart and/ or release of advance
            towards the supplier against this order
          </p>
          <p>
            2. Any amount due after
            <TextField
              id="standard-basic"
              label=""
              variant="standard"
              value={formObject.terms_amount_due}
              onChange={(e) => {
                const val = e.target.value;
                setFormObject((prevState) => {
                  return { ...prevState, terms_amount_due: val };
                });
              }}
            />
            days will attract a interest of 3% per month.
          </p>
          <p>
            3. Interest will be calculated on daily basis including the day of
            payment.
          </p>
          4. Taxes as applicable will be charged additional on the interest due
          to delay in payments.
          <p>
            5. Advances, if any, paid by the buyer will be adjusted in the last
            tranche of the order.
          </p>
        </Col>
      </div>

      <Row className="invoice-number-section">
        <Col>
          <Form.Label className="fieldLabel">Invoice Date</Form.Label>
          <DatePicker
            className="date-picker"
            placeholderText="Invoice Date"
            selected={invoiceDateDisplay}
            // value={invoiceDateDisplay}
            onChange={(date) => {
              console.log(
                "moment",
                moment(date).format(),
                moment(date).format("yyyy/mm/dd")
              );
              setInvoiceDateDisplay(date);
              setFormObject((prevState) => {
                return { ...prevState, invoice_date: moment(date).format() };
              });
            }}
          />
        </Col>
        <Col>
          <Form.Label className="fieldLabel">Invoice Number</Form.Label>
          <Form.Control
            placeholder="Invoice Number"
            value={formObject.invoice_no}
            onChange={(e) => {
              const val = e.target.value;
              setFormObject((prevState) => {
                return { ...prevState, invoice_no: val };
              });
            }}
          />
        </Col>
        <Col xs={5}>
          <Form.Label className="fieldLabel">Other References</Form.Label>
          <Form.Control
            placeholder="Other References"
            value={formObject.other_ref}
            onChange={(e) => {
              const val = e.target.value;
              setFormObject((prevState) => {
                return { ...prevState, other_ref: val };
              });
            }}
          />
        </Col>
      </Row>

      {/* {
        invoicePostResponse?.products && productDetails.length > 0 ?  */}
      <TableContainer
        className="table-container table-section"
        component={Paper}
      >
        <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>SrNo</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Description of Goods</TableCell>

              <TableCell>HSN Code</TableCell>
              <TableCell>Qty&nbsp;(in Kgs/Ltrs/Pcs)</TableCell>
              <TableCell>Rate per&nbsp;(in Kgs/Ltrs/Pcs)</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Taxable</TableCell>
              <TableCell>IGST</TableCell>
              <TableCell>CGST</TableCell>
              <TableCell>SGST</TableCell>
              <TableCell>CESS</TableCell>
              <TableCell>Total Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productDetails[0]?.Order?.OrderItems.map((row) => {
              if (row?.product?.min_order_qty > 0) {
                netTotalAmount =
                  netTotalAmount +
                  row?.product?.product_amount * row?.product?.min_order_qty;
              } else {
                netTotalAmount = netTotalAmount + row.product?.product_amount;
              }

              return (
                <TableRow
                  key={row?.product?.ID}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.product?.ID}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.product?.product_name}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row?.product?.product_desc}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.hsncode}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row?.product?.min_order_qty}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.rate}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row?.product?.product_amount}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row?.product?.discount_percentage}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.taxable}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row?.product?.gst}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.cgstrate}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.csgtamount}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.cessrate}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.product?.min_order_qty === 0
                      ? row?.product?.product_amount
                      : row?.product?.product_amount *
                        row?.product?.min_order_qty}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*       
      : null
} */}

      <div className="bottom-section">
        <Box>
          <Grid container spacing={3}>
            <div className="bank-details">
              <Grid item xs>
                <h6>Bank Details</h6>
                <p>Ayekart Fintech Private Limited</p>
                <p>Account Number: 641905052652</p>
                <p>Bank Name: ICICI Bank (Current Account)</p>
                <p>Branch Name: Malad East</p>
                <p>IFSC: ICIC006419</p>
              </Grid>
            </div>

            <div className="orderDetailsContainer">
              <Row className="order-details-container">
                <Col>
                  <p>Date of shipment</p>
                  <DatePicker
                    className="date-picker"
                    placeholderText="Date of Shipment"
                    selected={dateOfShipment}
                    onChange={(date) => {
                      console.log(
                        "moment",
                        moment(date).format(),
                        moment(date).format("yyyy/mm/dd")
                      );
                      setDateOfShipment(date);
                      setFormObject((prevState) => {
                        return {
                          ...prevState,
                          shipment_date: moment(date).format(),
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <p>Date of advance payment released to supplier</p>
                  <DatePicker
                    placeholderText="Date of advance payment released to supplier"
                    className="date-picker"
                    selected={other_advance_payment_date}
                    onChange={(date) => {
                      console.log(
                        "moment",
                        moment(date).format(),
                        moment(date).format("yyyy/mm/dd")
                      );
                      setOtherAdvancePaymentDate(date);
                      setFormObject((prevState) => {
                        return {
                          ...prevState,
                          other_advance_payment_date: moment(date).format(),
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <h6>Order Details</h6>
                  <Form.Control
                    style={{ height: "100px", padding: "20px" }}
                    placeholder="Attached acceptance note"
                    value={formObject.other_acceptance_note}
                    onChange={(e) => {
                      const val = e.target.value;
                      setFormObject((prevState) => {
                        return {
                          ...prevState,
                          other_acceptance_note: val,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>


            <Grid className="taxable-table">
              <h6>Total Taxable (INR)</h6>
              <TableContainer className="table-container">
                <Table
                  sx={{ minWidth: 400 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableRow>
                    <TableCell width={"20%"}>IGST</TableCell>
                    <TableCell width={"20%"}>18%</TableCell>
                    <TableCell width={"20%"}>736</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={"20%"}>CGST</TableCell>
                    <TableCell width={"20%"}>18%</TableCell>
                    <TableCell width={"20%"}>736</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={"20%"}>SGST</TableCell>
                    <TableCell width={"20%"}>6%</TableCell>
                    <TableCell width={"20%"}>9</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell width={"20%"}>CESS</TableCell>
                    <TableCell width={"20%"}>12%</TableCell>
                    <TableCell width={"20%"}>9</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell width={"20%"}>Net Payable Amount</TableCell>
                    <TableCell width={"20%"}>{}</TableCell>
                    <TableCell width={"20%"}>{netTotalAmount}</TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>


        <Box>
          <Grid container item xs>
            <div className="amountpayableContainer">
              <Grid xs={5}>
                <h6>Amount Payable in Words</h6>
                <p>{number2words(netTotalAmount)}</p>
              </Grid>
            </div>
            <div className="signatureContainer">
              <Grid item xs={2}>
                <h6>Signature</h6>
              </Grid>
            </div>
          </Grid>
        </Box>
      </div>

      <Toaster />
    </div>
  );
}
export default Invoice;
