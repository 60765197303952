import filter from "./../assets/images/filter.png";
import Header from "./Header";
import './../assets/css/style.css'
import React, { useEffect } from "react";
import { ApiUrl, local_access } from "../constant";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./loader";
import { useNavigate } from "react-router-dom";

function Inventory() {
    
    const navigate = useNavigate();
    const [orders, setOrder] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(20);
    const [eml, setEml] = React.useState(true);
    const [search, setSearch] = React.useState('');
    const [status, setStatus] = React.useState('approved');
    const [addData, setAddData] = React.useState({});
    const [productId, setProductId] = React.useState(null);
    const [userInfo, setUserInfo] = React.useState({})
    const [loader, setLoader] = React.useState(false);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userInfo'))
        console.log(user)
        setUserInfo(user)    
    },[])

    // https://ayekartproxycorsserver.herokuapp.com/
    useEffect(() => {
        getProducts('approved')
    },[userInfo])

    const getProducts = (status) => {
        if(userInfo.ID){
        setLoader(true)
        setStatus(status)
        try{
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v2/merchant/${userInfo.ID}/product?page=${page}&limit=${limit}&except_master_list=${eml}&is_approved=${status}&text=${search}`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.code === 200){
                setOrder(data?.data)
            }else{
                // toast.error('something went wrong')
            }
        });
    }catch{
        setLoader(false)
    }
    }
    }

    const searchProduct =(value) => {
        setSearch(value);
        if(value.length > 3){
            getProducts(status)
        }
    }
    const getPro = (value) => {
        getProducts(value)
    }

    const deleteProduct =  (data) => {
        setLoader(true)
        const dataToAdd = {
            "business_margin": data.business_margin,
            "buying_price": data.buying_price,
            "delivery_charges": data.delivery_charges,
            "demand_master_list": data.demand_master_list,
            "discount_percentage": data.discount_percentage,
            "free_delivery_distance": data.free_delivery_distance,
            "global_delivery": data.global_delivery,
            "gst": data.gst,
            "is_active": data.is_active,
            "is_approved": data.is_approved,
            "measurement_unit": data.measurement_unit,
            "min_order_qty": data.min_order_qty,
            "origin_from": data.origin_from,
            "pack_size": data.pack_size,
            "pack_sizes": data.pack_sizes,
            "presence": data.presence,
            "product_amount": data.product_amount,
            "product_category_id": data.product_category_id,
            "product_desc": data.product_desc,
            "product_images": data.product_images,
            "product_name": data.product_name,
            "product_sub_category_id": data.product_sub_category_id,
            "quality": data.quality,
            "selling_price": data.selling_price,
            "special_discount": data.special_discount,
            "stock": data.stock,
            "upc_code": data.upc_code,
            "user_id": data.user_id
        };

        const requestOptions = {
            crossDomain:true,
            method: 'PUT',            
            body: JSON.stringify(dataToAdd),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/${userInfo.ID}/product/${data.ID}`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.code === 200){
                setAddData({})
                getProducts();
                toast.success('products Deleted successfully')
            }else{
                toast.error('something went')
            }
        });
    }

    const editData = (index) => {
        navigate('/editProduct',{state:JSON.stringify(orders[index])})
    }

  return (
    <div>
    
    <Loader loading={loader} />
      <Header />
      <div className="container-fluid mt-3">
        <div className="custom-breadcrum">
            <span className="disable-color" style={{cursor:'pointer'}} onClick={() => navigate('/home')}>Home </span> 
            <span className="disable-color"> Inventory</span>
        </div>
        <hr></hr>
    </div>
    <div className="container-fluid mt-3">
        <div className="tab-panel-ui" style={{padding:'20px'}}>
            <div className="row">
            <div className="col col-lg-6 text-right" style={{ paddingTop: '10px',paddingLeft: '2px' }}>
                <div className="panel with-nav-tabs panel-default">
                    {/* <div className="panel-heading">
                        <ul className="nav nav-tabs">
                            <li   className={status === 'approved'?'active':''}><a href="#tab1default" onClick={() => getPro('approved')} data-toggle="tab">Approved</a></li>
                            <li  className={status === 'pending'?'active':''}><a href="#tab2default" data-toggle="tab"  onClick={() => getPro('pending')}>Pending</a></li>
                            <li  className={status === 'rejected'?'active':''}><a href="#tab3default" data-toggle="tab" onClick={() => getPro('rejected')}>Rejected</a></li>
                        </ul>
                    </div> */}
                    <div className="panel-body">
                        <div className="tab-content">
                        </div>
                    </div>
                </div>
                </div>
                <div className="col col-lg-6 text-right">
                    <button className="ui-button mr-5" onClick={() => navigate('/addProduct')}>Add New Products</button>
                    {/* <button className="ui-button ">Update in Bulk</button> */}
                </div>
            </div>
        </div>

        <div className="search-input-div">
            <input className="search-input" onKeyDown={(e) => e.code === 'Enter' && getProducts(status)}  onChange={(e) => searchProduct(e.target.value)} value={search} type="text" placeholder="Search by product name" />
        </div>

        <div className="table-responsive">

            <table className="zui-table zui-table-rounded">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Quantity Purchased</th>
                        <th>Unit of measurement</th>
                        <th>MRP in INR<br />/unit(incl GST)</th>
                        <th>Offer Price in INR<br />/unit(incl GST)</th>
                        <th>Quantity in Stock</th>
                        <th>Value Stock in Hand (INR)</th>
                        {/* <th>Reorder Level</th>
                        <th>Reorder Quantity</th>
                        <th>Quantity Sold</th> */}
                        <th>PAN India</th>
                        <th className="text-right">Presense</th>
                    </tr>
                </thead>
                <tbody>
                {orders && orders.map((item,index) => {
                    return (
                        <tr key={index}>
                        <td><input type="checkbox" /> {item.product.product_name}</td>
                        <td>{item.product.stock}</td>
                        <td>{item.product.measurement_unit}</td>
                        <td>{item.product.buying_price}</td>
                        <td>{item.product.stock}</td>
                        <td>{item.product.product_amount}</td>
                        <td>{Number(item.product.buying_price) * Number(item.product.stock)}</td>
                        {/* <td>25</td>
                        <td>100</td>
                        <td>50</td> */}
                        <td>{item.product.global_delivery ? 'Yes' : 'No'}</td>
                        <td className="text-right">
                            <div className="dropdown dropdown-action action-option">
                                <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                <div className="dropdown-menu dropdown-menu-down">
                                    <a className="dropdown-item" href="#"  data-toggle="modal" data-target="#exampleModal1" onClick={() => editData(index)}>Edit</a>
                                    <a className="dropdown-item" href="#" onClick={() => deleteProduct(item.product)}>Remove</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    )
                    
                }) }
                   
                </tbody>
            </table>
        </div>
    </div>
        
        <Toaster />
    </div>
  );
}

export default Inventory;
