var firebaseConfig = {
  // apiKey: "AIzaSyAKlBsoA_pUp4jVFc8PWRL4-THXKrHKC6g",
  // authDomain: "ayekarthisabsurya.firebaseapp.com",
  // projectId: "ayekarthisabsurya",
  // storageBucket: "ayekarthisabsurya.appspot.com",
  // messagingSenderId: "902570831841",
  // appId: "1:902570831841:web:25121315761abe23276ca0",
  // measurementId: "G-CS8RG4PV72",

  apiKey: "AIzaSyDaRk-s8KrGrV77yhdwqDOgl_ANLCyZU-Y",
  authDomain: "ayekart-business.firebaseapp.com",
  databaseURL: "https://ayekart-business-default-rtdb.firebaseio.com",
  projectId: "ayekart-business",
  storageBucket: "ayekart-business.appspot.com",
  messagingSenderId: "379760641963",
  appId: "1:379760641963:web:e89601ea0a50de7f0b34ee",
  measurementId: "G-XM6VPV43ZK",
};

export default firebaseConfig;
