import React, { useEffect, useState } from "react";
import Header from "../Header";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jspdf from 'jspdf'
// import { Document, Page } from 'react-pdf';
import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import "./viewReports.css";
import { useNavigate } from "react-router-dom";

function ViewReports() {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [too, setToo] = useState("");
  const [Showall, setShowAll] = useState("");
  const [allData, setAllData] = useState([]);
  const [toSearch, setToSearch] = useState("");

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
     const styles = StyleSheet.create({
        page: {
          flexDirection: "row",
          backgroundColor: "#E4E4E4",
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1,
        },
      });
 
  //   function onDocumentLoadSuccess({ numPages }) {
  //     setNumPages(numPages);
  //   }

  const myDocument = () => {
    var doc =new jspdf('landscape','px','a4','false')
    // doc.addPage()
    doc.text(60,60,'firstName')
    doc.text(120,60,'lastName')
    doc.text(180,60,'firstName')
    doc.text(240,60,'firstName')
    reports?.map((item,index) =>
    {
        return(
            doc.text(100,60,item?.first_name),
            doc.text(100,60,item?.first_name)
        )
    }
    )
    // doc.text(100,60,reports[0]?.first_name)
    // doc.text(60,100,reports[0]?.first_name)
    doc.save('a.pdf')
   };

  const getReports = async () => {
    const data = await fetch(
      "http://128.199.23.167:9000/v1/user/2/business/2/customer-hisab-reports"
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          setReports(res.data);
          setAllData(res.data);
          if (from && to) {
            let temp = res?.data?.filter(
              (item) =>
                item.CreatedAt.split("T")[0] > from &&
                item.CreatedAt.split("T")[0] < to
            );
            console.log(temp);
            setShowAll(false);
            setReports(temp);
          }
        }
      });
  };

  const ShowAll = (e) => {
    setShowAll(e.target.checked);
    if (e.target.checked) {
      setFrom("");
      setTo("");
      setToSearch("");
    }
  };

  const searchData = (e) => {
    setShowAll(false);
    setToSearch(e.target.value);
    var patt = new RegExp(e.target.value, "i");
    let temp = allData.filter((item, index) => item.item_name.match(patt));
    setReports(temp);
  };

  useEffect(() => {
    getReports();
  }, [from, to, Showall]);
  return (
    <React.Fragment>
      <Header />
      {/* <div className="btn btn-primary" 
      onClick={() => myDocument()}
      >
        Download
        
      </div> */}
      <div className="container-fluid">
        <div className="row reports-header">
          <div className="col-md-1">
            <PictureAsPdfIcon
              fontSize="large"
              className="text-light"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/my-document")}
            />
          </div>
          <div className="col-md-3 d-flex">
            <span className="pt-2 text-light">Start Date </span>
            <input
              className="form-control w-50 ml-2"
              style={{ cursor: "pointer" }}
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              type={"date"}
              placeholder="Start Date"
            />
          </div>
          <div className="col-md-3 d-flex">
            <span className="pt-2 text-light">End Date </span>{" "}
            <input
              className="form-control w-50 ml-2"
              value={to}
              type={"date"}
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
          <div className="col-md-2 text-light d-flex justify-content-left pt-2">
            <input
              type="checkbox"
              id="show-all"
              className="form-check h-50 w-25"
              size="small"
              disabled={Showall}
              checked={Showall}
              onClick={(e) => ShowAll(e)}
            />
            <label htmlFor="show-all"> Show All</label>
          </div>
          <div className="col-md-3 text-light">
            <div class="input-group mb-3">
              <input
                type="text"
                size="small"
                class="form-control"
                value={toSearch}
                onChange={(e) => searchData(e)}
                placeholder="Search"
                aria-describedby="basic-addon1"
              />
              <span class="input-group-text" id="basic-addon1">
                <SearchIcon />
              </span>
            </div>
          </div>
        </div>
        <div className="row reports-table-head mx-auto">
          <div className="col-md-3">
            <span>ITEMS</span>
          </div>
          <div className="col-md-2">
            <span>To Pay</span>
          </div>
          <div className="col-md-2">
            <span>To Collect</span>
          </div>
          <div className="col-md-2">
            <span>Paid</span>
          </div>
          <div className="col-md-3">
            <span>Collected</span>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ overflowY: "scroll", height: "540px" }}
        >
          {reports?.map((item, index) => (
            <div
              className="row view-reports-table-tr hoverEffect mx-auto"
              key={index}
            >
              <div className="col-md-3 d-flex flex-column text-left pl-5">
                <span style={{ fontWeight: 600 }}>{item?.item_name}</span>
                <span>
                  {item?.CreatedAt.split("T")[0]}{" "}
                  <small>{` ${item?.CreatedAt.split("T")[1].split(":")[0]} : ${
                    item?.CreatedAt.split("T")[1].split(":")[1]
                  } : ${
                    item?.CreatedAt.split("T")[1].split(":")[2].split(".")[0]
                  } ${
                    item?.CreatedAt.split("T")[1].split(":")[0] > 12
                      ? "PM"
                      : "AM"
                  }`}</small>{" "}
                </span>
              </div>
              <div className="col-md-2">
                <span>₹ {item?.to_pay}</span>
              </div>
              <div className="col-md-2">
                <span>₹ {item?.to_collect}</span>
              </div>
              <div className="col-md-2">
                <span>₹ {item?.paid}</span>
              </div>
              <div className="col-md-3">
                <span>₹ {item?.collected}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="row mx-auto">
          {/* <PDFViewer>
            <MyDocument />
          </PDFViewer> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewReports;
